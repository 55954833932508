import { useState } from "react";
import ReactDom from "react-dom";
import { FormError } from "../../FormError";
import { Button } from "../../Button";
import { LockIcon, RupeeIcon } from "../../../../icons/icons";

export const ConfirmInvestment = ({
  open,
  onClose,
  confirmInvestmentDetails,
  agreementId,
  transactionData,
}) => {
  const [proof, setProof] = useState();
  const [transferDetails, setTransferDetails] = useState({
    transactionType: "Net Banking",
    amount: 0,
    transactionId: "",
    proof: null,
  });

  if (!open) return null;
  const submitModal = (e) => {
    e.preventDefault();
    confirmInvestmentDetails(agreementId, transferDetails);
    setProof();
    onClose();
  };

  const handleAmount = (e) => {
    if (e.target.value > transactionData.pending) {
      document.getElementById("pending-error").classList.remove("hidden");
    } else {
      document.getElementById("pending-error").classList.add("hidden");
    }
    setTransferDetails((prev) => ({ ...prev, amount: e.target.value }));
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[50%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Investment Confirmation
        </h4>
        <form onSubmit={submitModal} className="p-3 text-left">
          <p className="mb-4">
            Enter the transaction details below once you have completed the
            payment process. Thank you.
          </p>
          <div className="grid grid-cols-2 gap-1 mb-3">
            <p className="col">
              <span className="font-medium me-1">Total Amount :</span> ₹{" "}
              {transactionData?.total}
            </p>
            <p className="col">
              <span className="font-medium me-1">Released Amount :</span> ₹{" "}
              {transactionData?.released}
            </p>
            <p className="col">
              <span className="font-medium me-1">Pending Amount :</span> ₹{" "}
              {transactionData?.pending}
            </p>
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="transactionType">
              Mode of Transaction
            </label>
            <div
              className="flex rounded-md overflow-hidden ps-2 pe-3"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <select
                className="w-full p-4 ps-0"
                id="transactionType"
                name="transactionType"
                required
                onChange={(e) =>
                  setTransferDetails((prev) => ({
                    ...prev,
                    transactionType: e.target.value,
                  }))
                }
              >
                <option value="Net Banking">Net Banking</option>
                <option value="Cheque">Cheque</option>
              </select>
            </div>
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="amount">
              Transfered Amount
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="number"
                className="border-0 w-full ps-3"
                id="amount"
                name="amount"
                autoFocus
                onChange={handleAmount}
                required
              />
              <span className="input-icons icon-span">
                <RupeeIcon />
              </span>
            </div>
            <div className="hidden" id="pending-error">
              <FormError message="Transfered amount is more than pending amount." />
            </div>
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="transactionId">
              Transaction ID
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="transactionId"
                name="transactionId"
                onChange={(e) =>
                  setTransferDetails((prev) => ({
                    ...prev,
                    transactionId: e.target.value,
                  }))
                }
                required
              />
              <span className="input-icons icon-span">
                <LockIcon />
              </span>
            </div>
          </div>
          <div>
            <p className="mb-0">Upload proof of transaction</p>
            <label
              htmlFor="transactionProof"
              className="p-1 rounded-md bg-primary text-white text-sm cursor-pointer"
            >
              Upload
            </label>
            <input
              type="file"
              id="transactionProof"
              name="transactionProof"
              accept="image/png,image/jpeg,application/pdf"
              className="hidden"
              onChange={(e) =>
                setTransferDetails((prev) => ({
                  ...prev,
                  proof: e.target.files[0],
                }))
              }
            />
            <span className="ms-2">{proof && proof.name}</span>
          </div>
          <div className="modalFooter p-3">
            <Button type="submit" className="px-2 py-1 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
