import React, { useCallback, useState } from "react";
import axios from "axios";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import { TicketModal } from "../../components/ui/modals/ticket/ticketModal";
import { useNavigate } from "react-router-dom";
import ErrorImg from "../../images/500.svg";
import { Button } from "../../components/ui/Button";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const Error500 = () => {
  const supportTicketApi = `${process.env.REACT_APP_API_URL}support-ticket`;

  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [open, setOpen] = useState(false);

  const supportTicket = useCallback(
    (file, data) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("support_type", data.complaintCategory.replace("-", " "));
      formData.append("ticket_heading", data.complaintHeading);
      formData.append("query", data.complaintQuery);
      formData.append("date", getCurrentDate());
      axios
        .post(supportTicketApi, formData, {
          headers: { "x-token": token },
        })
        .then((res) => {
          if (res.status === 200) {
            toast.open(
              "true",
              "Complaint registered successfully!. We will get back to you soon."
            );
          }
        })
        .catch(() => toast.open("false", "Failed to create new ticket"));
    },
    [supportTicketApi, token, toast]
  );

  const checkRole = () => {
    if (userRole === "f@!3A") {
      navigate("/startup-dashboard");
    } else if (userRole === "R$7s2") {
      navigate("/mentor-dashboard");
    } else if (userRole === "Sw%aa") {
      navigate("/investor-dashboard");
    } else {
      navigate("/vendor-profile");
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img src={ErrorImg} alt="Error-500" width={450} />
      <p className="mb-2 font-medium">
        Sorry something went wrong. Please try again or raise a support ticket
        if the issue persists.
      </p>
      <div>
        <Button onClick={() => checkRole()} className="px-2 py-1 mx-2">
          Dashboard
        </Button>
        <Button
          onClick={() => checkRole()}
          className="px-2 py-1 mx-2"
          bgColor="bg-[#F97316]"
        >
          Raise support ticket
        </Button>
      </div>
      <TicketModal
        open={open}
        onClose={() => setOpen(false)}
        supportTicket={supportTicket}
      />
    </div>
  );
};
