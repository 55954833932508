const NotiType = (noti, userRole) => {
  switch (noti.notification_type) {
    case "connections":
      return userRole === "f@!3A"
        ? "/startup-connections"
        : userRole === "R$7s2"
        ? "/mentor-connections"
        : "/investor-connections";
    case "subscription":
      return userRole === "f@!3A" ? "/store" : "/vendor-plan";
    case "investments":
      return userRole === "f@!3A"
        ? "/startup-investments"
        : "/investor-investments";
    case "profile":
      return `/view-investor/${noti.sender_name}/${noti.sender_id}`;
    case "certified":
      return "/startup-profile";
    case "market":
      return userRole !== "V&iR8" ? "/my-projects" : "/my-bids";
    case "reviews":
      return "/reviews";
    case "admin":
      return "/#";
    case "meeting":
      return "/meetings";
    case "ticket":
      return "/tickets";
    default:
      return "/error-500";
  }
};

export default NotiType;
