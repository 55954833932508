import React from "react";
import FavIcon from "../../images/favicon.png";
import { Stars } from "../../components/ui/modals/rating-modal/Stars";

export const SingleReview = ({ data }) => {
  return (
    <div className="grid grid-cols-6 my-2 bg-white shadow-md py-3 rounded-md">
      <div className="flex items-center justify-center px-0">
        <img
          src={data.logo.length > 0 ? data.logo : FavIcon}
          alt="reviewerImage"
          className="rounded-full w-[60px] h-[60px]"
        />
      </div>
      <div className="col-span-5 px-0">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <span className="me-2 text-sm font-semibold">{data.name}</span>
            <Stars rating={data.rating} size={"20px"} />
          </div>
          <div className="flex flex-col items-end">
            <span className="me-3 text-xs">{data.date}</span>
            <span
              className={`me-3 px-1 mt-1 text-xs rounded-md border ${
                data.role === "f@!3A"
                  ? "border-blue-400 text-blue-400"
                  : data.role === "R$7s2"
                  ? "border-orange-400 text-orange-400"
                  : data.role === "Sw%aa"
                  ? "border-green-500 text-green-500"
                  : "border-indigo-500 text-indigo-500"
              }`}
            >
              {data.role === "f@!3A"
                ? "Startup"
                : data.role === "R$7s2"
                ? "Mentor"
                : data.role === "Sw%aa"
                ? "Investor"
                : "Service Provider"}
            </span>
          </div>
        </div>
        <p className="text-sm">{data.review}</p>
      </div>
    </div>
  );
};
