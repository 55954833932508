import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { randomId } from "../../../utils/randomId";
import SubscriptionCard from "../../../components/ui/cards/subscriptionCard";
import { Button } from "../../../components/ui/Button";
import AddPlan from "../../../components/ui/modals/store/addPlan";
import EditPlan from "../../../components/ui/modals/store/editPlan";
import { DeleteModal } from "../../../components/ui/modals/utility-modals/deleteModal";
import { useToastContext } from "../../../context/ToastContext";
import { getCurrentDate } from "../../../utils/getCurrentDate";

const Plans = () => {
  const displaySubscriptionsApi = `${process.env.REACT_APP_API_URL}display-plans`;
  const addSubscriptionApi = `${process.env.REACT_APP_API_URL}add-subscription`;
  const updateSubscriptionApi = `${process.env.REACT_APP_API_URL}update-subscription`;
  const planStatusApi = `${process.env.REACT_APP_API_URL}change-plan-status`;
  const deletePlanApi = `${process.env.REACT_APP_API_URL}delete-plan`;

  const { fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();

  const [openAddPlanModal, setOpenAddPlanModal] = useState(false);
  const [editPlanModal, setEditPlanModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState({
    startup: [],
    vendor: [],
  });
  const [singlePlan, setSinglePlan] = useState([]);
  const [planId, setPlanId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("Startup");

  const displaySubscriptions = useCallback(() => {
    fetchApi(
      { url: displaySubscriptionsApi, headers: { "x-token": token } },
      (data) => {
        setSubscriptionPlans({
          startup: data.plans?.filter((plan) => plan.type === "startup"),
          vendor: data.plans?.filter((plan) => plan.type === "vendor"),
        });
      }
    );
  }, [displaySubscriptionsApi, token, fetchApi]);

  const addSubscription = useCallback(
    (data, features) => {
      fetchApi(
        {
          url: addSubscriptionApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            type: data.planFor,
            plan_type: data.planName,
            amount: parseInt(data.planPrice),
            points: parseInt(data.planTokens),
            plan_features: features,
            discount_status: data.discount,
            discount:
              data.type === "flat"
                ? parseInt(data.discountAmount)
                : parseFloat(data.discountAmount),
            discount_type: data.type,
            date: getCurrentDate(),
          },
        },
        () => {
          toast.open("true", "New plan created successfully!");
          displaySubscriptions();
        }
      );
    },
    [token, addSubscriptionApi, fetchApi, displaySubscriptions, toast]
  );

  const updateSubscription = useCallback(
    (data, features, id) => {
      fetchApi(
        {
          url: updateSubscriptionApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            type: data.planFor,
            plan_type: data.planName,
            amount: data.planPrice,
            points: data.planTokens,
            plan_features: features,
            discount_status: data.discount,
            discount: data.discountAmount,
            discount_type: data.type,
            date: getCurrentDate(),
          },
        },
        () => {
          toast.open("true", "Plan updated successfully!");
          displaySubscriptions();
        }
      );
    },
    [token, updateSubscriptionApi, fetchApi, displaySubscriptions, toast]
  );

  const changePlanStatus = useCallback(
    (id, status) => {
      fetchApi(
        {
          url: planStatusApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            status: status,
          },
        },
        () => {
          displaySubscriptions();
        }
      );
    },
    [token, planStatusApi, fetchApi, displaySubscriptions]
  );

  const deletePlan = useCallback(
    (id) => {
      fetchApi(
        {
          url: deletePlanApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        () => {
          toast.open("true", "Plan deleted successfully!");
          displaySubscriptions();
        }
      );
    },
    [token, deletePlanApi, fetchApi, displaySubscriptions, toast]
  );

  const updateModal = (data) => {
    setSinglePlan(data);
    setEditPlanModal(true);
  };

  const handleDeleteModal = (id) => {
    setPlanId(id);
    setDeleteModal(true);
  };

  useEffect(() => {
    const fetchPlans = setTimeout(() => {
      displaySubscriptions();
    });
    return () => {
      clearTimeout(fetchPlans);
    };
  }, [displaySubscriptions, fetchApi, token]);

  const checkSelectedPlan = () => {
    if (selectedPlan === "Startup") {
      return subscriptionPlans.startup;
    } else return subscriptionPlans.vendor;
  };

  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">
      <p className="sm:col-span-2 md:col-span-3 font-medium text-sm">
        Note : Use toggle switch to activate/deactive store plans.
      </p>
      <div className="sm:col-span-2 md:col-span-3 flex flex-col items-start gap-y-3 sm:flex-row sm:items-center sm:justify-between">
        <div>
          <span
            className={`${
              selectedPlan === "Startup"
                ? "bg-primary text-white"
                : "bg-gray-200"
            } px-2 py-1 rounded-l-md border-r-2 border-gray-400`}
            onClick={() => setSelectedPlan("Startup")}
          >
            Startup
          </span>
          <span
            className={`${
              selectedPlan === "Service Provider"
                ? "bg-primary text-white"
                : "bg-gray-200"
            } px-2 py-1 rounded-r-md`}
            onClick={() => setSelectedPlan("Service Provider")}
          >
            Service Provider
          </span>
        </div>
        <Button className="px-3 py-1" onClick={() => setOpenAddPlanModal(true)}>
          Create Token Plan
        </Button>
      </div>
      <p className="sm:col-span-2 md:col-span-3 font-medium text-lg">
        {selectedPlan} Plans
      </p>
      {checkSelectedPlan().length > 0 ? (
        checkSelectedPlan().map((plan) => (
          <SubscriptionCard
            key={plan._id}
            data={plan}
            role="admin"
            updateModal={updateModal}
            changePlanStatus={changePlanStatus}
            handleDeleteModal={handleDeleteModal}
          />
        ))
      ) : (
        <p>No Plans Found.</p>
      )}
      <AddPlan
        key={randomId()}
        open={openAddPlanModal}
        onClose={() => setOpenAddPlanModal(false)}
        addSubscription={addSubscription}
      />
      <EditPlan
        key={randomId()}
        open={editPlanModal}
        onClose={() => setEditPlanModal(false)}
        singlePlan={singlePlan}
        updateSubscription={updateSubscription}
      />
      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        deleteMethod={deletePlan}
        id={planId}
      />
    </div>
  );
};

export default Plans;
