export const calculateMonthsPassed = () => {
  const previousDate = new Date("2024-01-01");
  const currentDate = new Date();
  const previousMonth = previousDate.getMonth();
  const currentMonth = currentDate.getMonth();
  const previousYear = previousDate.getFullYear();
  const currentYear = currentDate.getFullYear();

  // Calculate the difference in months
  const monthsPassed =
    (currentYear - previousYear) * 12 + (currentMonth - previousMonth);

  return monthsPassed;
};
