import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useFetch from "../../hooks/useFetch";
import useUploadImage from "../../hooks/useUploadImage";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { randomId } from "../../utils/randomId";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { EditInvestorProfile } from "../../components/ui/modals/profile/editInvestorProfile";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import { PrevInvestmentModal } from "../../components/ui/modals/profile/prevInvestmentModal";
import { capitalizeText } from "../../utils/capitalizeText";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import { CameraIcon, DeleteIcon } from "../../icons/icons";

const InvestorProfile = () => {
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}display-investor-profile`;
  const editInvestorProfileApi = `${process.env.REACT_APP_API_URL}update-investor-profile`;
  const previousInvestmentApi = `${process.env.REACT_APP_API_URL}previous-investment`;
  const deletePrevInvestmentApi = `${process.env.REACT_APP_API_URL}delete-previous-investment`;

  const [investorDetails, setInvestorDetails] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [editData, setEditData] = useState([]);
  const [prevInvestments, setPrevInvestments] = useState([]);
  const [desc, setDesc] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [prevInvestmentModal, setPrevInvestmentModal] = useState(false);

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { updateProfile } = useUpdateProfile();
  const { uploadImage } = useUploadImage();
  const { displayIndustries } = useDisplayIndustries();
  const navigate = useNavigate();
  const ref = useRef(true);

  const getInvestorData = useCallback(() => {
    fetchApi(
      {
        url: investorDetailsApi,
        method: "POST",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setInvestorDetails(data.investor_profile);
        setEditData(data.investor_profile);
        setProfileImg(data.investor_profile[0].logo);
        setPrevInvestments(data.investor_profile[0].previous_investment);
        setDesc(data.investor_profile[0].description);
      }
    );
  }, [investorDetailsApi, fetchApi, token]);

  const editInvestorDetails = (data, industry) => {
    let dataObj = {
      name: data.investorName,
      phone: data.investorPhone,
      companies_invested_count: data.investedCompanyCount,
      invested_amount: data.fillTotalInvestment,
      future_investment_amount: data.futureInvestmentAmount,
      linked_in: data.investorLinkedIn,
    };
    if (industry.length > 0) {
      dataObj = { ...dataObj, investor_industries: industry };
    }
    updateProfile(editInvestorProfileApi, dataObj, getInvestorData, "investor");
  };

  const updatePrevInvestments = useCallback(
    (data) => {
      fetchApi(
        {
          url: previousInvestmentApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            company_name: data.prevCompanyName,
            company_url: data.prevCompanyUrl,
          },
        },
        () => {
          getInvestorData();
        }
      );
    },
    [fetchApi, token, previousInvestmentApi, getInvestorData]
  );

  const deletePrevInvestment = useCallback(
    (id) => {
      fetchApi(
        {
          url: deletePrevInvestmentApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        () => {
          getInvestorData();
        }
      );
    },
    [fetchApi, token, deletePrevInvestmentApi, getInvestorData]
  );

  useEffect(() => {
    if (userRole !== "Sw%aa") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getInvestorData();
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
    return () => {
      ref.current = false;
    };
  }, [getInvestorData, navigate, userRole, displayIndustries]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-3 md:p-4">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <div>
              <div
                className="relative hidden md:block bg-cover h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div>
                <div className="grid grid-cols-5 px-3 gap-x-1 bg-white shadow-md rounded-md">
                  <div className="col-span-5 ml-auto md:hidden">
                    <button
                      type="button"
                      className="profile-btns bg-primary text-white mt-2 text-center px-2 py-1 rounded-md text-xs md:text-sm"
                      onClick={() => setOpenProfile(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="col-span-5 md:col-span-1 relative flex items-end justify-center">
                    <img
                      src={profileImg.length > 0 ? profileImg : FavIcon}
                      className="profile-img shadow w-[90px] h-[90px] md:w-[140px] md:h-[140px]"
                      alt="profile-img"
                      width={140}
                    />
                    <div className="img-icon bg-primary rounded-full p-1">
                      <label
                        htmlFor="upload-logo"
                        className="mb-0 cursor-pointer"
                      >
                        <CameraIcon fill="#fff" />
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="upload-logo"
                        hidden
                        onChange={(e) =>
                          uploadImage(e.target.files[0], getInvestorData)
                        }
                      />
                    </div>
                    {profileImg.length < 1 && (
                      <p className="text-red-500 font-medium absolute top-[100px] md:top-[85px]">
                        Upload image
                      </p>
                    )}
                  </div>
                  <div className="relative col-span-5 md:col-span-4 pb-4 mb-5 md:pl-[3.5rem] 2xl:pl-0">
                    <button
                      type="button"
                      className="hidden md:block profile-btns bg-primary text-white mt-2 text-center px-2 py-1 rounded-md absolute right-0 md:top-unset md:bottom-2.5 text-xs md:text-sm"
                      onClick={() => setOpenProfile(true)}
                    >
                      Edit
                    </button>
                    {investorDetails.map((investor) => (
                      <div
                        key={investor.user_id}
                        className="grid grid-cols-1 md:grid-cols-3 mt-10 gap-y-1 md:mt-2"
                      >
                        <div className="md:col-span-3 grid grid-cols-2">
                          <p className="col-span-2 md:col-span-1 font-bold">
                            Name :{" "}
                            <span className="uppercase font-medium">
                              {investor.name}
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-bold">
                            Email :{" "}
                            <span className="font-medium">
                              {investor.email}
                            </span>
                          </p>
                        </div>
                        <p className=" font-bold">
                          No. of Investments :{" "}
                          <span className="font-medium">
                            {investor.companies_invested_count}
                          </span>
                        </p>
                        <p className="flex items-center font-bold">
                          Address :{" "}
                          <span className="flex items-center ms-1 font-medium capitalize">
                            {investor.country?.length > 0 ? (
                              <>
                                <span className="pe-1 capitalize">
                                  {investor.city}
                                </span>
                                <img
                                  src={
                                    investor.country_code
                                      ? `https://flagsapi.com/${investor.country_code}/flat/64.png`
                                      : "https://flagsapi.com/IN/flat/64.png"
                                  }
                                  alt={`${investor.country} flag`}
                                  width={30}
                                  title={capitalizeText(investor.country)}
                                  loading="lazy"
                                />
                              </>
                            ) : (
                              <span className="text-red-500 text-sm">
                                Missing
                              </span>
                            )}
                          </span>
                        </p>
                        <p className="font-bold">
                          LinkedIn :{" "}
                          <span className="font-medium">
                            {investor.linked_in.length > 0 ? (
                              <a
                                href={investor.linked_in}
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-500"
                              >
                                Profile
                              </a>
                            ) : (
                              <span className="text-red-500 text-sm">
                                Missing
                              </span>
                            )}
                          </span>
                        </p>
                        <div className="md:col-span-3 grid grid-cols-2">
                          <p className="col-span-2 md:col-span-1 font-bold">
                            Previous Investment :{" "}
                            <span className="capitalize font-medium">
                              ₹{investor.invested_amount}
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-bold">
                            Investment Target :{" "}
                            <span className="capitalize font-medium">
                              ₹{investor.future_investment_amount}
                            </span>
                          </p>
                        </div>
                        <p className="md:col-span-2 font-bold">
                          Industries :{" "}
                          <span className="font-medium ms-1">
                            {investor.investor_industries.map((industry, i) => (
                              <span className="outline-tags" key={i}>
                                {industry.replace(new RegExp("_", "g"), " ")}
                              </span>
                            ))}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3 mt-2 mx-0 px-0">
                  <div className="ideaContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md p-3 my-2 relative">
                    <div className="flex items-center justify-between">
                      <h5 className="font-semibold">About Me</h5>
                      <p
                        className="outline-btn text-xs md:text-sm"
                        type="button"
                        onClick={() => setDescriptionModal(true)}
                      >
                        Write here
                      </p>
                    </div>
                    {desc.length > 0 ? (
                      <p id="noIdea" className="mt-3 text-sm">
                        {desc}
                      </p>
                    ) : (
                      <p id="noIdea" className="mt-3 text-sm">
                        Tell us about yourself and your expertise.
                      </p>
                    )}
                  </div>
                  <div className="investmentContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md p-3 my-2 relative">
                    <div className="flex items-center justify-between">
                      <h5 className="font-semibold">Portfolio</h5>
                      <p
                        className="outline-btn text-xs md:text-sm"
                        type="button"
                        onClick={() => setPrevInvestmentModal(true)}
                      >
                        Add here
                      </p>
                    </div>
                    {prevInvestments.length > 0 ? (
                      <div className="mt-5">
                        {prevInvestments.map((investment) => (
                          <div
                            key={investment._id}
                            className="profile-detail-card relative px-4 py-2 mt-2 rounded-md bg-gray-200"
                          >
                            <Link
                              to={
                                investment.company_url
                                  ? investment.company_url
                                  : "#"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="font-medium me-2 text-sm">
                                Company Name :
                              </span>
                              <span className="text-sm">
                                {investment.company_name}
                              </span>
                            </Link>
                            <DeleteIcon
                              className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                              onClick={() =>
                                deletePrevInvestment(investment._id)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p id="noInvestment" className="mt-3 text-sm">
                        Add previous investment details
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <EditInvestorProfile
          key={randomId()}
          open={openProfile}
          onClose={() => setOpenProfile(false)}
          editInvestorDetails={editInvestorDetails}
          editData={editData}
          industries={industries}
        />
        <DescriptionModal
          key={new Date()}
          open={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          callBackFn={() => getInvestorData()}
          url={editInvestorProfileApi}
          data={desc}
        />
        <PrevInvestmentModal
          open={prevInvestmentModal}
          onClose={() => setPrevInvestmentModal(false)}
          updatePrevInvestments={updatePrevInvestments}
        />
      </div>
    </div>
  );
};

export default InvestorProfile;
