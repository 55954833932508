import { useCallback } from "react";
import useFetch from "./useFetch";

const useDisplayIndustries = () => {
  const displayIndustriesApi = `${process.env.REACT_APP_API_URL}display-industries`;

  const { fetchApi } = useFetch();

  const displayIndustries = useCallback(
    (fetchedIndustries, type) => {
      fetchApi(
        {
          url: displayIndustriesApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: {
            type: type,
          },
        },
        (data) => {
          fetchedIndustries(data);
        }
      );
    },
    [fetchApi, displayIndustriesApi]
  );
  return { displayIndustries };
};

export default useDisplayIndustries;
