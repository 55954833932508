import { useState } from "react";
import { Button } from "../../../../components/ui/Button";
import { Stars } from "../../../../components/ui/modals/rating-modal/Stars";
import { capitalizeText } from "../../../../utils/capitalizeText";
import {
  BuildingIcon,
  LinkedInIcon,
  LocationIcon,
  RedirectArrowIcon,
  RedirectIcon,
  RupeeIcon,
  UserTieIcon,
  VerticleDotsIcon,
} from "../../../../icons/icons";
import FavIcon from "../../../../images/favicon.png";
import certifiedLogo from "../../../../images/certified.png";
import verifiedLogo from "../../../../images/verified.png";
import IconDropdown from "../../../../components/ui/inputs/iconDropdown";

const StartupDetails = ({ details, startupLevel, handleConfirmationModal }) => {
  const [levelDropdown, setLevelDropdown] = useState(false);
  const [updatedLevel, setUpdatedLevel] = useState(details?.level);

  const mentorshipLevelOptions = [
    {
      label: "Level 1",
      value: 1,
    },
    {
      label: "Level 2",
      value: 2,
    },
    {
      label: "Level 3",
      value: 3,
    },
  ];

  const handleUpdateLevel = (newLevel) => {
    setUpdatedLevel(newLevel);
    setLevelDropdown(false); // Close dropdown after selecting a value
    if (newLevel !== updatedLevel) {
      startupLevel(newLevel);
    }
  };

  return (
    <>
      <div className="container grid grid-cols-8 gap-3">
        {details?.deactivate === "1" &&
          details?.deactive_reason?.length > 0 && (
            <div className="col-span-8 md:hidden bg-slate-200 shadow-md rounded-md py-2 px-3">
              <h5 className="font-semibold pb-2 text-secondary">
                Deactivation Reason :
              </h5>
              <p className="font-medium">{details?.deactive_reason}</p>
            </div>
          )}
        <div className="col-span-8 md:col-span-3 bg-white shadow-md rounded-md p-3 text-center">
          <div className="flex flex-wrap items-center justify-end gap-1 md:hidden">
            {details?.is_suspend === "0" ? (
              <Button
                className="px-2 py-1 text-xs"
                bgColor="bg-secondary"
                id="suspend-btn"
                onClick={() => handleConfirmationModal("suspend")}
              >
                Suspend
              </Button>
            ) : (
              <Button
                className="px-2 py-1 text-xs"
                bgColor="bg-green-500"
                id="unsuspend-btn"
                onClick={() => handleConfirmationModal("unsuspend")}
              >
                Un-Suspend
              </Button>
            )}
            {details?.platform_verify === "0" ? (
              <Button
                className="px-2 py-1 text-xs"
                id="verify-btn"
                onClick={() => handleConfirmationModal("verify")}
              >
                Verify
              </Button>
            ) : (
              <Button className="px-2 py-1 text-xs" id="verified-btn" disabled>
                Verified
              </Button>
            )}
          </div>
          <div className="h-[90px] w-[90px] lg:h-[140px] lg:w-[140px] flex items-center border rounded-full">
            <img
              src={details?.logo?.length > 1 ? details?.logo : FavIcon}
              className="max-h-[90px] lg:max-h-[140px] p-1 mx-auto rounded-full"
              alt="profile-img"
            />
          </div>
          <div className="text-start space-y-2">
            <div className="font-bold text-lg pt-2 mb-0 flex items-center uppercase">
              {details?.name}{" "}
              <span className="flex items-center">
                {details?.platform_verify === "1" && (
                  <img
                    src={verifiedLogo}
                    alt="Platform Verification Logo"
                    title="Platform Verified"
                    width={20}
                    className="ms-1"
                  />
                )}
                {details?.details?.verify_profile === "1" && (
                  <img
                    src={certifiedLogo}
                    alt="Mentorship Certification Logo"
                    title="Certified by mentor"
                    width={18}
                    className="ms-1"
                  />
                )}
              </span>
            </div>
            <div className="md:hidden">
              <Stars rating={details?.details?.average_rating} size={12} />
            </div>
            <div className="flex items-center gap-x-2 mb-2">
              <span
                className={`px-1 text-xs ${
                  details?.level === 1
                    ? "orange-badge"
                    : details?.level === 2
                    ? "indigo-badge"
                    : "green-badge"
                }`}
                title={`mentorship level ${details?.level}`}
              >
                Level {details?.level}
              </span>
              {details?.platform_verify === "1" && (
                <div className="relative cursor-pointer">
                  <p
                    className="flex items-center gap-x-1 bg-[#eaeaea] px-2 py-0.5 rounded-md text-xs"
                    onClick={() => setLevelDropdown(!levelDropdown)}
                  >
                    Change Level <VerticleDotsIcon width="14px" height="14px" />
                  </p>
                  <IconDropdown
                    open={levelDropdown}
                    options={mentorshipLevelOptions}
                    callBackFn={handleUpdateLevel}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center mb-2">
              <LinkedInIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="me-1 font-medium">Linkedin :</span>
                {details?.details?.linked_in ? (
                  <a
                    href={details?.details?.linked_in}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    Profile
                  </a>
                ) : (
                  <span className="text-secondary">N/A</span>
                )}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <RupeeIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="me-1 font-medium">Desired Investment :</span>
                {details?.details?.require_investment}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <BuildingIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="me-1 font-medium">Company :</span>
                {details?.details?.company_name || "N/A"}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <RedirectIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="me-1 font-medium">Website :</span>
                {details?.details?.website.length > 0 ? (
                  <a
                    href={details?.details?.website}
                    target="_blank"
                    rel="noreferrer"
                    className="text-xs mb-0 text-blue-400"
                  >
                    URL
                  </a>
                ) : (
                  <span className="text-secondary text-sm ms-3">N/A</span>
                )}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <UserTieIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="me-1 font-medium">
                  Company Size :{" "}
                  <span className="font-normal">
                    {details?.details?.no_of_employees || (
                      <span className="text-secondary text-sm">N/A</span>
                    )}
                  </span>
                </span>
              </p>
            </div>
            <div className="flex items-center mb-2">
              <LocationIcon fill="#9c9c9c" />
              <div className="flex items-center text-sm mb-0 ms-3 font-medium">
                Address :{" "}
                <div className="flex items-center mb-0 ms-1">
                  <span className="me-1 font-normal">{details?.city}</span>
                  <img
                    src={
                      details?.country_code
                        ? `https://flagsapi.com/${details?.country_code}/flat/64.png`
                        : "https://flagsapi.com/IN/flat/64.png"
                    }
                    alt={`${details?.country} flag`}
                    width={18}
                    title={capitalizeText(details?.country)}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="border-t py-2">
              <p className="mb-0 font-medium">Industries</p>
              {details?.details?.startup_industry.map((industry, i) => (
                <span key={i} className="outline-tags">
                  {industry.replace(new RegExp("_", "g"), " ")}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-8 md:col-span-5">
          {details?.deactivate === "1" &&
            details?.deactive_reason?.length > 0 && (
              <div className="hidden md:block bg-slate-200 shadow-md rounded-md py-2 px-3">
                <h5 className="font-semibold pb-2 text-secondary">
                  Deactivation Reason :
                </h5>
                <p className="font-medium">{details?.deactive_reason}</p>
              </div>
            )}
          <div className="hidden md:flex items-end justify-between mb-3">
            <div className="flex items-center">
              <Stars rating={details?.details?.average_rating} size={12} />
            </div>
            <div className="flex items-end gap-x-3">
              {details?.is_suspend === "0" ? (
                <Button
                  className="px-3 py-1 text-sm"
                  bgColor="bg-secondary"
                  id="suspend-btn"
                  onClick={() => handleConfirmationModal("suspend")}
                >
                  Suspend
                </Button>
              ) : (
                <Button
                  className="px-3 py-1 text-sm"
                  bgColor="bg-green-500"
                  id="unsuspend-btn"
                  onClick={() => handleConfirmationModal("unsuspend")}
                >
                  Un-Suspend
                </Button>
              )}
              {details?.platform_verify === "0" ? (
                <Button
                  className="px-3 py-1 text-sm"
                  id="verify-btn"
                  onClick={() => handleConfirmationModal("verify")}
                >
                  Verify
                </Button>
              ) : (
                <Button
                  className="px-3 py-1 text-sm disabled"
                  id="verified-btn"
                  disabled
                >
                  Verified
                </Button>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-3">
            <div className="px-0 max-h-[270px] md:min-h-[270px]">
              <div className="bg-white shadow-md rounded-md p-3 h-full overflow-auto">
                <h5 className="font-medium pb-3">Startup Idea</h5>
                <p className="mb-0 text-sm">
                  {details?.details?.description.length > 0
                    ? details?.details?.description
                    : "Not updated."}
                </p>
              </div>
            </div>
            <div className="pe-0">
              <div className="bg-white shadow-md rounded-md p-3">
                <h5 className="font-medium pb-5">Video</h5>
                {details?.video?.length > 0 ? (
                  <>
                    <video
                      controls
                      className="w-full"
                      id="videoFile"
                      src={details?.video}
                      style={{ height: "200px" }}
                    >
                      Video uploaded
                    </video>
                  </>
                ) : (
                  <p>No Video uploaded.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1 px-0">
          <div
            className="bg-white shadow-md rounded-md p-3"
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <h5 className="pb-3 font-medium">Business Overview</h5>
            <div className="grid grid-cols-2">
              <div className="flex flex-col items-start mb-1">
                <p className="mb-0 font-bold text-sm">Startup Stage</p>
                <p className="text-sm capitalize">
                  {details?.details?.startup_stage?.replace("-", " ") || "Nill"}
                </p>
              </div>
              <div className="flex flex-col items-start mb-1">
                <p className="mb-0 font-bold text-sm">Previous Investment</p>
                <p className="text-sm capitalize">
                  {details?.details?.previous_investment || "Nill"}
                </p>
              </div>
              <div className="flex flex-col items-start mb-1">
                <p className="mb-0 font-bold text-sm">Invested Amount</p>
                <p className="text-sm">
                  {details?.details?.investment_amount || "Nill"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 px-0">
          <div className="bg-white shadow-md rounded-md p-3 mb-2 h-100 overflow-y-auto">
            <h5 className="font-medium pb-3">Pitch Deck</h5>
            {details?.document?.length > 0 &&
              details?.document?.map((document) => (
                <a
                  href={document.path}
                  key={document._id}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
                >
                  <p>{document.file_name}</p>
                  <RedirectArrowIcon className="cursor-pointer" />
                </a>
              ))}
            {!details?.document?.length > 0 && <span>No Files uploaded</span>}
          </div>
          <div className="bg-white shadow-md rounded-md p-3 h-100 overflow-y-auto">
            <h5 className="font-medium pb-3">Documents & Certifications</h5>
            {details?.msme?.length > 0 && (
              <a
                href={details.msme[0].path}
                target="_blank"
                rel="noreferrer"
                className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
              >
                <p>{details?.msme[0].document_name}</p>
                <RedirectArrowIcon className="cursor-pointer" />
              </a>
            )}
            {details?.gst?.length > 0 && (
              <a
                href={details?.gst[0].path}
                target="_blank"
                rel="noreferrer"
                className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
              >
                <p>{details?.gst[0].document_name}</p>
                <RedirectArrowIcon className="cursor-pointer" />
              </a>
            )}
            {details?.incorporation?.length > 0 && (
              <a
                href={details?.incorporation[0].path}
                target="_blank"
                rel="noreferrer"
                className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
              >
                <p>{details?.incorporation[0].document_name}</p>
                <RedirectArrowIcon className="cursor-pointer" />
              </a>
            )}
            {details?.others?.length > 0 &&
              details?.others?.map((file) => (
                <a
                  href={file.path}
                  key={file._id}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
                >
                  <p>{file.document_name}</p>
                  <RedirectArrowIcon className="cursor-pointer" />
                </a>
              ))}
            {!details?.msme?.length > 0 &&
              !details?.gst?.length > 0 &&
              !details?.incorporation?.length > 0 &&
              !details?.others?.length > 0 && <span>No Files uploaded</span>}
          </div>
        </div>
      </div>
    </>
  );
};

export default StartupDetails;
