import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import { bannedInputs, numberRegex, urlRegex } from "../../constants";
import { MoneyUpTrendIcon, LinkedInIcon, UploadIcon } from "../../icons/icons";
import { useToastContext } from "../../context/ToastContext";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const InvestorDetailsForm = ({ token, industries }) => {
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}insert-investor-details`;

  const navigate = useNavigate();
  const { isLoading, error } = useFetch();
  const toast = useToastContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [investment, setInvestment] = useState({
    investedAmount: "",
    futureInvestmentAmount: "",
  });

  const InvestorDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("invested-amount-error").classList.add("hidden");
    document.getElementById("future-amount-error").classList.add("hidden");
    document.getElementById("industry-limit").classList.add("hidden");
    document.getElementById("industry-limit").classList.add("hidden");
    if (investment.investedAmount === "") {
      document
        .getElementById("invested-amount-error")
        .classList.remove("hidden");
      return;
    }
    if (investment.futureInvestmentAmount === "") {
      document.getElementById("future-amount-error").classList.remove("hidden");
      return;
    }
    if (selectedIndustry.length < 1) {
      document.getElementById("industry-error").classList.remove("hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("hidden");
      return;
    }
    let logo = document.getElementById("profileImg").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("companies_invested_count", data.numberOfInvestments);
    formData.append("invested_amount", investment.investedAmount);
    formData.append("investor_industries", selectedIndustry);
    formData.append(
      "future_investment_amount",
      investment.futureInvestmentAmount
    );
    formData.append("linked_in", data.investorLinkedin);
    formData.append("date", getCurrentDate());
    axios
      .post(investorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/investor-dashboard");
      })
      .catch(() => toast.open("false", "Failed to submit"));
  };

  return (
    <div
      id="investorDetailsForm"
      className="flex col-span-7 authentication-bg relative bg-white md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:my-0 lg:py-3 md:col-start-2 md:col-span-5 lg:col-span-2"
    >
      <form
        onSubmit={handleSubmit(InvestorDetailsApiCall)}
        className="mx-auto p-5 rounded-md shadow-md md:w-full lg:p-3 lg:shadow-none lg:rounded-none lg:pt-0"
      >
        <h4 className="text-lg font-medium">
          Some details about your investment career
        </h4>
        <div className="mb-1">
          <label
            htmlFor="numberOfInvestments"
            className="text-sm mb-0 required"
          >
            Number of companies in your portfolio
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="number"
              className="border-0 w-full ps-3"
              id="numberOfInvestments"
              name="numberOfInvestments"
              placeholder="ex: 3"
              {...register("numberOfInvestments", {
                required: "portfolio count is required",
                pattern: {
                  value: numberRegex,
                  message: "Count must only contain digits",
                },
                maxLength: {
                  value: 3,
                  message: "Cannot exceed 3 digits",
                },
              })}
            />
            <span className="input-icons icon-span">
              <MoneyUpTrendIcon />
            </span>
          </div>
          {errors.numberOfInvestments && (
            <div>
              <FormError message={errors.numberOfInvestments.message} />
            </div>
          )}
        </div>
        <div className="mb-1 flex-col">
          <label htmlFor="investedAmount" className="text-sm mb-0 required">
            Total Investment done till now
          </label>
          <Dropdown
            name="investedAmount"
            id="investedAmount"
            options={[
              { label: "Not Applicable", value: "NA" },
              { label: "Upto 10Lakh", value: "Upto 10Lakh" },
              { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
              { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
              { label: "50Lakh-1Cr", value: "50Lakh-1Cr" },
              { label: "1Cr+", value: "More than 1Cr" },
            ]}
            onChange={(selectedOption) =>
              setInvestment((prev) => ({
                ...prev,
                investedAmount: selectedOption.value,
              }))
            }
          />
          <div className="hidden" id="invested-amount-error">
            <FormError message="Amount is required" />
          </div>
        </div>
        <div className="mb-1 flex-col">
          <label
            htmlFor="futureInvestmentAmount"
            className="text-sm mb-0 required"
          >
            How much are you looking to invest
          </label>
          <Dropdown
            name="futureInvestmentAmount"
            id="futureInvestmentAmount"
            options={[
              { label: "Upto 10Lakh", value: "Upto 10Lakh" },
              { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
              { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
              { label: "50Lakh-1Cr", value: "50Lakh-1Cr" },
              { label: "1Cr+", value: "More than 1Cr" },
            ]}
            onChange={(selectedOption) =>
              setInvestment((prev) => ({
                ...prev,
                futureInvestmentAmount: selectedOption.value,
              }))
            }
          />
          <div className="hidden" id="future-amount-error">
            <FormError message="Amount is required" />
          </div>
        </div>
        <div className="mb-1 flex-col">
          <p>
            Industry{" "}
            <span className="required">({selectedIndustry.length + "/3"})</span>
            <span id="industry-limit" className="hidden text-red-500 ms-2">
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setSelectedIndustry(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small className="text-red-500 mb-2 hidden" id="industry-error">
            This field is mandatory.
          </small>
        </div>
        <div className="mb-1">
          <label htmlFor="investorLinkedin" className="text-sm mb-0">
            Linkedin profile
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="border-0 w-full ps-3"
              id="investorLinkedin"
              name="investorLinkedin"
              placeholder="Linkedin URL"
              {...register("investorLinkedin", {
                validate: {
                  validUrl: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? urlRegex.test(fieldValue) || "Please enter a valid url"
                      : null;
                  },
                  bannedUrl: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different url"
                      : null;
                  },
                  maxLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length < 200 ||
                      "Cannot exceed 200 characters"
                    );
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <LinkedInIcon />
            </span>
          </div>
          {errors.investorLinkedin && (
            <div>
              <FormError message={errors.investorLinkedin.message} />
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="profileImg" className="text-sm mb-0">
            Profile Image
          </label>
          <div
            className="flex items-center rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/*"
              className="border-0 w-full ps-3"
              id="profileImg"
              name="profileImg"
              placeholder="Upload Profile Image"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        {isLoading && !error ? <Loader pt="10px" classes="my-2" /> : null}
        <Button
          type="submit"
          className={`py-1.5 mt-4 w-full ${
            isClicked && "disabled cursor-not-allowed"
          }`}
          bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
          disabled={isClicked}
        >
          Next
        </Button>
      </form>
    </div>
  );
};
