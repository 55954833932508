import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";
import { useToastContext } from "../context/ToastContext";

const useEditBank = () => {
  const updateBankDetailsApi = `${process.env.REACT_APP_API_URL}update-bank-details`;

  const { fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();

  const editBank = (id, data, callBackFn) => {
    fetchApi(
      {
        url: updateBankDetailsApi,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
          name: data.accountName,
          bank_name: data.bankName,
          account_number: data.accountNumber,
          ifsc: data.ifscCode,
        },
      },
      (data) => {
        if (data.message === "exists") {
          toast("Incorrect bank account number. Please try again");
          return;
        }
        callBackFn();
      }
    );
  };
  return { editBank };
};

export default useEditBank;
