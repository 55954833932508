import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import {
  numberRegex,
  textWithSpaceRegex,
  urlRegex,
  bannedInputs,
} from "../../../../constants";
import {
  BuildingIcon,
  GlobeIcon,
  LinkedInIcon,
  PhoneIcon,
  RupeeIcon,
  UserIcon,
} from "../../../../icons/icons";
import { Dropdown } from "../../inputs/dropdown";

export const EditStartupProfile = ({
  open,
  onClose,
  editStartupDetails,
  editData,
  industries,
}) => {
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const ref = useRef(false);

  const {
    register: editStartup,
    handleSubmit: editStartupSubmit,
    formState: { errors: editStartupErrors },
  } = useForm({
    defaultValues: {
      startupName: editData.name,
      startupPhone: editData.phone,
      companyName: editData.company_name,
      ceoName: editData.ceo_name,
      companyWebsite: editData.website,
      requireInvestment: editData.require_investment,
      noofemployees: editData.no_of_employees,
      startingDate: editData.business_start_date,
      startupLinkedin: editData.linked_in,
    },
  });

  useEffect(() => {
    if (!ref.current) {
      if (editData?.startup_industry) {
        setSelectedIndustry([...editData.startup_industry]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [editData]);

  if (!open) return null;

  const submitModal = (data) => {
    if (selectedIndustry.length < 1) {
      document.getElementById("industry-error").classList.remove("hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("select-limit").classList.remove("hidden");
      return;
    }
    document.getElementById("industry-error").classList.add("hidden");
    document.getElementById("select-limit").classList.add("hidden");
    editStartupDetails(data, selectedIndustry);
    setSelectedIndustry([]);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] max-h-[95vh] overflow-y-auto md:w-[75%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Update Profile
        </h4>
        <form onSubmit={editStartupSubmit(submitModal)} className="p-3 mt-3">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-3">
            <div className="mb-1">
              <label className="mb-0 text-xs required" htmlFor="startupName">
                Edit Name
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="text"
                  className="border-0 w-full ps-3"
                  id="startupName"
                  name="startupName"
                  placeholder="Enter Name"
                  {...editStartup("startupName", {
                    required: "Name is required",
                    validate: {
                      validLength: (fieldValue) => {
                        return fieldValue.trim().length > 0
                          ? fieldValue.trim().length > 2 ||
                              "minimum required length is 3"
                          : null;
                      },
                      validName: (fieldValue) => {
                        return fieldValue.trim.length > 0
                          ? !bannedInputs.some((value) =>
                              fieldValue.includes(value)
                            ) || "Enter a different name"
                          : null;
                      },
                    },
                    pattern: {
                      value: textWithSpaceRegex,
                      message:
                        "Invalid name, must not start or end with space and must contain only alphabets",
                    },
                  })}
                />
                <span className="input-icons icon-span w-[39px]">
                  <UserIcon />
                </span>
              </div>
              {editStartupErrors.startupName && (
                <div>
                  <FormError message={editStartupErrors.startupName.message} />
                </div>
              )}
            </div>
            <div className="mb-1">
              <label className="mb-0 text-xs required" htmlFor="startupPhone">
                Change Contact Number
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="text"
                  maxLength="12"
                  minLength="7"
                  className="border-0 w-full ps-3"
                  id="startupPhone"
                  name="startupPhone"
                  placeholder="Enter phone number"
                  {...editStartup("startupPhone", {
                    required: "Contact is required",
                    validate: {
                      minValue: (fieldValue) => {
                        return fieldValue.length > 0
                          ? fieldValue.length > 6 ||
                              "Number should be atleast 7 digits"
                          : null;
                      },
                      maxValue: (fieldValue) => {
                        return fieldValue.length > 0
                          ? fieldValue.length < 13 ||
                              "Number cannot be more than 12 digits"
                          : null;
                      },
                    },
                    pattern: {
                      value: numberRegex,
                      message: "Number must only contain digits",
                    },
                  })}
                />
                <span className="input-icons icon-span w-[39px]">
                  <PhoneIcon />
                </span>
              </div>
              {editStartupErrors.startupPhone && (
                <div>
                  <FormError message={editStartupErrors.startupPhone.message} />
                </div>
              )}
            </div>
            <div className="mb-1">
              <label className="mb-0 text-xs required" htmlFor="companyName">
                Company Name
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="text"
                  className="border-0 w-full ps-3"
                  id="companyName"
                  name="companyName"
                  placeholder="ex: Pitchhere"
                  {...editStartup("companyName", {
                    required: "Company Name is required",
                    validate: {
                      validLength: (fieldValue) => {
                        return fieldValue.trim().length > 0
                          ? fieldValue.trim().length > 1 ||
                              "minimum required length is 2"
                          : null;
                      },
                      validName: (fieldValue) => {
                        return fieldValue.trim.length > 0
                          ? !bannedInputs.some((value) =>
                              fieldValue.includes(value)
                            ) || "Enter a different name"
                          : null;
                      },
                    },
                  })}
                />
                <span className="input-icons icon-span w-[39px]">
                  <BuildingIcon />
                </span>
              </div>
            </div>
            <div className="mb-1">
              <label className="mb-0 text-xs" htmlFor="companyWebsite">
                Change company website url
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="text"
                  className="border-0 w-full ps-3"
                  id="companyWebsite"
                  name="companyWebsite"
                  placeholder="Enter complete url"
                  {...editStartup("companyWebsite", {
                    validate: {
                      validUrl: (fieldValue) => {
                        return fieldValue.trim().length > 0
                          ? urlRegex.test(fieldValue) ||
                              "Please enter a valid url"
                          : null;
                      },
                      bannedUrl: (fieldValue) => {
                        return fieldValue.trim().length > 0
                          ? !bannedInputs.some((value) =>
                              fieldValue.includes(value)
                            ) || "Enter a different url"
                          : null;
                      },
                    },
                  })}
                />
                <span className="input-icons icon-span w-[39px]">
                  <GlobeIcon />
                </span>
              </div>
              {editStartupErrors.companyWebsite && (
                <div>
                  <FormError
                    message={editStartupErrors.companyWebsite.message}
                  />
                </div>
              )}
            </div>
            <div className="mb-1">
              <label
                htmlFor="requireInvestment"
                className="mb-0 text-xs required"
              >
                How much investment are you looking for
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{ border: "1px solid #ced4da" }}
              >
                <input
                  type="number"
                  className="border-0 w-full ps-3"
                  id="requireInvestment"
                  name="requireInvestment"
                  placeholder="ex: 1500000"
                  {...editStartup("requireInvestment", {
                    required: "Required Investment is mandatory",
                    pattern: {
                      value: numberRegex,
                      message: "Amount must only contain digits",
                    },
                  })}
                />
                <span className="input-icons icon-span">
                  <RupeeIcon />
                </span>
              </div>
              {editStartupErrors.requireInvestment && (
                <div>
                  <FormError
                    message={editStartupErrors.requireInvestment.message}
                  />
                </div>
              )}
            </div>
            <div className="my-1 flex-col">
              <p className="text-xs">
                Industry <span>({selectedIndustry.length + "/3"})</span>
                <span
                  id="select-limit"
                  className="hidden text-red-500 text-xs ms-2"
                >
                  Limit exceeded
                </span>
              </p>
              <Dropdown
                options={industries?.map((industry) => ({
                  label: industry.industry_name
                    .replace(new RegExp("_", "g"), " ")
                    .toUpperCase(),
                  value: industry.industry_name,
                }))}
                value={selectedIndustry.map((industry) => ({
                  label: industry
                    .replace(new RegExp("_", "g"), " ")
                    .toUpperCase(),
                  value: industry,
                }))}
                onChange={(selectedOptions) =>
                  setSelectedIndustry(
                    selectedOptions.map((option) => option.value)
                  )
                }
                isMulti
              />
              <small className="text-red-500 mb-2 hidden" id="industry-error">
                This field is mandatory.
              </small>
            </div>
            <div className="mb-1 flex-col">
              <label className="mb-0 text-xs" htmlFor="noofemployees">
                No. of employees
              </label>
              <div className="role-input form-floating form-floating-outline w-full border border-slate-300 rounded-md m-auto">
                <select
                  name="noofemployees"
                  id="noofemployees"
                  className="border-0 w-full text-gray-500"
                  {...editStartup("noofemployees")}
                >
                  <option value="">Select</option>
                  <option value="1-10">1-10</option>
                  <option value="10-100">10-100</option>
                  <option value="100-1000">100-1000</option>
                  <option value="1000+">1000+</option>
                </select>
              </div>
            </div>
            <div className="mb-1">
              <label className="mb-0 text-xs" htmlFor="startingDate">
                Company's Starting Date
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="date"
                  className="border-0 w-full px-3 py-3"
                  id="startingDate"
                  name="startingDate"
                  {...editStartup("startingDate")}
                />
              </div>
            </div>
            <div className="md:col-span-2 mb-1">
              <label htmlFor="startupLinkedin" className="mb-0 text-sm">
                Linkedin profile{" "}
                <small>
                  ( LinkedIn profiles boost authenticity and credibility—highly
                  recommended. )
                </small>
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{ border: "1px solid #ced4da" }}
              >
                <input
                  type="text"
                  className="border-0 w-full ps-3"
                  id="startupLinkedin"
                  name="startupLinkedin"
                  placeholder="Linkedin URL"
                  {...editStartup("startupLinkedin", {
                    validate: {
                      validUrl: (fieldValue) => {
                        return fieldValue.trim().length > 0
                          ? urlRegex.test(fieldValue) ||
                              "Please enter a valid url"
                          : null;
                      },
                      bannedUrl: (fieldValue) => {
                        return fieldValue.trim().length > 0
                          ? !bannedInputs.some((value) =>
                              fieldValue.includes(value)
                            ) || "Enter a different url"
                          : null;
                      },
                    },
                  })}
                />
                <span className="input-icons icon-span">
                  <LinkedInIcon />
                </span>
              </div>
              {editStartupErrors.startupLinkedin && (
                <div>
                  <FormError
                    message={editStartupErrors.startupLinkedin.message}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end p-3">
            <Button
              onClick={onClose}
              className="px-2 py-1 mx-1 text-sm"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1 text-sm">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
