import ReactDom from "react-dom";

export const ViewMeetingDetails = ({ open, onClose, meetingData, userId }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-base">
          Meeting Details
        </h4>
        <div className="grid grid-cols-2 p-3 gap-3">
          <p className="col-span-2 font-medium">
            Participant :{" "}
            <span className="font-normal">
              {userId === meetingData.reciever_id
                ? meetingData.sender_name
                : meetingData.reciever_name}
            </span>
          </p>
          <p className="font-medium">
            Status :{" "}
            <span
              className={
                meetingData.status === "0"
                  ? "text-orange-600"
                  : meetingData.status === "1"
                  ? "text-blue-600"
                  : meetingData.status === "2"
                  ? "text-green-600"
                  : "text-red-600"
              }
            >
              {meetingData.status === "0"
                ? "Pending"
                : meetingData.status === "1"
                ? "Accepted"
                : meetingData.status === "2"
                ? "Completed"
                : "Cancelled"}
            </span>
          </p>
          <p className="font-medium">
            Date : <span className="font-normal">{meetingData.date}</span>
          </p>
          <p className="font-medium">
            Start Time : <span className="font-normal">{meetingData.time}</span>
          </p>
          <p className="font-medium">
            End Time :{" "}
            <span className="font-normal">{meetingData.exp_time}</span>
          </p>
          <p className="col-span-2 font-medium">
            Title : <span className="font-normal">{meetingData.title}</span>
          </p>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
