export const capitalizeText = (title) => {
  if (!title) return "";

  const delimiter = title.includes(".") ? "." : " ";

  if (!title.includes(" ") && !title.includes(".")) {
    return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
  }

  return title
    .split(delimiter)
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(delimiter);
};
