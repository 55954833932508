import { useContext, useEffect, useState } from "react";
import ReactDom from "react-dom";
import { VideoClientContext } from "../../context/videoContext";
import { Button } from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const Preview = ({ handleSessionState }) => {
  const navigate = useNavigate();

  const uitoolkit = useContext(VideoClientContext);
  const [openPreviewModal, setOpenPreviewModal] = useState(true);

  useEffect(() => {
    let preview = document.getElementById("previewContainer");
    uitoolkit.openPreview(preview);
    return () => {
      uitoolkit.closePreview(preview);
    };
  }, [uitoolkit]);

  const handleClosePreview = () => {
    setOpenPreviewModal(false);
    navigate("/meetings");
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        openPreviewModal ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`md:max-w-[70%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          openPreviewModal ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={() => handleClosePreview()} className="closeBtn">
          X
        </p>
        <div id="previewContainer"></div>
        <Button
          onClick={handleSessionState}
          className="block ml-auto px-3 py-1.5 m-1"
        >
          Join
        </Button>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default Preview;
