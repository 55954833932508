import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimension";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { setUser, deleteUser } from "../../store/slices/LoginSlice";
import { useToastContext } from "../../context/ToastContext";
import { RegistrationForm } from "./RegistrationForm";
import { StartupDetailsForm } from "./StartupDetailsForm";
import { MentorDetailsForm } from "./MentorDetailsForm";
import { InvestorDetailsForm } from "./InvestorDetailsForm";
import { VendorDetailsForm } from "./VendorDetailsForm";
import "./Register.css";
import "../../custom.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";

const Register = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const toast = useToastContext();
  const { displayIndustries } = useDisplayIndustries();

  const [token, setToken] = useState("");
  const [industries, setIndustries] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const runOnce = useRef(true);

  useEffect(() => {
    dispatch(deleteUser());

    document.getElementById("startupForm").classList.add("hidden");
    document.getElementById("mentorDetailsForm").classList.add("hidden");
    document.getElementById("investorDetailsForm").classList.add("hidden");
    document.getElementById("vendorDetailsForm").classList.add("hidden");
    return () => {
      runOnce.current = false;
    };
  }, [dispatch, displayIndustries]);

  const postRegistration = (data) => {
    setIsClicked(false);
    if (data.message === "validation error") {
      toast.open("false", "Sorry, Failed to submit. Validation failed.");
      return;
    }
    if (data.message === "exists") {
      document.getElementById("email-exists").classList.remove("hidden");
      return;
    } else {
      dispatch(
        setUser({ id: data.jwt_token, role: data.role, name: data.name })
      );
      setToken(data.jwt_token);
      displayIndustries(
        (data) => setIndustries(data.industries),
        data.role === "V&iR8" ? "service provider" : "other"
      );
      if (data.role === "f@!3A") {
        document.getElementById("registrationForm").classList.add("hidden");
        document.getElementById("startupForm").classList.remove("hidden");
      }
      if (data.role === "R$7s2") {
        document.getElementById("registrationForm").classList.add("hidden");
        document.getElementById("mentorDetailsForm").classList.remove("hidden");
      }
      if (data.role === "Sw%aa") {
        document.getElementById("registrationForm").classList.add("hidden");
        document
          .getElementById("investorDetailsForm")
          .classList.remove("hidden");
      }
      if (data.role === "V&iR8") {
        document.getElementById("registrationForm").classList.add("hidden");
        document.getElementById("vendorDetailsForm").classList.remove("hidden");
      }
    }
  };

  return (
    <div className="authentication-wrapper authentication-cover">
      {width < 1024 ? (
        <p className="text-center pt-5">
          <Link to="/">
            <img
              src={pitchHereLogo}
              alt="skilldukan-logo"
              width={200}
              className="inline"
            />
          </Link>
        </p>
      ) : (
        <p className="text-left">
          <Link to="/" className="auth-cover-brand md:ps-5">
            <img src={pitchHereLogo} alt="skilldukan-logo" width={140} />
          </Link>
        </p>
      )}
      <div className="md:h-screen w-full relative grid grid-cols-7 lg:bg-white mt-[70px] md:mt-0">
        <div className="hidden main-bg-img lg:flex col-span-5 items-center justify-end p-5 pb-2"></div>
        <RegistrationForm
          postRegistration={postRegistration}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
        />
        <StartupDetailsForm token={token} industries={industries} />
        <MentorDetailsForm token={token} industries={industries} />
        <InvestorDetailsForm token={token} industries={industries} />
        <VendorDetailsForm token={token} industries={industries} />
      </div>
    </div>
  );
};

export default Register;
