import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store, { persistor } from "./store/Store";
import { PersistGate } from "redux-persist/integration/react";
import { FilterDetailsProvider } from "./context/filterContext";
import { ToastProvider } from "./context/ToastContext";
import { VideoClientContext } from "./context/videoContext";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import App from "./App";
import "./index.css";

//remove filter details provider
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <FilterDetailsProvider>
          <VideoClientContext.Provider value={uitoolkit}>
            <ToastProvider>
              <App id="app" />
            </ToastProvider>
          </VideoClientContext.Provider>
        </FilterDetailsProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
