import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";
import { updateName } from "../store/slices/LoginSlice";

const useUpdateProfile = () => {
  const { token } = useLocalStorage();
  const { fetchApi } = useFetch();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateProfile = (url, dataObj, callBackFn, redirect) => {
    fetchApi(
      {
        url: url,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: dataObj,
      },
      (data) => {
        if (data.message === "validation error") {
          alert("Sorry, Failed to submit. Validation failed");
          return;
        }
        if (dataObj.name) {
          dispatch(updateName(dataObj.name));
        }
        navigate(`/${redirect}-profile`);
        callBackFn();
      }
    );
  };

  return { updateProfile };
};

export default useUpdateProfile;
