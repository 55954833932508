import "./revenueCard.css";

const RevenueCard = ({
  title,
  amount,
  bgColor = "bg-primary",
  borderColor = "border-primary",
  classes = "",
}) => {
  return (
    <div className={`plan-card border-b-4 ${borderColor} ${classes}`}>
      <h4>{title}</h4>
      <div className={`etiquet-price ${bgColor} text-white`}>
        <p>{amount}</p>
        <div className={`border-t-[13px] ${borderColor}`}></div>
      </div>
    </div>
  );
};

export default RevenueCard;
