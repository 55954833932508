import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import { Loader } from "../../Loader";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import "../../../../custom.css";
import { numberRegex } from "../../../../constants";
import { RupeeIcon } from "../../../../icons/icons";

export const EditAgreementModal = ({
  open,
  onClose,
  propDetails,
  editAgreement,
}) => {
  const { isLoading, error } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (!open) return null;

  const updateAgreement = (data) => {
    editAgreement(propDetails.agreementId, data, propDetails.userId);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[50%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Edit Agreement Details
        </h4>
        <form
          onSubmit={handleSubmit(updateAgreement)}
          className="grid grid-cols-2 gap-3 p-3"
        >
          <div>
            <h5 className="mb-2 font-medium">Current Values</h5>
            <div className="mb-1">
              <label className="mb-0 text-sm" htmlFor="editAmount">
                Amount(&#8377;)
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="text"
                  className="border-0 w-full ps-3"
                  id="editAmount"
                  name="editAmount"
                  value={propDetails.amount}
                  disabled
                />
                <span className="input-icons icon-span">
                  <RupeeIcon />
                </span>
              </div>
            </div>
          </div>
          <div>
            <h5 className="mb-2 font-medium">New Values</h5>
            <div className="mb-1">
              <label className="mb-0 text-sm required" htmlFor="editAmount">
                Amount(in Rupees)
              </label>
              <div className="flex border border-gray-400 rounded-md overflow-hidden">
                <input
                  type="text"
                  className="w-full px-2 py-1"
                  id="editAmount"
                  name="editAmount"
                  autoFocus
                  {...register("editAmount", {
                    required: "amount is required",
                    pattern: {
                      value: numberRegex,
                      message: "Number must only contain digits",
                    },
                    validate: (fieldvalue) => {
                      return parseInt(fieldvalue) === 0
                        ? "Amount cannot be 0"
                        : null;
                    },
                  })}
                />
                <span className="input-icons icon-span">
                  <RupeeIcon />
                </span>
              </div>
              {errors.editAmount && (
                <div>
                  <FormError message={errors.editAmount.message} />
                </div>
              )}
            </div>
          </div>
          <div className="modalFooter col-span-2 border-t p-3">
            <Button
              onClick={onClose}
              className="px-2 py-1 mx-1"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1">
              Update
            </Button>
          </div>
        </form>
        {isLoading && <Loader />}
        {error && (
          <p className="text-red-500 text-xs my-1">
            Sorry something went wrong. Please try again later
          </p>
        )}
      </div>
    </div>,
    document.getElementById("portal")
  );
};
