import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { urlRegex } from "../../../../constants";
import { GlobeIcon, UserIcon } from "../../../../icons/icons";

export const PrevInvestmentModal = ({
  open,
  onClose,
  updatePrevInvestments,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    updatePrevInvestments(data);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-lg">
          Previous Investment Detail
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-2 pb-0">
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="prevCompanyName">
              Company Name
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="prevCompanyName"
                name="prevCompanyName"
                autoFocus
                {...register("prevCompanyName", {
                  required: "Company name is required",
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.prevCompanyName && (
              <div>
                <FormError message={errors.prevCompanyName.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm" htmlFor="prevCompanyUrl">
              Company Website Link
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="prevCompanyUrl"
                name="prevCompanyUrl"
                {...register("prevCompanyUrl", {
                  validate: (fieldvalue) => {
                    return fieldvalue.trim().length > 0
                      ? urlRegex.test(fieldvalue) || "Enter a valid url"
                      : null;
                  },
                })}
              />
              <span className="input-icons icon-span">
                <GlobeIcon />
              </span>
            </div>
            {errors.prevCompanyUrl && (
              <div>
                <FormError message={errors.prevCompanyUrl.message} />
              </div>
            )}
          </div>
          <div className="col-start-2 text-right p-2">
            <Button
              onClick={onClose}
              className="px-1 py-0.5 mx-1"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-1 py-0.5 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
