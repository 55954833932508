import ReactDom from "react-dom";
import PlanForm from "./planForm";
import { useState } from "react";
import { useForm } from "react-hook-form";

const AddPlan = ({ open, onClose, addSubscription }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [features, setFeatures] = useState([]);

  if (!open) return null;

  const submitModal = (data) => {
    if (features.length < 1) {
      document.getElementById("feature-required").classList.remove("hidden");
      return;
    } else {
      document.getElementById("feature-required").classList.add("hidden");
    }
    addSubscription(data, features);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[750px] md:max-w-[750px] max-h-[95vh] overflow-y-auto bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 capitalize text-base">
          Create New Token Plan
        </h4>
        <PlanForm
          submitModal={submitModal}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          features={features}
          setFeatures={setFeatures}
        />
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default AddPlan;
