import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { Button } from "../../components/ui/Button";
import { EyeIcon, SlashedEyeIcon } from "../../icons/icons";
import "../register-page/Register.css";
import "./Signin.css";
import Favicon from "../../images/favicon.png";

const ResetPassword = () => {
  const resetPasswordApi = `${process.env.REACT_APP_API_URL}reset-password`;
  const resetAdminPasswordApi = `${process.env.REACT_APP_API_URL}reset-password-admin`;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const { token } = useParams();
  const { role, token } = useParams();
  const { isLoading, error, fetchApi } = useFetch();

  const [show, setShow] = useState(false);

  const resetPasswordApiCall = async (data) => {
    fetchApi(
      {
        // url: resetPasswordApi,
        url: role === "user" ? resetPasswordApi : resetAdminPasswordApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: {
          verifytoken: token,
          password: data.resetPassword,
        },
      },
      (data) => {
        if (data.message === "success") {
          document.getElementById("resetPasswordForm").classList.add("hidden");
          document
            .getElementById("resetConfirmation")
            .classList.remove("hidden");
        } else {
          document.getElementById("resetErrorText").classList.remove("hidden");
        }
      }
    );
  };

  return (
    <div className="resetPasswordContainer flex flex-col items-center md:justify-center p-3 md:p-0">
      <img src={Favicon} alt="pitchere-logo" width={80} className="my-2" />
      <div
        className="card shadow-md p-4 w-full md:w-[40%]"
        id="resetPasswordForm"
      >
        <h4 className="text-center font-medium">
          You can now reset your password
        </h4>
        <form onSubmit={handleSubmit(resetPasswordApiCall)}>
          <input
            type="text"
            id="token"
            name="token"
            value={token}
            hidden
            readOnly
          />
          <div className="mb-1">
            <label htmlFor="resetPassword" className="mb-0 text-sm required">
              New Password
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type={show ? "text" : "password"}
                id="resetPassword"
                name="resetPassword"
                className="border-0 w-full ps-3"
                placeholder="Enter new password"
                {...register("resetPassword", {
                  required: true,
                  minLength: 7,
                })}
              />
              <span
                className="input-icons cursor-pointer icon-span"
                onClick={() => setShow(!show)}
              >
                {show ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.password && (
              <span className="text-secondary">Password is required</span>
            )}
          </div>
          {isLoading && <Loader pt="10px" />}
          {error && <Error />}
          <p className="text-red-500 mt-1 hidden" id="resetErrorText">
            Invalid token. Please try again.
          </p>
          <Button type="submit" className="px-3 py-1 mt-3 w-fit">
            Reset Password
          </Button>
        </form>
      </div>
      <div
        className="card shadow-md p-4 cstm-width hidden text-center"
        id="resetConfirmation"
      >
        <p className="font-medium">Congrats! Password reset successfully.</p>
        <Link
          to="/"
          className="bg-primary w-4/12 m-auto text-white px-2 py-1 rounded-md my-2"
        >
          Login
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
