import "./chat.css";
import FavIcon from "../../images/favicon.png";

const ChatList = (props) => {
  const { data, getRoomId, unreadCount } = props;

  return (
    <li
      onClick={() =>
        getRoomId(data.connection_id, data.role, data.status, data.deactivate)
      }
      className="mb-2 flex items-center justify-between"
    >
      <div className="flex items-center">
        <div className="relative">
          <img
            className="rounded-full h-[45px]"
            src={data.logo.length > 0 ? data.logo : FavIcon}
            alt="connection-img"
            width={45}
            loading="lazy"
          />
          {/* <div className="online-status-circle"></div> */}
        </div>
        <div className="ps-2">
          <p className="font-medium capitalize">{data.name}</p>
          {data.role === "f@!3A" ? (
            <span className="text-xs px-1 bg-green-500 text-white rounded-md">
              Startup
            </span>
          ) : data.role === "R$7s2" ? (
            <span className="text-xs px-1 bg-primary text-white rounded-md">
              Mentor
            </span>
          ) : data.role === "Sw%aa" ? (
            <span className="text-xs px-1 bg-secondary text-white rounded-md">
              Investor
            </span>
          ) : (
            <span className="text-xs px-1 bg-orange-500 text-white rounded-md">
              Vendor
            </span>
          )}
        </div>
      </div>
      {unreadCount > 0 && (
        <p className="bg-secondary text-white rounded-full p-0.5 w-5 h-5 flex items-center justify-center">
          {unreadCount}
        </p>
      )}
    </li>
  );
};

export default ChatList;
