import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const displaySampleDocsApi = `${process.env.REACT_APP_API_URL}diplay-sample-document`;

const initialState = {
  templates: [],
  doc_types: [],
  status: "idle",
  error: null,
};

export const fetchTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(displaySampleDocsApi, {
        headers: {
          "x-token": token,
        },
      });
      const data = response.data;

      return {
        templates: data.details,
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "An error occurred while fetching notifications.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

const sampleTemplateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    clearTemplates: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.templates = action.payload.templates;
        state.doc_types = [
          "All",
          ...new Set(action.payload.templates.map((doc) => doc.document_type)),
        ];
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearTemplates } = sampleTemplateSlice.actions;
export default sampleTemplateSlice.reducer;
