import Select from "react-select";
import makeAnimated from "react-select/animated";

export const Dropdown = ({
  options,
  styles = {
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: "180px",
      overflowY: "auto",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      minHeight: "49px",
    }),
  },
  required = true,
  closeMenuOnSelect = false,
  ...props
}) => {
  const animatedComponents = makeAnimated();

  return (
    <Select
      options={options}
      closeMenuOnSelect={closeMenuOnSelect}
      components={animatedComponents}
      styles={styles}
      required={required}
      {...props}
    />
  );
};
