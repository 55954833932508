import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import "../../../../custom.css";
import { IndustryIcon } from "../../../../icons/icons";
import { bannedInputs } from "../../../../constants";
import { FormError } from "../../FormError";

export const AddIndustryModal = ({
  open,
  onClose,
  addIndustry,
  updateIndustry,
  selectedIndustry,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      newIndustry: selectedIndustry.industry.replace(new RegExp("_", "g"), " "),
      industryRole: selectedIndustry.role || "other",
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  if (!open) return null;

  const submitModal = (data) => {
    const newIndustry = data.newIndustry
      .trim()
      .toLowerCase()
      .replace(new RegExp(" ", "g"), "_");
    if (selectedIndustry.industry.length > 0) {
      updateIndustry(selectedIndustry.id, {
        industry_name: newIndustry,
        role: data.industryRole,
      });
    } else {
      addIndustry(newIndustry, data.industryRole);
    }
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 capitalize text-base">
          {selectedIndustry.industry.length < 1
            ? "Add New Industry"
            : "Edit Industry"}
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-3">
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="newIndustry">
              New Industry
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="newIndustry"
                name="newIndustry"
                placeholder="Enter Industry"
                autoFocus
                {...register("newIndustry", {
                  required: "Industry is required",
                  validate: {
                    validLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 1 ||
                        "minimum required length is 2"
                      );
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Invalid Industry name"
                      );
                    },
                  },
                })}
              />
              <span className="input-icons icon-span">
                <IndustryIcon />
              </span>
            </div>
            {errors.newIndustry && (
              <div>
                <FormError message={errors.newIndustry.message} />
              </div>
            )}
          </div>
          <div className="mb-1 pt-2 flex-col">
            <label className="required" htmlFor="industryRole">
              Select Role
            </label>
            <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
              <select
                name="industryRole"
                id="industryRole"
                className="border-0 w-full text-gray-500"
                {...register("industryRole", {
                  required: "Role is required",
                })}
              >
                <option value="other">Other</option>
                <option value="service provider">Service Provider</option>
              </select>
            </div>
            {errors.industryRole && (
              <div>
                <FormError message={errors.industryRole.message} />
              </div>
            )}
          </div>
          <Button type="submit" className="block ml-auto px-1.5 py-1 mt-2">
            Add
          </Button>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
