import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";

export const StartupStageModal = ({
  open,
  onClose,
  editStartupStage,
  editData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      startupStage: editData.startup_stage,
      prevInvestedAmount: editData.investment_amount,
    },
  });

  const [showPrevInvestmentDetails, setShowPrevInvestmentDetails] = useState(
    editData.previous_investment === "yes"
  );

  useEffect(() => {
    setShowPrevInvestmentDetails(editData.previous_investment === "yes");

    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset, editData]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    const prevInvestment = document.getElementById("prevInvestment").value;
    editStartupStage(data, prevInvestment);
    onClose();
  };

  const handlePrevInfo = () => {
    setShowPrevInvestmentDetails(
      document.getElementById("prevInvestment").value === "yes"
    );
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-base">
          More Information
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-2 pb-0">
          <div className="mb-1">
            <label className="mb-0 text-sm" htmlFor="startupStage">
              Startup stage
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <select
                className="form-select border-0 p-2 w-full"
                name="startupStage"
                id="startupStage"
                {...register("startupStage")}
              >
                <option value="">Select Stage</option>
                <option value="seed-stage">Seed stage</option>
                <option value="early-funded">Early stage</option>
                <option value="growth-stage">Growth stage</option>
                <option value="late-stage">Late stage</option>
              </select>
            </div>
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm" htmlFor="prevInvestment">
              Any Previous Round Investment
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <select
                className="form-select border-0 p-2 w-full"
                name="prevInvestment"
                id="prevInvestment"
                value={showPrevInvestmentDetails ? "yes" : "no"}
                onChange={handlePrevInfo}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
          {showPrevInvestmentDetails && (
            <div className="mb-1" id="prevInvestmentDetails">
              <label className="mb-0 text-sm" htmlFor="prevInvestedAmount">
                Previous Investment Amount
              </label>
              <div
                className="flex rounded-md overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <select
                  className="form-select border-0 p-2 w-full"
                  name="prevInvestedAmount"
                  id="prevInvestedAmount"
                  {...register("prevInvestedAmount")}
                >
                  <option value="">Select Amount</option>
                  <option value="Less than 5Lakh">Upto 5Lakh</option>
                  <option value="5Lakh-10Lakh">5Lakh-10Lakh</option>
                  <option value="10Lakh-25Lakh">10Lakh-25Lakh</option>
                  <option value="25Lakh-50Lakh">25Lakh-50Lakh</option>
                  <option value="More than 50Lakh">50Lakh+</option>
                </select>
              </div>
            </div>
          )}
          <div className="col-start-2 text-right p-2">
            <Button type="submit" className="px-1 py-0.5 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
