import React, { useState } from "react";
import {
  CoinsIcon,
  CommentIcon,
  DashboardIcon,
  DownArrowIcon,
  RocketIcon,
  RupeeIcon,
  StoreIcon,
  UserIcon,
  UserPenIcon,
  WalletIcon,
} from "../../icons/icons";
import { Link } from "react-router-dom";

const VendorSidebar = ({ currPath, activeStyle, regularStyle }) => {
  const [subMenuOpen, setSubMenuOpen] = useState({
    open: false,
    parentTitle: null,
  });

  const sidebarMenu = [
    {
      title: "Profile",
      icon: (
        <UserIcon
          fill={
            currPath === "/vendor-profile" || currPath === "/reviews"
              ? "#fff"
              : "#999999"
          }
        />
      ),
      submenu: true,
      submenuItems: [
        {
          title: "Profile",
          icon: (
            <UserIcon
              fill={currPath === "/vendor-profile" ? "#fff" : "#999999"}
            />
          ),
          to: "/vendor-profile",
        },
        {
          title: "Reviews",
          icon: (
            <UserPenIcon fill={currPath === "/reviews" ? "#fff" : "#999999"} />
          ),
          to: "/reviews",
        },
      ],
    },
    {
      title: "Startups",
      icon: <RocketIcon fill={currPath === "/startups" ? "#fff" : "#999999"} />,
      to: "/startups",
    },
    {
      title: "Market",
      icon: (
        <StoreIcon
          fill={
            currPath === "/market" || currPath === "/my-projects"
              ? "#fff"
              : "#999999"
          }
        />
      ),
      submenu: true,
      submenuItems: [
        {
          title: "Market Place",
          icon: (
            <StoreIcon fill={currPath === "/market" ? "#fff" : "#999999"} />
          ),
          to: "/market",
        },
        {
          title: "My Bids",
          icon: (
            <RupeeIcon fill={currPath === "/my-bids" ? "#fff" : "#999999"} />
          ),
          to: "/my-bids",
        },
      ],
    },
    {
      title: "Wallet",
      icon: (
        <WalletIcon
          fill={
            currPath === "/vendor-wallet" || currPath === "/store"
              ? "#fff"
              : "#999999"
          }
        />
      ),
      spacing: true,
      submenu: true,
      submenuItems: [
        {
          title: "Wallet",
          icon: (
            <WalletIcon
              fill={currPath === "/vendor-wallet" ? "#fff" : "#999999"}
            />
          ),
          to: "/vendor-wallet",
        },
        {
          title: "Token Store",
          icon: (
            <CoinsIcon
              fill={currPath === "/vendor-plan" ? "#fff" : "#999999"}
            />
          ),
          to: "/vendor-plan",
        },
      ],
    },
    {
      title: "Chat",
      icon: <CommentIcon fill={currPath === "/chat" ? "#fff" : "#999999"} />,
      to: "/chat",
    },
  ];

  return (
    <ul className="pt-2 sidebar-main">
      {sidebarMenu.map((menu, i) => (
        <React.Fragment key={i}>
          <li>
            <Link
              to={menu.to}
              className={
                currPath === menu.to ||
                menu?.submenuItems?.some((item) => item.to === currPath)
                  ? activeStyle
                  : regularStyle
              }
              onClick={() => {
                if (menu.submenu) {
                  setSubMenuOpen((prev) => ({
                    open: !prev.open,
                    parentTitle: menu.title,
                  }));
                }
              }}
            >
              <span className="block float-left">
                {menu.icon ? (
                  menu.icon
                ) : (
                  <DashboardIcon
                    fill={currPath === menu.to ? "#fff" : "#999999"}
                  />
                )}
              </span>
              <span className="font-medium flex-1 duration-200 text-sm">
                {menu.title}
              </span>
              {menu.submenu && (
                <DownArrowIcon
                  width="11px"
                  height="11px"
                  fill={currPath === menu.to ? "#fff" : "#999999"}
                  className={`${
                    subMenuOpen.open &&
                    subMenuOpen.parentTitle === menu.title &&
                    "rotate-180"
                  }`}
                />
              )}
            </Link>
          </li>
          {menu.submenu &&
            subMenuOpen.open &&
            subMenuOpen.parentTitle === menu.title && (
              <ul>
                {menu.submenuItems.map((submenuItem, index) => (
                  <li key={index}>
                    <Link
                      to={submenuItem.to}
                      className={`${
                        currPath === submenuItem.to ? activeStyle : regularStyle
                      } ml-8`}
                    >
                      <span className="block float-left">
                        {submenuItem.icon ? (
                          submenuItem.icon
                        ) : (
                          <DashboardIcon
                            fill={
                              currPath === submenuItem.to ? "#fff" : "#999999"
                            }
                          />
                        )}
                      </span>
                      <span className="font-medium flex-1 duration-200 text-sm">
                        {submenuItem.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default VendorSidebar;
