import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useForm } from "react-hook-form";
import useCheckRole from "../../hooks/useCheckRole";
import useFetch from "../../hooks/useFetch";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { useToastContext } from "../../context/ToastContext";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { FormError } from "../../components/ui/FormError";
import { Button } from "../../components/ui/Button";
import { numberRegex, urlRegex, numberWithDecimalRegex } from "../../constants";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { getCurrentDate } from "../../utils/getCurrentDate";
import "./Profile.css";
import "../../custom.css";
import {
  BriefCaseIcon,
  BuildingIcon,
  LinkedInIcon,
  MoneyUpTrendIcon,
  RedirectIcon,
  RupeeIcon,
  UploadIcon,
} from "../../icons/icons";

const ProfileCompletion = () => {
  const businessDetailsApi = `${process.env.REACT_APP_API_URL}insert-business-details`;
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}insert-mentor-details`;
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}insert-investor-details`;
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}insert-vendor-details`;
  const navigate = useNavigate();
  const toast = useToastContext();
  const { error } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: mentorRegister,
    handleSubmit: mentorSubmit,
    formState: { errors: mentorError },
  } = useForm();
  const {
    register: investorRegister,
    handleSubmit: investorSubmit,
    formState: { errors: investorError },
  } = useForm();
  const {
    register: vendorRegister,
    handleSubmit: vendorSubmit,
    formState: { errors: vendorError },
  } = useForm();

  const token = useSelector((state) => {
    return state.users[0].id;
  });
  const getRole = useSelector((state) => {
    return state.users[0].role;
  });
  const { displayIndustries } = useDisplayIndustries();

  const { role, CheckRole } = useCheckRole();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [startupIndustry, setStartupIndustry] = useState([]);
  const [mentorIndustry, setMentorIndustry] = useState([]);
  const [investorIndustry, setInvestorIndustry] = useState([]);
  const [investment, setInvestment] = useState({
    investedAmount: "",
    futureInvestmentAmount: "",
  });
  const [isClicked, setIsClicked] = useState(false);

  const businessDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("duplicate-error").classList.add("hidden");
    document.getElementById("category-error").classList.add("hidden");
    document.getElementById("category-limit").classList.add("hidden");
    if (startupIndustry.length < 1) {
      document.getElementById("category-error").classList.remove("hidden");
      return;
    }
    if (startupIndustry.length > 3) {
      document.getElementById("category-limit").classList.remove("hidden");
      return;
    }
    let logo = document.getElementById("startupLogo").files[0];
    const formData = new FormData();
    formData.append("file", logo);
    formData.append("company_name", data.companyName);
    formData.append("require_investment", data.requireInvestment);
    formData.append("startup_industry", startupIndustry.join(","));
    formData.append("website", data.website);
    formData.append("linked_in", data.startupLinkedin);
    formData.append("date", getCurrentDate());

    axios
      .post(businessDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "duplicate") {
          document.getElementById("duplicate-error").classList.remove("hidden");
          return;
        }
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/startup-dashboard");
      })
      .catch(() => {
        toast.open("false", "Sorry, Failed to submit");
      });
  };

  const mentorDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("mentor-category-error").classList.add("hidden");
    document.getElementById("mentor-category-limit").classList.add("hidden");
    if (mentorIndustry.length < 1) {
      document
        .getElementById("mentor-category-error")
        .classList.remove("hidden");
      return;
    }
    if (mentorIndustry.length > 3) {
      document
        .getElementById("mentor-category-limit")
        .classList.remove("hidden");
      return;
    }
    let logo = document.getElementById("mentorLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("work_experience", data.work_experience);
    formData.append("mentor_industry", mentorIndustry.join(","));
    formData.append("previous_company", data.previous_company);
    formData.append("short_description", data.short_description);
    formData.append("date", getCurrentDate());
    axios
      .post(mentorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/mentor-dashboard");
      })
      .catch(() => toast.open("false", "Sorry, Failed to submit"));
  };

  const investorDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("invested-amount-error").classList.add("hidden");
    document.getElementById("future-amount-error").classList.add("hidden");
    document.getElementById("investor-category-error").classList.add("hidden");
    document.getElementById("industry-limit").classList.add("hidden");

    if (investment.investedAmount === "") {
      document
        .getElementById("invested-amount-error")
        .classList.remove("hidden");
      return;
    }
    if (investment.futureInvestmentAmount === "") {
      document.getElementById("future-amount-error").classList.remove("hidden");
      return;
    }
    if (investorIndustry.length < 1) {
      document
        .getElementById("investor-category-error")
        .classList.remove("hidden");
      return;
    }
    if (investorIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("hidden");
      return;
    }
    let logo = document.getElementById("investorLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("companies_invested_count", data.numberOfInvestments);
    formData.append("invested_amount", investment.investedAmount);
    formData.append("investor_industries", investorIndustry.join(","));
    formData.append(
      "future_investment_amount",
      investment.futureInvestmentAmount
    );
    formData.append("linked_in", data.investorLinkedin);
    formData.append("date", getCurrentDate());
    axios
      .post(investorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/investor-dashboard");
      })
      .catch(() => toast.open("false", "Sorry, Failed to submit"));
  };

  const vendorDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("missing-expertise").classList.add("hidden");
    document.getElementById("expertise-limit").classList.add("hidden");
    if (expertise.length < 1) {
      document.getElementById("missing-expertise").classList.remove("hidden");
      return;
    }
    if (expertise.length > 3) {
      document.getElementById("expertise-limit").classList.remove("hidden");
      return;
    }

    let logo = document.getElementById("vendorLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("company_name", data.vendorCompany);
    formData.append("description", data.vendorBio);
    formData.append("expert_in", expertise.join(","));
    formData.append("date", getCurrentDate());
    axios
      .post(vendorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/vendor-profile");
      })
      .catch(() => toast.open("false", "Sorry, Failed to submit"));
  };

  useEffect(() => {
    CheckRole(getRole);
    if (getRole === "V&iR8") {
      displayIndustries(
        (data) => setIndustries(data.industries),
        "service provider"
      );
    } else {
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
  }, [CheckRole, getRole, displayIndustries]);

  const style = {
    filter: "blur(1px)",
    pointerEvents: "none",
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
        style={style}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} style={style} />
        <section className="p-5 flex items-center justify-center">
          {role === 1 ? (
            <div
              className="w-full bg-white shadow card pb-3"
              id="startup-details-form"
            >
              <h4 className="text-center my-4">
                Please complete your profile to continue
              </h4>
              <small className="text-red-500 mb-2 hidden" id="fillDetailsText">
                Please fill in all the mandatory(*) details.
              </small>
              {error && (
                <p className="text-red-500 text-sm my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                onSubmit={handleSubmit(businessDetailsApiCall)}
                className="grid grid-cols-1 md:grid-cols-2 gap-3"
              >
                <div className="mb-1">
                  <label htmlFor="companyName" className="small mb-0">
                    Company Name*
                  </label>
                  <div className="flex" style={{ border: "1px solid #ced4da" }}>
                    <input
                      type="text"
                      className="border-0 w-full ps-3"
                      id="companyName"
                      name="companyName"
                      placeholder="Enter your Company Name"
                      {...register("companyName", {
                        required: "Company Name is required",
                      })}
                    />
                    <span className="input-icons icon-span">
                      <BuildingIcon />
                    </span>
                  </div>
                  {errors.companyName && (
                    <div>
                      <FormError message={errors.companyName.message} />
                    </div>
                  )}
                </div>
                <div className="mb-1 flex-col">
                  <p className="text-sm">
                    Industry* <span>({startupIndustry.length + "/3"})</span>
                    <span
                      id="category-limit"
                      className="hidden text-red-500 text-sm ms-2"
                    >
                      Limit exceeded
                    </span>
                  </p>
                  <Dropdown
                    options={industries?.map((industry) => ({
                      label: industry.industry_name
                        .replace(new RegExp("_", "g"), " ")
                        .toUpperCase(),
                      value: industry.industry_name,
                    }))}
                    onChange={(selectedOptions) =>
                      setStartupIndustry(
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    isMulti
                  />
                  <small
                    className="text-red-500 mb-2 hidden"
                    id="category-error"
                  >
                    This field is mandatory.
                  </small>
                </div>
                <div className="mb-1">
                  <label htmlFor="ceoDetails" className="mb-0 text-sm">
                    Website Link
                  </label>
                  <div className="flex" style={{ border: "1px solid #ced4da" }}>
                    <input
                      type="text"
                      className="border-0 w-full ps-3"
                      id="website"
                      name="website"
                      placeholder="Websites URL"
                      {...register("website", {
                        validate: (fieldValue) => {
                          return fieldValue.length > 0
                            ? urlRegex.test(fieldValue) ||
                                "Please enter a valid url"
                            : null;
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <RedirectIcon />
                    </span>
                  </div>
                  {errors.website && (
                    <div>
                      <FormError message={errors.website.message} />
                    </div>
                  )}
                  <span
                    id="duplicate-error"
                    className="hidden text-red-500 text-sm"
                  >
                    website already exists.
                  </span>
                </div>
                <div className="mb-1">
                  <label htmlFor="requireInvestment" className="mb-0 text-sm">
                    How much investment are you looking for(₹)*
                  </label>
                  <div className="flex" style={{ border: "1px solid #ced4da" }}>
                    <input
                      type="text"
                      className="border-0 w-full ps-3"
                      id="requireInvestment"
                      name="requireInvestment"
                      placeholder="ex: 500000"
                      {...register("requireInvestment", {
                        required: "Required investment is required",
                        pattern: {
                          value: numberRegex,
                          message: "Amount must only contain digits",
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <RupeeIcon />
                    </span>
                  </div>
                  {errors.requireInvestment && (
                    <div>
                      <FormError message={errors.requireInvestment.message} />
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <label htmlFor="startupLinkedin" className="mb-0 text-sm">
                    Linkedin profile{" "}
                    <small>
                      ( LinkedIn profiles boost authenticity and
                      credibility—highly recommended. )
                    </small>
                  </label>
                  <div className="flex" style={{ border: "1px solid #ced4da" }}>
                    <input
                      type="text"
                      className="border-0 w-full ps-3"
                      id="startupLinkedin"
                      name="startupLinkedin"
                      placeholder="LinkedIn URL"
                      {...register("startupLinkedin", {
                        validate: (fieldValue) => {
                          return fieldValue.length > 0
                            ? urlRegex.test(fieldValue) ||
                                "Please enter a valid url"
                            : null;
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <LinkedInIcon />
                    </span>
                  </div>
                  {errors.startupLinkedin && (
                    <div>
                      <FormError message={errors.startupLinkedin.message} />
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <label htmlFor="startupLogo" className="text-sm mb-0">
                    Company Logo
                  </label>
                  <div
                    className="flex items-center rounded-md overflow-hidden"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="border-0 w-full ps-3"
                      id="startupLogo"
                      name="startupLogo"
                      placeholder="Upload Company Logo"
                    />
                    <span className="input-icons icon-span">
                      <UploadIcon />
                    </span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Button
                    type="submit"
                    className={`py-2.5 w-full ${
                      isClicked && "disabled cursor-not-allowed"
                    }`}
                    bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
                    disabled={isClicked}
                  >
                    Proceed
                  </Button>
                </div>
              </form>
            </div>
          ) : role === 2 ? (
            <div
              className="w-full bg-white shadow card"
              id="mentor-details-form"
            >
              <h4 className="text-center my-4">
                Please complete your profile to continue
              </h4>
              <small className="text-red-500 mb-2 hidden" id="fillDetailsText">
                Please fill in all the mandatory(*) details.
              </small>
              {error && (
                <p className="text-red-500 text-sm my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                onSubmit={mentorSubmit(mentorDetailsApiCall)}
                className="grid grid-cols-1 md:grid-cols-2 gap-3"
                id="mentor-details"
              >
                <div>
                  <div className="mb-1">
                    <label htmlFor="workExperience" className="mb-0 text-sm">
                      Work experience(in years*)
                    </label>
                    <div
                      className="flex"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <input
                        type="text"
                        className="border-0 w-full ps-3"
                        id="work_experience"
                        name="work_experience"
                        placeholder="ex: 5"
                        autoFocus
                        {...mentorRegister("work_experience", {
                          required: "Work experience is required",
                          pattern: {
                            value: numberWithDecimalRegex,
                            message: "Cannot start or end with decimal point",
                          },
                          maxLength: {
                            value: 4,
                            message: "Cannot exceed 4 characters",
                          },
                        })}
                      />
                      <span className="input-icons icon-span">
                        <BriefCaseIcon />
                      </span>
                    </div>
                    {mentorError.work_experience && (
                      <div>
                        <FormError
                          message={mentorError.work_experience.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="mb-1">
                    <label htmlFor="mentorCompany" className="mb-0 text-sm">
                      Current or Previous company Name*
                    </label>
                    <div
                      className="flex"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <input
                        type="text"
                        className="border-0 w-full ps-3"
                        id="previous_company"
                        name="previous_company"
                        placeholder="Enter company name"
                        {...mentorRegister("previous_company", {
                          required: "Company name is required",
                        })}
                      />
                      <span className="input-icons icon-span">
                        <BuildingIcon />
                      </span>
                    </div>
                    {mentorError.previous_company && (
                      <div>
                        <FormError
                          message={mentorError.previous_company.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="mb-1 flex-col">
                    <p className="text-sm">
                      Industry* <span>({mentorIndustry.length + "/3"})</span>
                      <span
                        id="mentor-category-limit"
                        className="hidden text-red-500 text-sm ms-2"
                      >
                        Limit exceeded
                      </span>
                    </p>
                    <Dropdown
                      options={industries?.map((industry) => ({
                        label: industry.industry_name
                          .replace(new RegExp("_", "g"), " ")
                          .toUpperCase(),
                        value: industry.industry_name,
                      }))}
                      onChange={(selectedOptions) =>
                        setMentorIndustry(
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      isMulti
                    />
                    <small
                      className="text-red-500 mb-2 hidden"
                      id="mentor-category-error"
                    >
                      This field is mandatory.
                    </small>
                  </div>
                </div>
                <div className="mb-1">
                  <label htmlFor="mentorLogo" className="text-sm mb-0">
                    Profile Image
                  </label>
                  <div
                    className="flex items-center rounded-md overflow-hidden"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="border-0 w-full ps-3"
                      id="mentorLogo"
                      name="mentorLogo"
                      placeholder="Upload Profile Image"
                    />
                    <span className="input-icons icon-span">
                      <UploadIcon />
                    </span>
                  </div>
                </div>
                <div>
                  <div className="mb-4">
                    <label htmlFor="mentorBio" className="mb-0 text-sm">
                      Short bio about yourself*
                    </label>
                    <div className="totalInvestmentInput w-full border rounded-sm m-auto">
                      <textarea
                        name="short_description"
                        id="short_description"
                        cols="30"
                        rows="5"
                        className="border-0 w-full ps-3 pt-2"
                        placeholder="Write here..."
                        {...mentorRegister("short_description", {
                          required: "Short description is required",
                          minLength: {
                            value: 20,
                            message:
                              "Short description must be atleast 20 characters",
                          },
                        })}
                      ></textarea>
                    </div>
                    {mentorError.short_description && (
                      <div>
                        <FormError
                          message={mentorError.short_description.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="md:col-start-2">
                    <Button
                      type="submit"
                      className={`py-1.5 w-full ${
                        isClicked && "disabled cursor-not-allowed"
                      }`}
                      bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
                      disabled={isClicked}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          ) : role === 3 ? (
            <div
              className="w-full bg-white shadow card"
              id="investor-details-form"
            >
              <h4 className="text-center my-4">
                Please complete your profile to continue
              </h4>
              {error && (
                <p className="text-red-500 text-sm my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                onSubmit={investorSubmit(investorDetailsApiCall)}
                className="grid grid-cols-1 md:grid-cols-2 gap-3"
                id="investor-details"
              >
                <div>
                  <label htmlFor="numberOfInvestments" className="mb-0 text-sm">
                    Number of companies in your portfolio*
                  </label>
                  <div className="flex" style={{ border: "1px solid #ced4da" }}>
                    <input
                      type="number"
                      className="border-0 w-full ps-3"
                      id="numberOfInvestments"
                      name="numberOfInvestments"
                      placeholder="ex: 3"
                      {...investorRegister("numberOfInvestments", {
                        required: "Portfolio count is required",
                        pattern: {
                          value: numberRegex,
                          message: "Count must only contain digits",
                        },
                      })}
                    />
                    <span className="input-icons icon-span w-[39px]">
                      <MoneyUpTrendIcon />
                    </span>
                  </div>
                  {investorError.numberOfInvestments && (
                    <div>
                      <FormError
                        message={investorError.numberOfInvestments.message}
                      />
                    </div>
                  )}
                </div>
                <div className="flex-col">
                  <p className="text-sm">
                    Industry* <span>({investorIndustry.length + "/3"})</span>
                    <span
                      id="industry-limit"
                      className="hidden text-red-500 text-sm ms-2"
                    >
                      Limit exceeded
                    </span>
                  </p>
                  <Dropdown
                    options={industries?.map((industry) => ({
                      label: industry.industry_name
                        .replace(new RegExp("_", "g"), " ")
                        .toUpperCase(),
                      value: industry.industry_name,
                    }))}
                    onChange={(selectedOptions) =>
                      setInvestorIndustry(
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    isMulti
                  />
                  <small
                    className="text-red-500 mb-2 hidden"
                    id="investor-category-error"
                  >
                    This field is mandatory.
                  </small>
                </div>
                <div>
                  <label htmlFor="investedAmount" className="mb-0 text-sm">
                    Total Investment done till now*
                  </label>
                  <Dropdown
                    name="investedAmount"
                    id="investedAmount"
                    options={[
                      { label: "Not Applicable", value: "NA" },
                      { label: "Upto 10Lakh", value: "Upto 10Lakh" },
                      { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
                      { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
                      { label: "50Lakh-1Cr", value: "50Lakh-1Cr" },
                      { label: "1Cr+", value: "More than 1Cr" },
                    ]}
                    onChange={(selectedOption) =>
                      setInvestment((prev) => ({
                        ...prev,
                        investedAmount: selectedOption.value,
                      }))
                    }
                  />
                  <div className="hidden" id="invested-amount-error">
                    <FormError message="Amount is required" />
                  </div>
                </div>
                <div>
                  <label htmlFor="futureInvestment" className="mb-0 text-sm">
                    How much are you looking to invest*
                  </label>
                  <Dropdown
                    name="futureInvestmentAmount"
                    id="futureInvestmentAmount"
                    options={[
                      { label: "Upto 10Lakh", value: "Upto 10Lakh" },
                      { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
                      { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
                      { label: "50Lakh-1Cr", value: "50Lakh-1Cr" },
                      { label: "1Cr+", value: "More than 1Cr" },
                    ]}
                    onChange={(selectedOption) =>
                      setInvestment((prev) => ({
                        ...prev,
                        futureInvestmentAmount: selectedOption.value,
                      }))
                    }
                  />
                  <div className="hidden" id="future-amount-error">
                    <FormError message="Amount is required" />
                  </div>
                </div>
                <div className="mb-1">
                  <label htmlFor="investorLinkedin" className="mb-0 text-sm">
                    Linkedin profile
                  </label>
                  <div className="flex" style={{ border: "1px solid #ced4da" }}>
                    <input
                      type="text"
                      className="border-0 w-full ps-3"
                      id="investorLinkedin"
                      name="investorLinkedin"
                      placeholder="LinkedIn URL"
                      {...investorRegister("investorLinkedin", {
                        validate: (fieldValue) => {
                          return fieldValue.length > 0
                            ? urlRegex.test(fieldValue) ||
                                "Please enter a valid url"
                            : null;
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <LinkedInIcon />
                    </span>
                  </div>
                  {investorError.investorLinkedin && (
                    <div>
                      <FormError
                        message={investorError.investorLinkedin.message}
                      />
                    </div>
                  )}
                </div>
                <div className="mb-1">
                  <label htmlFor="investorLogo" className="text-sm mb-0">
                    Profile Image
                  </label>
                  <div
                    className="flex items-center rounded-md overflow-hidden"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="border-0 w-full ps-3"
                      id="investorLogo"
                      name="investorLogo"
                      placeholder="Upload Profile Image"
                    />
                    <span className="input-icons icon-span">
                      <UploadIcon />
                    </span>
                  </div>
                </div>
                <div className="w-full md:col-start-2 self-center">
                  <Button
                    type="submit"
                    className={`py-2 me-2 w-full ${
                      isClicked && "disabled cursor-not-allowed"
                    }`}
                    bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
                    disabled={isClicked}
                  >
                    Proceed
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div
              className="w-full bg-white shadow card"
              id="vendor-details-form"
            >
              <h4 className="text-center my-4">
                Please complete your profile to continue
              </h4>
              {error && (
                <p className="text-red-500 text-sm my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                className="grid grid-cols-1 md:grid-cols-2 gap-3"
                id="vendor-details"
                onSubmit={vendorSubmit(vendorDetailsApiCall)}
              >
                <div>
                  <div className="mb-1">
                    <label htmlFor="vendorCompany" className="mb-0 text-sm">
                      Company Name
                    </label>
                    <div
                      className="flex items-center rounded-md overflow-hidden"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <input
                        type="text"
                        className="border-0 w-full ps-3"
                        id="vendorCompany"
                        name="vendorCompany"
                        placeholder="Enter company name"
                        {...vendorRegister("vendorCompany", {
                          required: "company name is required",
                        })}
                      />
                      <span className="input-icons icon-span">
                        <BuildingIcon />
                      </span>
                    </div>
                    {vendorError.vendorCompany && (
                      <div>
                        <FormError
                          message={vendorError.vendorCompany.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex-col mb-1">
                    <p className="text-sm">
                      Company expertise
                      <span>({expertise.length + "/3"})</span>
                      <span
                        id="expertise-limit"
                        className="hidden text-red-500 text-sm ms-2"
                      >
                        Limit exceeded
                      </span>
                    </p>
                    <Dropdown
                      options={industries?.map((industry) => ({
                        label: industry.industry_name
                          .replace(new RegExp("_", "g"), " ")
                          .toUpperCase(),
                        value: industry.industry_name,
                      }))}
                      onChange={(selectedOptions) =>
                        setExpertise(
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      isMulti
                    />
                  </div>
                  <p
                    id="missing-expertise"
                    className="hidden mt-1 text-red-500 text-sm"
                  >
                    This field is mandatory
                  </p>
                  <div className="mb-1">
                    <label htmlFor="vendorLogo" className="text-sm mb-0">
                      Company Logo
                    </label>
                    <div
                      className="flex items-center rounded-md overflow-hidden"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="border-0 w-full ps-3"
                        id="vendorLogo"
                        name="vendorLogo"
                        placeholder="Upload Company Logo"
                      />
                      <span className="input-icons icon-span">
                        <UploadIcon />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mb-2">
                    <label htmlFor="vendorBio" className="mb-0 text-sm">
                      Short description about your company
                    </label>
                    <div
                      className="flex items-center rounded-md overflow-hidden"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <textarea
                        name="vendorBio"
                        id="vendorBio"
                        cols="30"
                        rows="7"
                        placeholder="Write here..."
                        className="border-0 w-full ps-3 pt-1"
                        {...vendorRegister("vendorBio", {
                          required: "Bio is required",
                          minLength: {
                            value: 15,
                            message: "Bio must be at least 15 characters",
                          },
                        })}
                      ></textarea>
                    </div>
                    {vendorError.vendorBio && (
                      <div>
                        <FormError message={vendorError.vendorBio.message} />
                      </div>
                    )}
                  </div>
                  <div className="w-full md:col-start-2">
                    <Button
                      type="submit"
                      className={`py-1.5 me-2 w-full ${
                        isClicked && "disabled cursor-not-allowed"
                      }`}
                      bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
                      disabled={isClicked}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default ProfileCompletion;
