import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { randomId } from "../../../utils/randomId";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import "./pricing.css";
import { DeleteIcon, PenIcon } from "../../../icons/icons";
import { EditPricingModal } from "../../../components/ui/modals/platform-fees/editPricingModal";
import PHcoin from "../../../images/PH-coin.svg";
import { EditInvestmentBracketModal } from "../../../components/ui/modals/platform-fees/editInvestmentBracket";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";
import { Button } from "../../../components/ui/Button";
import { AddInvestmentBracket } from "../../../components/ui/modals/platform-fees/addInvestmentBracketModa";

const PlatformFees = () => {
  const getPricingApi = `${process.env.REACT_APP_API_URL}display-pricing`;
  const displayInvestmentBracketApi = `${process.env.REACT_APP_API_URL}display-platform-fee`;
  const editPricingApi = `${process.env.REACT_APP_API_URL}edit-pricing`;
  const editInvestmentBracketApi = `${process.env.REACT_APP_API_URL}edit-platform-fee`;
  const deleteInvestmentBracketApi = `${process.env.REACT_APP_API_URL}delete-platform-fee`;
  const createInvestmentBracketApi = `${process.env.REACT_APP_API_URL}add-platform-fee`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [feesData, setFeesData] = useState({
    connection_charges: {
      mentor_connection: 0,
      investor_connection: 0,
      id: "",
    },
    investment_charges: [],
  });
  const [priceModal, setPriceModal] = useState({
    open: false,
    currPrice: 0,
    field: "",
  });
  const [investmentBracketModal, setInvestmentBracketModal] = useState({
    open: false,
    data: null,
  });
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    id: null,
    message:
      "Are you sure you want to permanently delete this investment bracket?",
  });
  const [addBracketModal, setAddBracketModal] = useState(false);

  const getPricing = useCallback(() => {
    fetchApi(
      {
        url: getPricingApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setFeesData((prev) => ({
          ...prev,
          connection_charges: {
            mentor_connection: data.startup_to_mentor_connection,
            investor_connection: data.startup_to_investor_connection,
            id: data.id,
          },
        }));
      }
    );
  }, [getPricingApi, fetchApi, token]);

  const displayInvestmentBracket = useCallback(() => {
    fetchApi(
      {
        url: displayInvestmentBracketApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setFeesData((prev) => ({ ...prev, investment_charges: data.details }));
      }
    );
  }, [displayInvestmentBracketApi, fetchApi, token]);

  const editPricing = useCallback(
    (price) => {
      fetchApi(
        {
          url: editPricingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: feesData.connection_charges.id,
            [priceModal?.field]: price,
          },
        },
        () => {
          getPricing();
        }
      );
    },
    [
      editPricingApi,
      fetchApi,
      token,
      getPricing,
      feesData.connection_charges.id,
      priceModal.field,
    ]
  );

  const editInvestmentBracket = useCallback(
    (id, data) => {
      fetchApi(
        {
          url: editInvestmentBracketApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            actual_fee: parseFloat(data.actualFee),
            investment_min: data.minInvestment,
            investment_max: data.maxInvestment,
            offer: data.offer !== "0" ? parseFloat(data.offerPercentage) : 0,
          },
        },
        () => {
          displayInvestmentBracket();
        }
      );
    },
    [editInvestmentBracketApi, fetchApi, token, displayInvestmentBracket]
  );

  const deleteInvestmentBracket = useCallback(() => {
    fetchApi(
      {
        url: deleteInvestmentBracketApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          id: confirmationModal.id,
        },
      },
      () => {
        displayInvestmentBracket();
      }
    );
  }, [
    deleteInvestmentBracketApi,
    fetchApi,
    token,
    displayInvestmentBracket,
    confirmationModal.id,
  ]);

  const createInvestmentBracket = useCallback(
    (data) => {
      fetchApi(
        {
          url: createInvestmentBracketApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            investment_min: data.minInvestment,
            investment_max: data.maxInvestment,
            actual_fee: parseFloat(data.actualFee),
            offer: data.offer !== "0" ? parseFloat(data.offerPercentage) : 0,
          },
        },
        () => {
          displayInvestmentBracket();
        }
      );
    },
    [createInvestmentBracketApi, fetchApi, token, displayInvestmentBracket]
  );

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      getPricing();
      displayInvestmentBracket();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getPricing, displayInvestmentBracket, navigate, userRole]);

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!error && !isLoading && (
          <div className="grid md:grid-cols-3 p-4 gap-3">
            <div className="md:col-span-3">
              <h3 className="font-semibold text-xl">Connection Pricing</h3>
              <div className="flex flex-wrap items-center gap-5 mt-4">
                <div className="pricing-card shadow-md">
                  <h5 className="text-lg font-medium w-max">
                    Investor Connection
                  </h5>
                  <p className="flex items-center gap-x-1 text-base">
                    {feesData.connection_charges.investor_connection}{" "}
                    <img src={PHcoin} alt="PH coin" />
                  </p>
                  <PenIcon
                    className="pricing-card-icon cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                    onClick={() =>
                      setPriceModal({
                        open: true,
                        currPrice:
                          feesData.connection_charges.investor_connection,
                        field: "startup_to_investor_connection",
                      })
                    }
                  />
                </div>
                <div className="pricing-card shadow-md">
                  <h5 className="text-lg font-medium w-max">
                    Mentor Connection
                  </h5>
                  <p className="flex items-center gap-x-1 text-base">
                    {feesData.connection_charges.mentor_connection}{" "}
                    <img src={PHcoin} alt="PH coin" />
                  </p>
                  <PenIcon
                    className="pricing-card-icon cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                    onClick={() =>
                      setPriceModal({
                        open: true,
                        currPrice:
                          feesData.connection_charges.mentor_connection,
                        field: "startup_to_mentor_connection",
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="md:col-span-3 overflow-auto w-full md:overflow-none">
              <div className="flex flex-col items-start gap-3 md:gap-0 md:flex-row md:items-center md:justify-between">
                <h3 className="font-semibold text-xl order-2 md:order-1">
                  Platform Fees
                </h3>
                <Button
                  className="px-3 py-1 order-1 md:order-2"
                  onClick={() => setAddBracketModal(true)}
                >
                  Create Bracket
                </Button>
              </div>
              <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
                <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
                  <tr>
                    <th className="p-3 font-medium text-sm">
                      Investment Bracket (
                      <span className="font-semibold">&#8377;</span>)
                    </th>
                    <th className="p-3 font-medium text-sm">
                      Orignal Fees (<span className="font-bold">%</span>)
                    </th>
                    <th className="p-3 font-medium text-sm">Discount</th>
                    <th className="p-3 font-medium text-sm">
                      Discount Offer (<span className="font-bold">%</span>)
                    </th>
                    <th className="p-3 font-medium text-sm">
                      Discounted Fees (<span className="font-bold">%</span>)
                    </th>
                    <th className="p-3 font-medium text-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {feesData.investment_charges.length > 0 ? (
                    feesData.investment_charges.map((bracket) => (
                      <tr
                        key={bracket._id}
                        className="bg-white border-b border-slate-300 align-middle h-auto"
                      >
                        <td className="p-3 text-xs md:text-sm">
                          <span className="font-medium mr-1">&#8377;</span>
                          {bracket.investment_min} -{" "}
                          <span className="font-medium mr-1">&#8377;</span>
                          {bracket.investment_max}
                        </td>
                        <td className="p-3 text-xs md:text-sm">
                          {bracket.actual_fee}
                          <span className="font-bold">%</span>
                        </td>
                        <td className="p-3 text-xs">
                          {bracket.offer !== 0 ? (
                            <span className="p-1 green-badge">Yes</span>
                          ) : (
                            <span className="p-1 red-badge">No</span>
                          )}
                        </td>
                        <td className="p-3 text-xs md:text-sm">
                          {bracket.offer !== 0 ? (
                            <>
                              {bracket.offer}
                              <span className="font-bold">%</span>
                            </>
                          ) : (
                            <span className="red-badge p-1 text-xs">NA</span>
                          )}
                        </td>
                        <td className="p-3 text-xs md:text-sm">
                          {bracket.final_fee}
                          <span className="font-bold">%</span>
                        </td>
                        <td className="p-3">
                          <div className="flex items-center justify-evenly gap-2 h-full">
                            <span
                              title="Edit Bracket"
                              onClick={() =>
                                setInvestmentBracketModal({
                                  open: true,
                                  data: bracket,
                                })
                              }
                            >
                              <PenIcon className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800" />
                            </span>
                            <span
                              title="Delete Bracket"
                              onClick={() =>
                                setConfirmationModal((prev) => ({
                                  ...prev,
                                  open: true,
                                  id: bracket._id,
                                }))
                              }
                            >
                              <DeleteIcon className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800" />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white text-center">
                      <td colSpan={5} className="p-3 text-xs md:text-sm">
                        No Investment Bracket Added
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <EditPricingModal
        key={randomId()}
        open={priceModal.open}
        onClose={() => setPriceModal((prev) => ({ ...prev, open: false }))}
        data={priceModal}
        callBackFn={editPricing}
      />
      <EditInvestmentBracketModal
        key={randomId()}
        open={investmentBracketModal.open}
        onClose={() =>
          setInvestmentBracketModal((prev) => ({ ...prev, open: false }))
        }
        bracketData={investmentBracketModal.data}
        callBackFn={editInvestmentBracket}
      />
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={() =>
          setConfirmationModal((prev) => ({ ...prev, open: false }))
        }
        message={confirmationModal.message}
        callBackFn={deleteInvestmentBracket}
      />
      <AddInvestmentBracket
        open={addBracketModal}
        onClose={() => setAddBracketModal(false)}
        callBackFn={createInvestmentBracket}
      />
    </div>
  );
};

export default PlatformFees;
