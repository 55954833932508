import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const displayCreditsApi = `${process.env.REACT_APP_API_URL}display-wallet-credits`;
const displayFreezePointsApi = `${process.env.REACT_APP_API_URL}display-freeze-points`;

const initialState = {
  credits: null,
  freezePoints: null,
  creditsStatus: "idle",
  creditsError: null,
};

export const fetchCredits = createAsyncThunk(
  "credits/fetch",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(displayCreditsApi, {
        headers: {
          "x-token": token,
        },
      });
      const data = response.data;

      return {
        credits: data.credits,
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "An error occurred while fetching credits.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchFreezePoints = createAsyncThunk(
  "freezePoints/fetch",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(displayFreezePointsApi, {
        headers: {
          "x-token": token,
        },
      });
      const data = response.data;
      return {
        freezePoints: data.freeze_points,
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "An error occurred while fetching credits.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

const fetchCreditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    clearCredits: (state) => {
      Object.assign(state, initialState);
    },
    updateCredits: (state, action) => {
      state.credits =
        action.payload.type === "remove"
          ? state.credits - action.payload.amount
          : state.credits + action.payload.amount;

      state.credits = state.credits >= 0 ? state.credits : 0;
    },
    updateFreezePoints: (state, action) => {
      state.freezePoints = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCredits.pending, (state) => {
        state.creditsStatus = "loading";
      })
      .addCase(fetchCredits.fulfilled, (state, action) => {
        state.creditsStatus = "succeeded";
        state.credits = action.payload.credits;
      })
      .addCase(fetchCredits.rejected, (state, action) => {
        state.creditsStatus = "failed";
        state.creditsError = action.payload;
      })
      .addCase(fetchFreezePoints.pending, (state) => {
        state.creditsStatus = "loading";
      })
      .addCase(fetchFreezePoints.fulfilled, (state, action) => {
        state.creditsStatus = "succeeded";
        state.freezePoints = action.payload.freezePoints;
      })
      .addCase(fetchFreezePoints.rejected, (state, action) => {
        state.creditsStatus = "failed";
        state.creditsError = action.payload;
      });
  },
});

export const { clearCredits, updateCredits, updateFreezePoints } =
  fetchCreditsSlice.actions;
export default fetchCreditsSlice.reducer;
