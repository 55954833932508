import { Button } from "../Button";
import { CheckIcon, DeleteIcon, PenIcon } from "../../../icons/icons";
import useCheckout from "../../../hooks/useCheckout";

const SubscriptionCard = ({
  data,
  role = "",
  updateModal,
  changePlanStatus,
  handleDeleteModal,
  callbackUrl,
}) => {
  const { createCheckoutSession } = useCheckout();

  return (
    <div
      className={`relative card border rounded-md shadow-none ${
        role === "admin" ? "group hover:bg-slate-200" : ""
      }`}
    >
      {role === "admin" && (
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={data.plan_status === "0" ? false : true}
            onChange={() =>
              changePlanStatus(data._id, data.plan_status === "0" ? "1" : "0")
            }
          />
          <div className="relative w-9 h-5 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
      )}

      {role === "admin" && (
        <>
          <span
            className="hidden group-hover:inline absolute right-10 top-2 cursor-pointer"
            onClick={() => updateModal(data)}
          >
            <PenIcon fill="#12d512" />
          </span>
          <span
            className="hidden group-hover:inline absolute right-2 top-2 cursor-pointer"
            onClick={() => handleDeleteModal(data._id)}
          >
            <DeleteIcon />
          </span>
        </>
      )}
      <h4 className="font-medium text-center capitalize mb-1 pt-1">
        {data.plan_type}
      </h4>
      <div className="pb-3 text-center">
        {data.discount_status === "0" ? (
          <div className="flex justify-center">
            <sup className="text-base text-medium mt-3 mb-0 me-1">₹</sup>
            <h4 className="font-medium text-4xl mb-0 text-indigo-500">
              {data.final_amount}
            </h4>
          </div>
        ) : (
          <>
            <div className="flex justify-center">
              <sup className="text-base text-medium mt-3 mb-0 me-1">₹</sup>
              <h4 className="font-medium text-4xl mb-0 text-indigo-500">
                {data.final_amount}
              </h4>
              <sub className="flex justify-center items-center opacity-75 ml-1 mt-3 text-sm">
                /
                <div className="flex justify-center items-center ml-1">
                  <span className="text-medium me-1">₹</span>
                  <h4 className="font-medium mb-0 text-indigo-500 line-through">
                    {data.amount}
                  </h4>
                </div>
              </sub>
            </div>{" "}
          </>
        )}
        <ul className="fa-ul ms-2 my-4 pt-3 text-left">
          {data.plan_features.map((feature) => (
            <li className="flex items-start mb-2 text-sm" key={feature.key}>
              <span className="fa-li pr-2">
                <CheckIcon width="14px" />
              </span>
              {feature.value}
            </li>
          ))}
        </ul>
        {role.length < 1 &&
          (data.final_amount !== 0 ? (
            <Button
              onClick={() =>
                createCheckoutSession(
                  {
                    type: "subscription",
                    plan_type: data.plan_type,
                    id: data._id,
                  },
                  callbackUrl
                )
              }
              className="absolute left-0 bottom-0 px-2 py-1 w-full mb-0 rounded-t-none"
            >
              Select
            </Button>
          ) : (
            <Button
              className="absolute left-0 bottom-0 px-2 py-1 w-full mb-0 rounded-t-none disabled"
              disabled
            >
              Default Plan
            </Button>
          ))}
      </div>
    </div>
  );
};

export default SubscriptionCard;
