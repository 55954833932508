import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchCredits } from "../../store/slices/FetchCreditsSlice";
import {
  startupDashboard,
  updateClaimStatus,
  updateProfileCompletion,
} from "../../store/slices/dashboard/StartupDashboardSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import { BarGraph } from "../../components/ui/graphs-charts/barGraph";
import LongCard from "../../components/ui/cards/longCard";
import SingleConnection from "./singleConnection";
import WelcomeCard from "../../components/ui/cards/welcomeCard";
import AmountCard from "../../components/ui/cards/amountCard";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import RecentTransactions from "./recentTransaction";
import { Button } from "../../components/ui/Button";
import { ClaimModal } from "../../components/ui/modals/utility-modals/claimModal";
import { randomId } from "../../utils/randomId";
import "./dashboard.css";
import usersIcon from "../../images/users-icon.svg";
import users2Icon from "../../images/users2-icon.svg";
import PHcoin from "../../images/PH-coin.svg";
import {
  AngleRightIcon,
  LongRightArrow,
  RocketIcon,
  ShoppingCartIcon,
  UserGraduateIcon,
  UserTieIcon,
} from "../../icons/icons";

const StartupDashBoard = () => {
  const claimTokensApi = `${process.env.REACT_APP_API_URL}claim-tokens`;

  const { isLoading, error, fetchApi } = useFetch();
  const navigate = useNavigate();
  const { token, userRole } = useLocalStorage();
  const toast = useToastContext();
  const reduxDispatch = useDispatch();
  const { credits } = useSelector((state) => state.credits);
  const {
    name,
    logo,
    description,
    claim_status,
    profile_completion,
    connected_mentors,
    connected_investors,
    used_credits,
    investors,
    mentors,
    startups,
    vendors,
    userTransactions,
    userConnections,
    barGraphData,
    dashboardStatus,
    dashboardError,
  } = useSelector((state) => state.dashboard);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [claimFields, setClaimFields] = useState({
    logo: null,
    description: null,
  });

  const claimTokens = useCallback(() => {
    fetchApi(
      {
        url: claimTokensApi,
        method: "POST",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        if (data.message !== "Claim Successfull") {
          toast.open("warning", data.message);
          setClaimFields({
            logo: data.logo,
            description: data.description,
          });
          setOpenClaimModal(true);
          return;
        }
        setOpenClaimModal(false);
        toast.open("true", data.message);
        reduxDispatch(fetchCredits(token));
        reduxDispatch(updateClaimStatus("1"));
        reduxDispatch(updateProfileCompletion(data.profile_completion));
      }
    );
  }, [token, claimTokensApi, fetchApi, toast, reduxDispatch]);

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/error-403");
      return;
    }
    const dashboardTimeout = setTimeout(() => {
      if (dashboardStatus === "idle") {
        reduxDispatch(startupDashboard(token));
      }
      if (dashboardStatus === "failed") {
        toast.open("false", dashboardError);
      }
    });
    return () => {
      clearTimeout(dashboardTimeout);
    };
  }, [navigate, userRole, reduxDispatch, token, toast]);

  const ViewProfile = (connectionId, name, role) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name.split(" ").join("-")}/${connectionId}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name.split(" ").join("-")}/${connectionId}`);
    } else if (role === "Sw%aa") {
      navigate(`/view-investor/${name.split(" ").join("-")}/${connectionId}`);
    } else {
      navigate(`/view-vendor/${name.split(" ").join("-")}/${connectionId}`);
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          {(isLoading || dashboardStatus === "loading") && <Loader />}
          {(error || dashboardStatus === "failed") && <Error />}
          {!isLoading && !error && dashboardStatus === "succeeded" && (
            <div className="grid grid-cols-8 gap-3 widget-grid">
              <div className="col-span-8">
                {claim_status === "0" && (
                  <div className="claim-card shadow-md">
                    <p>
                      {!logo || !description
                        ? "Add logo and description from profile to claim free 500 tokens"
                        : "Claim your free 500 tokens."}
                    </p>
                    <Button
                      className="claim-btn"
                      textColor="text-black"
                      onClick={() => claimTokens()}
                    >
                      Claim
                    </Button>
                  </div>
                )}
              </div>
              <div className="col-span-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <WelcomeCard name={name} />
                  <AmountCard
                    credits={credits}
                    profile_completion={profile_completion}
                    userRole={userRole}
                  />
                </div>
              </div>
              <div className="col-span-8 lg:col-span-5 space-y-3">
                <div className="grid grid-cols-4 gap-3">
                  <div className="col-span-4 bg-white shadow-md rounded-lg p-5">
                    <h6 className="font-semibold mb-1">Users</h6>
                    <p className="text-gray-600 text-xs font-medium">
                      Platform Summary
                    </p>
                    <div className="grid grid-cols-4 gap-2 mt-5">
                      <div className="col-span-4 sm:col-span-2 md:col-span-1 p-3 rounded-lg bg-[#FFE2E5] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#FA5A7D] p-1 flex items-center justify-center">
                          <RocketIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">{startups}+</p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Startups
                        </p>
                        <Link
                          to="/startups"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                      <div className="col-span-4 sm:col-span-2 md:col-span-1 p-3 rounded-lg bg-[#DCFCE7] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#3CD856] p-1 flex items-center justify-center">
                          <UserGraduateIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">{mentors}+</p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Mentors
                        </p>
                        <Link
                          to="/mentors"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                      <div className="col-span-4 sm:col-span-2 md:col-span-1 p-3 rounded-lg bg-[#FFF4DE] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#FF947A] p-1 flex items-center justify-center">
                          <UserTieIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">{investors}+</p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Investors
                        </p>
                        <Link
                          to="/investors"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                      <div className="col-span-4 sm:col-span-2 md:col-span-1 p-3 rounded-lg bg-[#F3E8FF] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#BF83FF] p-1 flex items-center justify-center">
                          <ShoppingCartIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">{vendors}+</p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Service Providers
                        </p>
                        <Link
                          to="/vendors"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-2 bg-white rounded-lg shadow-md p-5">
                  <h6 className="col-span-6 font-semibold">Profile Summary</h6>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{ title: "Tokens used", count: used_credits }}
                      img={PHcoin}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Mentor Connections",
                        count: connected_mentors,
                      }}
                      img={usersIcon}
                      width={25}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Investor Connections",
                        count: connected_investors,
                      }}
                      img={users2Icon}
                      gradient="primary"
                      width={25}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-8 lg:col-span-3 card shadow-md rounded-lg">
                <h5 className="text-lg font-medium">
                  Popular Startup Industries
                </h5>
                <div className="px-0 cstm-svg-pos uppercase">
                  <BarGraph data={barGraphData} />
                </div>
              </div>
              <div className="col-span-8 md:col-span-5 py-3 md:pe-3">
                <RecentTransactions data={userTransactions} />
              </div>
              <div className="col-span-8 md:col-span-3 space-y-3">
                <div className="flex items-center justify-between">
                  <h5 className="text-lg font-medium">Recent Connections</h5>
                  <Link
                    to="/startup-connections"
                    className="text-sm flex items-center gap-x-1 text-primary font-medium"
                  >
                    View All <LongRightArrow fill="#17335E" />
                  </Link>
                </div>
                <div className="grid gap-2 card shadow-md rounded-lg py-3">
                  {userConnections?.length > 0 ? (
                    userConnections.map((connection) => (
                      <SingleConnection
                        key={connection.id}
                        data={connection}
                        ViewProfile={ViewProfile}
                        userRole={userRole}
                        userId={connection.connection_id}
                      />
                    ))
                  ) : (
                    <>
                      <p>Make your first connection now.</p>
                      <Link
                        to="/mentors"
                        className="w-fit px-3 py-1 bg-primary text-white hover:text-white text-sm rounded-full"
                      >
                        View Mentors
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ClaimModal
        key={randomId()}
        open={openClaimModal}
        onClose={() => setOpenClaimModal(false)}
        claimFields={claimFields}
        callBackFn={claimTokens}
      />
    </div>
  );
};

export default StartupDashBoard;
