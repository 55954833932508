import { useToastContext } from "../context/ToastContext";
import { getCurrentDate } from "../utils/getCurrentDate";
import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useSubmitReview = () => {
  const submitReviewApi = `${process.env.REACT_APP_API_URL}review`;

  const { token } = useLocalStorage();
  const { fetchApi } = useFetch();
  const toast = useToastContext();

  const submitReview = (id, rating, review, role, onClose) => {
    fetchApi(
      {
        url: submitReviewApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: id,
          review: review,
          rating: rating,
          role: role,
          date: getCurrentDate(),
        },
      },
      (data) => {
        if (data.message === "already reviewed") {
          toast.open("false", "You have already given a review to this user");
          onClose();
          return;
        }
        toast.open("true", "Review submitted successfully!");
        onClose();
      }
    );
  };
  return { submitReview };
};

export default useSubmitReview;
