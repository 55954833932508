import "./Dropdown.css";

const IconDropdown = ({ open, options, callBackFn }) => {
  return (
    <div className={`${!open && "hidden"} dropdown`}>
      <ul className="dropdown-list">
        {options.map((option) => (
          <li
            key={option.label}
            onClick={() => callBackFn(option.value)}
            className="iconDropdown-item"
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IconDropdown;
