import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import {
  bannedInputs,
  numberRegex,
  textWithSpaceRegex,
} from "../../../../constants";
import { BuildingIcon, PhoneIcon, UserIcon } from "../../../../icons/icons";
import { Dropdown } from "../../inputs/dropdown";

export const EditVendorProfile = ({
  open,
  onClose,
  editVendorDetails,
  editData,
  industries,
}) => {
  const [expertise, setExpertise] = useState([]);
  const ref = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vendorName: editData.name,
      vendorPhone: editData.phone,
      vendorCompany: editData.companyName,
    },
  });

  useEffect(() => {
    if (!ref.current) {
      if (editData.industry) {
        setExpertise([...editData.industry]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [editData]);

  if (!open) return null;

  const submitModal = (data) => {
    document.getElementById("industry-error").classList.add("hidden");
    document.getElementById("limit-error").classList.add("hidden");
    if (expertise.length < 1) {
      document.getElementById("industry-error").classList.remove("hidden");
      return;
    }
    if (expertise.length > 3) {
      document.getElementById("limit-error").classList.remove("hidden");
      return;
    }
    editVendorDetails(data, expertise);
    setExpertise([]);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] max-h-[95vh] overflow-y-auto md:min-w-[50%] md:max-w-[50%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Update Profile
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="grid grid-cols-1 md:grid-cols-2 gap-2 p-3 mt-3"
        >
          <div className="mb-2">
            <label htmlFor="vendorName" className="mb-0 text-sm">
              Edit Name
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="vendorName"
                name="vendorName"
                placeholder="Enter Name"
                autoFocus
                {...register("vendorName", {
                  validate: {
                    validLength: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? fieldValue.trim().length > 2 ||
                            "minimum required length is 3"
                        : null;
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons icon-span w-[39px]">
                <UserIcon />
              </span>
            </div>
            {errors.vendorName && (
              <div>
                <FormError message={errors.vendorName.message} />
              </div>
            )}
          </div>
          <div className="mb-2">
            <label htmlFor="vendorPhone" className="mb-0 text-sm">
              Change Contact Number
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                maxLength="12"
                minLength="7"
                className="border-0 w-full ps-3"
                id="vendorPhone"
                name="vendorPhone"
                placeholder="Enter Phone Number"
                {...register("vendorPhone", {
                  validate: {
                    minValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length > 6 ||
                            "Number should be atleast 7 digits"
                        : null;
                    },
                    maxValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length < 13 ||
                            "Number cannot be more than 12 digits"
                        : null;
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span w-[39px]">
                <PhoneIcon />
              </span>
            </div>
            {errors.vendorPhone && (
              <div>
                <FormError message={errors.vendorPhone.message} />
              </div>
            )}
          </div>
          <div className="flex-col">
            <p className="text-sm mb-0">
              Company expertise <span>({expertise.length + "/3"})</span>
              <span id="limit-error" className="hidden text-red-500 text-xs">
                Limit exceeded
              </span>
            </p>
            <Dropdown
              options={industries?.map((industry) => ({
                label: industry.industry_name
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry.industry_name,
              }))}
              value={expertise.map((industry) => ({
                label: industry
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry,
              }))}
              onChange={(selectedOptions) =>
                setExpertise(selectedOptions.map((option) => option.value))
              }
              isMulti
            />
            <small className="text-red-500 mb-2 hidden" id="industry-error">
              This field is mandatory.
            </small>
          </div>
          <div className="mb-2">
            <label htmlFor="vendorCompany" className="mb-0 text-sm">
              Change Company Name
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="vendorCompany"
                name="vendorCompany"
                placeholder="Enter Company Name"
                {...register("vendorCompany", {
                  validate: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      : null;
                  },
                })}
              />
              <span className="input-icons icon-span w-[39px]">
                <BuildingIcon />
              </span>
            </div>
            {errors.vendorCompany && (
              <div>
                <FormError message={errors.vendorCompany.message} />
              </div>
            )}
          </div>
          <div className="md:col-span-2 flex items-center justify-end p-3">
            <Button
              onClick={onClose}
              className="px-2 py-1 mx-1 text-sm"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1 text-sm">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
