import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { WalletTable } from "./walletTable";
import { TransactionTable } from "./transactionTable";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import Navbar from "../../../components/layout/Navbar";
import Sidebar from "../../../components/layout/Sidebar";
import { Button } from "../../../components/ui/Button";
import "../transactionPages.css";

const StartupWallet = () => {
  const getCreditHistoryApi = `${process.env.REACT_APP_API_URL}credit-history`;
  const startupTransactionsApi = `${process.env.REACT_APP_API_URL}startup-transactions`;
  const [creditTransactions, setCreditTransactions] = useState([]);
  const [usedCredits, setUsedCredits] = useState(0);
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [tableType, setTableType] = useState("wallet");
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const effectRef = useRef(true);
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);

  const getCreditHistory = useCallback(() => {
    setTableType("wallet");
    fetchApi(
      { url: getCreditHistoryApi, headers: { "x-token": token } },
      (data) => {
        setCreditTransactions(data.credit_history);
        setUsedCredits(data.used_credit);
      }
    );
  }, [fetchApi, getCreditHistoryApi, token]);

  const getTransactionHistory = useCallback(() => {
    setTableType("transaction");
    fetchApi(
      { url: startupTransactionsApi, headers: { "x-token": token } },
      (data) => {
        setWalletTransactions(data.transaction_details);
      }
    );
  }, [fetchApi, startupTransactionsApi, token]);

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/");
      return;
    }
    if (effectRef.current) {
      getCreditHistory();
    }
    return () => {
      effectRef.current = false;
    };
  }, [getCreditHistory, navigate, userRole]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-5 pt-6 h-full">
          <div className="text-end">
            <Link
              to="/store"
              className="px-2 py-1 md:py-[6px] text-xs md:text-sm bg-green-600 text-white rounded-md mx-1 hover:text-white"
            >
              Buy Tokens
            </Link>
            <Button
              onClick={getCreditHistory}
              className="px-2 py-1 mx-1 text-xs md:text-sm"
              bgColor="bg-[#FC551E]"
            >
              Wallet
            </Button>
            <Button
              onClick={getTransactionHistory}
              className="px-2 py-1 mx-1 text-xs md:text-sm"
            >
              Transactions
            </Button>
          </div>
          {isLoading ? <Loader /> : null}
          {error && <Error />}
          {!isLoading &&
            !error &&
            (tableType === "wallet"
              ? creditTransactions && (
                  <WalletTable
                    creditTransactions={creditTransactions}
                    usedCredits={usedCredits}
                  />
                )
              : tableType === "transaction"
              ? walletTransactions && (
                  <TransactionTable walletTransactions={walletTransactions} />
                )
              : null)}
        </div>
      </div>
    </div>
  );
};

export default StartupWallet;
