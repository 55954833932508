import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex } from "../../../../constants";

export const AddBidModal = ({ open, onClose, adDetails, postBid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    document.getElementById("id-error").classList.add("hidden");

    if (adDetails.id.length < 0) {
      document.getElementById("id-error").classList.remove("hidden");
      return;
    }
    postBid(adDetails.id, adDetails.startupId, data);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:max-w-[50%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-base">
          Add Bid
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="grid grid-cols-1 md:grid-cols-3 gap-2 p-2 pb-0"
        >
          <span className="hidden text-red-500 text-sm" id="id-error">
            Couldn't post bid. Ad id was missing try again.
          </span>
          <div className="md:col-span-3 flex flex-col">
            <p htmlFor="ad-title" className="font-medium">
              Bidding for
            </p>
            <p className="p-2 max-h-28 overflow-auto border rounded bg-slate-100">
              {adDetails.title}
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="minAmount" className="mb-1 font-medium text-sm">
              Minimum budget
            </label>
            <input
              type="text"
              className="p-1 border rounded-md bg-slate-100"
              value={adDetails.minAmount}
              disabled
              readOnly
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="bidAmount"
              className="mb-1 font-medium text-sm required"
            >
              Bid Amount
            </label>
            <input
              type="number"
              id="bidAmount"
              name="bidAmount"
              className="p-1 border rounded-md"
              placeholder="Enter bid amount"
              {...register("bidAmount", {
                required: "bid amount is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                validate: {
                  notZero: (fieldValue) => {
                    return fieldValue > 0 || "Amount cannot be 0";
                  },
                  minAmount: (fieldValue) => {
                    return fieldValue > 0
                      ? fieldValue >= adDetails.minAmount ||
                          "Amount cannot be less than minimum project budget"
                      : null;
                  },
                },
              })}
            />
            {errors.bidAmount && (
              <div>
                <FormError message={errors.bidAmount.message} />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="workDuration"
              className="mb-1 font-medium text-sm required"
            >
              Work Duration(days)
            </label>
            <input
              type="number"
              id="workDuration"
              name="workDuration"
              className="p-1 border rounded-md"
              placeholder="Add work duration"
              {...register("workDuration", {
                required: "Duration is required",
                pattern: {
                  value: numberRegex,
                  message: "Duration must only contain digits",
                },
                validate: (fieldValue) => {
                  return fieldValue > 0 || "Duration cannot be 0";
                },
              })}
            />
            {errors.workDuration && (
              <div>
                <FormError message={errors.workDuration.message} />
              </div>
            )}
          </div>
          <div className="md:col-span-3 flex flex-col">
            <label
              htmlFor="bidDescription"
              className="mb-1 font-medium text-sm required"
            >
              Bid Description
            </label>
            <textarea
              name="bidDescription"
              id="bidDescription"
              cols="30"
              rows="7"
              className="p-1 border rounded-md"
              placeholder="Add bid description"
              {...register("bidDescription", {
                required: "description is required",
                minLength: {
                  value: 150,
                  message: "description must be at least 150 characters",
                },
                maxLength: {
                  value: 2000,
                  message: "description must be less than 2000 characters",
                },
              })}
            ></textarea>
            {errors.bidDescription && (
              <div>
                <FormError message={errors.bidDescription.message} />
              </div>
            )}
          </div>
          <div className="md:col-start-3 text-right p-2">
            <Button
              onClick={onClose}
              className="px-1 py-0.5 mx-1"
              bgColor="bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="px-1 py-0.5 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
