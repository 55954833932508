import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCredits } from "../../store/slices/FetchCreditsSlice";
import { deleteChatRoom } from "../../store/slices/ChatSlice";
import { updateTokensUsed } from "../../store/slices/dashboard/StartupDashboardSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useNotificationApi from "../../hooks/useNotificationApi";
import useCheckConnection from "../../hooks/useCheckConnection";
import useUnfollowConnection from "../../hooks/useUnfollowConnection";
import useVerificationApi from "../../hooks/useVerificationApi";
import { useToastContext } from "../../context/ToastContext";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { ConfirmationModal } from "../../components/ui/modals/utility-modals/confirmationModal";
import NavigateToDashboard from "../../utils/navigateToDashboard";
import { Button } from "../../components/ui/Button";
import { getCurrentDate } from "../../utils/getCurrentDate";
import { capitalizeText } from "../../utils/capitalizeText";
import "./Profile.css";
import "./viewProfile.css";
import FavIcon from "../../images/favicon.png";
import {
  CalenderIcon,
  FolderIcon,
  LocationIcon,
  RupeeIcon,
} from "../../icons/icons";

const ViewInvestorProfile = () => {
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}display-investor-profile`;
  const sendConnectionRequestApi = `${process.env.REACT_APP_API_URL}send-connect-request`;
  const acceptOrCancelApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(true);
  const { user_id } = useParams();
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const { checkConnection } = useCheckConnection();
  const { unFollow } = useUnfollowConnection();
  const { verification } = useVerificationApi();
  const toast = useToastContext();
  const location = useLocation();
  const { credits } = useSelector((state) => state.credits);

  const [investorDetails, setInvestorDetails] = useState([]);
  const [investorIndustries, setInvestorIndustries] = useState([]);
  const [prevInvestments, setPrevInvestments] = useState([]);
  const [investorBio, setInvestorBio] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [connectionStatus, setConnectionStatus] = useState({
    status: "",
    senderId: "",
  });
  const [startupVerified, setStartupVerified] = useState("0");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    callback: null,
    message: "Are you sure you want to disconnect?",
  });
  const [userlevel, setUserlevel] = useState(0);

  const getInvestorDetails = useCallback(() => {
    if (!user_id) {
      toast.open("false", "Sorry, something went wrong. Please try again");
      return;
    }
    fetchApi(
      {
        url: investorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
        },
      },
      (data) => {
        if (data.message === "suspended") {
          alert("This accound has been suspended/Deactivated.");
          navigate(NavigateToDashboard(userRole));
          return;
        }
        setInvestorDetails(data.investor_profile[0]);
        setInvestorIndustries(data.investor_profile[0].investor_industries);
        setProfileImg(data.investor_profile[0].logo);
        setInvestorBio(data.investor_profile[0].description);
        setPrevInvestments(data.investor_profile[0].previous_investment);
        setStartupVerified(data.profile_verify);
        setUserlevel(data.level);
      }
    );
  }, [investorDetailsApi, fetchApi, token, user_id, navigate, userRole, toast]);

  useEffect(() => {
    if (userRole === "Sw%aa" || userRole === "V&iR8") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getInvestorDetails();
      checkConnection(user_id, setConnectionStatus);
    }
    return () => {
      ref.current = false;
    };
  }, [
    userRole,
    navigate,
    getInvestorDetails,
    verification,
    checkConnection,
    user_id,
  ]);

  const sendConnectionRequest = useCallback(() => {
    fetchApi(
      {
        url: sendConnectionRequestApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: user_id,
          date: getCurrentDate(),
        },
      },
      (data) => {
        toast.open("true", "Connection request sent successfully!");
        checkConnection(user_id, setConnectionStatus);
        dispatch(updateCredits({ type: "remove", amount: 100 }));
        dispatch(updateTokensUsed({ type: "add", amount: 100 }));
        sendNotification(
          user_id,
          `Incoming connection request from ${data.name}`,
          "connections"
        );
      }
    );
  }, [
    toast,
    sendConnectionRequestApi,
    fetchApi,
    token,
    sendNotification,
    user_id,
    checkConnection,
    dispatch,
  ]);

  const acceptOrCancelReq = useCallback(
    (type, requestType) => {
      fetchApi(
        {
          url: acceptOrCancelApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: user_id,
            type: type,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "accepted") {
            toast.open("true", "Connection request accepted!");
            setConnectionStatus((prev) => ({ ...prev, status: "connected" }));
          }
          if (data.message === "already cancelled") {
            if (requestType === "sender" && userRole === "f@!3A") {
              dispatch(updateCredits({ type: "add", amount: 100 }));
              dispatch(updateTokensUsed({ type: "remove", amount: 100 }));
            }
            toast.open(
              "warning",
              "Sorry, Request was already cancelled by sender!"
            );
            setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
          }
          if (data.message === "cancelled") {
            if (requestType === "sender" && userRole === "f@!3A") {
              dispatch(updateCredits({ type: "add", amount: 100 }));
              dispatch(updateTokensUsed({ type: "remove", amount: 100 }));
            }
            toast.open("true", "Connection request cancelled successfully!");
            setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
            sendNotification(
              user_id,
              `Connection cancelled from ${data.name}`,
              "connections"
            );
          }
          if (data.message === "connected") {
            toast.open("warning", "User has already accepted this request");
            setConnectionStatus((prev) => ({ ...prev, status: "connected" }));
          }
        }
      );
    },
    [
      fetchApi,
      token,
      acceptOrCancelApi,
      toast,
      sendNotification,
      user_id,
      dispatch,
      userRole,
    ]
  );

  const routeToChat = () => {
    dispatch(deleteChatRoom());
    navigate("/chat");
  };

  const handleConfirmationModal = (type) => {
    if (type === "unfollow") {
      setConfirmationModal((prev) => ({
        ...prev,
        open: true,
        callback: () => unFollow(user_id, setConnectionStatus),
      }));
    } else {
      setConfirmationModal({
        open: true,
        callback: () => sendConnectionRequest(),
        message: `There is a one time cost of ${location.state?.connection_fee} tokens to connect with a investor`,
      });
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-3 md:p-4">
          <div>
            {isLoading && !error ? (
              <Loader />
            ) : (
              <>
                <div className="grid grid-cols-6 gap-2">
                  <div className="col-span-6 ml-auto md:hidden">
                    {connectionStatus.status === "connected" &&
                      userRole === "f@!3A" && (
                        <>
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            onClick={() => routeToChat()}
                          >
                            Message
                          </Button>
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            bgColor="bg-secondary"
                            onClick={() => handleConfirmationModal("unfollow")}
                          >
                            Disconnect
                          </Button>
                        </>
                      )}
                    {connectionStatus.status === "disconnect" &&
                    userRole === "f@!3A" ? (
                      credits >= 100 && userlevel === 3 ? (
                        <Button
                          className="px-2 py-1 mx-1 text-xs"
                          onClick={() => handleConfirmationModal("connect")}
                        >
                          Connect
                        </Button>
                      ) : credits < 100 && userlevel === 3 ? (
                        <Link
                          to="/store"
                          title="Insufficient tokens"
                          className="disabled px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                        >
                          Connect
                        </Link>
                      ) : credits >= 100 && startupVerified === "1" ? (
                        <Link
                          className="px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                          onClick={() => handleConfirmationModal("connect")}
                        >
                          Connect
                        </Link>
                      ) : credits >= 100 && startupVerified !== "1" ? (
                        <Link
                          title="Not verified"
                          className="disabled px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                        >
                          Connect
                        </Link>
                      ) : credits < 100 && startupVerified === "1" ? (
                        <Link
                          to="/store"
                          title="Insufficient tokens"
                          className="disabled px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                        >
                          Connect
                        </Link>
                      ) : null
                    ) : null}
                    {connectionStatus.status === "pending" && (
                      <>
                        <Button
                          className="px-2 py-1 mx-1 text-xs cursor-not-allowed disabled"
                          disabled
                          bgColor="bg-gray-400"
                        >
                          Awaiting Acceptance
                        </Button>
                        {connectionStatus.senderId === user_id ? (
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            bgColor="bg-green-700"
                            onClick={() =>
                              acceptOrCancelReq("accept", "reciever")
                            }
                          >
                            Accept Request
                          </Button>
                        ) : (
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            bgColor="bg-orange-700"
                            onClick={() =>
                              acceptOrCancelReq("cancel", "sender")
                            }
                          >
                            Cancel Request
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-span-6 md:col-span-2">
                    <div className="bg-white shadow-md rounded-md p-3 text-center">
                      <img
                        src={profileImg.length > 0 ? profileImg : FavIcon}
                        className="rounded-full w-[90px] h-[90px] md:w-[120px] md:h-[120px] border border-slate-400 p-1"
                        alt="profile-img"
                      />
                      <div className="text-start">
                        <p className="flex items-center font-bold text-lg pt-2 mb-0 uppercase">
                          {investorDetails.name}
                        </p>
                        <div className="flex items-center mb-2">
                          <CalenderIcon className="#999999" />
                          <p className="text-sm mb-0 ms-3">
                            <span className="font-semibold me-1">
                              Joined On :
                            </span>
                            {investorDetails.date}
                          </p>
                        </div>
                        <div className="flex items-center mb-2">
                          <RupeeIcon fill="#999999" />
                          <p className="text-sm mb-0 ms-3">
                            <span className="font-semibold me-1">
                              Total Investment :
                            </span>
                            {investorDetails.invested_amount}
                          </p>
                        </div>
                        <div className="flex items-center mb-2">
                          <RupeeIcon fill="#999999" />
                          <p className="text-sm mb-0 ms-3">
                            <span className="font-semibold me-1">
                              Investment Target :
                            </span>
                            {investorDetails.future_investment_amount}
                          </p>
                        </div>
                        <div className="flex items-center mb-2">
                          <FolderIcon fill="#999999" />
                          <p className="text-sm mb-0 ms-3">
                            <span className="font-semibold me-1">
                              Companies in portfolio :
                            </span>{" "}
                            {investorDetails.companies_invested_count}
                          </p>
                        </div>
                        <div className="flex mb-4">
                          <LocationIcon fill="#999999" />
                          <div className="flex items-center text-sm mb-0 ms-3">
                            <span className="font-semibold me-1">
                              Address :
                            </span>
                            <div className="flex items-center">
                              <span className="me-1 capitalize">
                                {investorDetails.city}
                              </span>
                              <img
                                src={
                                  investorDetails.country_code
                                    ? `https://flagsapi.com/${investorDetails.country_code}/flat/64.png`
                                    : "https://flagsapi.com/IN/flat/64.png"
                                }
                                alt={`${investorDetails.country} flag`}
                                width={18}
                                title={capitalizeText(investorDetails.country)}
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="border-t py-2">
                          <p className="mb-0 font-semibold">Industries</p>
                          {investorIndustries.map((industry, i) => (
                            <span key={i} className="outline-tags">
                              {industry.replace(new RegExp("_", "g"), " ")}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-4">
                    {startupVerified !== "1" &&
                      userlevel !== 3 &&
                      connectionStatus.status !== "connected" &&
                      userRole === "f@!3A" && (
                        <span className="text-secondary text-xs">
                          Get mentor verification to connect with investor.
                          <a
                            href="https://www.youtube.com/watch?v=FRLDZTLYn8w"
                            target="_blank"
                            rel="noreferrer"
                            className="ms-1 underline underline-offset-2 text-blue-500"
                          >
                            Know why?
                          </a>
                        </span>
                      )}
                    <div className="hidden md:flex items-center justify-end mb-8">
                      {connectionStatus.status === "connected" &&
                        userRole === "f@!3A" && (
                          <>
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              onClick={() => routeToChat()}
                            >
                              Message
                            </Button>
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              bgColor="bg-secondary"
                              onClick={() =>
                                handleConfirmationModal("unfollow")
                              }
                            >
                              Disconnect
                            </Button>
                          </>
                        )}
                      {connectionStatus.status === "disconnect" &&
                      userRole === "f@!3A" ? (
                        credits >= 100 && userlevel === 3 ? (
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            onClick={() => handleConfirmationModal("connect")}
                          >
                            Connect
                          </Button>
                        ) : credits < 100 && userlevel === 3 ? (
                          <Link
                            to="/store"
                            title="Insufficient tokens"
                            className="disabled px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                          >
                            Connect
                          </Link>
                        ) : credits >= 100 && startupVerified === "1" ? (
                          <Link
                            className="px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                            onClick={() => handleConfirmationModal("connect")}
                          >
                            Connect
                          </Link>
                        ) : credits >= 100 && startupVerified !== "1" ? (
                          <Link
                            title="Not verified"
                            className="disabled px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                          >
                            Connect
                          </Link>
                        ) : credits < 100 && startupVerified === "1" ? (
                          <Link
                            to="/store"
                            title="Insufficient tokens"
                            className="disabled px-2 py-1 mx-1 text-xs rounded-md bg-primary text-white"
                          >
                            Connect
                          </Link>
                        ) : null
                      ) : null}
                      {connectionStatus.status === "pending" && (
                        <>
                          <Button
                            className="px-2 py-1 mx-1 text-xs cursor-not-allowed disabled"
                            disabled
                            bgColor="bg-gray-400"
                          >
                            Awaiting Acceptance
                          </Button>
                          {connectionStatus.senderId === user_id ? (
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              bgColor="bg-green-700"
                              onClick={() =>
                                acceptOrCancelReq("accept", "reciever")
                              }
                            >
                              Accept Request
                            </Button>
                          ) : (
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              bgColor="bg-orange-700"
                              onClick={() =>
                                acceptOrCancelReq("cancel", "sender")
                              }
                            >
                              Cancel Request
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    {investorBio.length > 0 ? (
                      <div className="mt-4 p-3 pt-1 shadow-md rounded-md">
                        <h4 className="my-3 font-medium text-lg">Bio</h4>
                        <p>{investorBio}</p>
                      </div>
                    ) : (
                      <div className="rounded-md bg-white shadow-md mt-3 p-3 text-sm">
                        No Bio added
                      </div>
                    )}
                    {prevInvestments.length > 0 ? (
                      <>
                        <div className="p-2 rounded-md bg-white shadow mt-3 py-3">
                          <h5 className="font-medium mb-3">Portfolio</h5>
                          {prevInvestments.map((investment) => (
                            <div
                              key={investment._id}
                              className="flex items-center justify-between p-2 my-1 bg-gray-200 rounded"
                            >
                              <div>
                                <span className="font-medium me-2">
                                  Company Name :
                                </span>
                                <span>{investment.company_name}</span>
                              </div>
                              <Link
                                to={
                                  investment.company_url
                                    ? investment.company_url
                                    : "#"
                                }
                                target={
                                  investment.company_url ? "_blank" : "_self"
                                }
                                rel={
                                  investment.company_url
                                    ? "noopener noreferrer"
                                    : undefined
                                }
                              >
                                <span className="font-medium me-2">
                                  Website Url :
                                </span>
                                <span
                                  class={
                                    !investment.company_url
                                      ? "text-red-500"
                                      : ""
                                  }
                                >
                                  {investment.company_url
                                    ? investment.company_url
                                    : "NA"}
                                </span>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="rounded-md bg-white shadow-md mt-3 p-3 text-sm">
                        No previous investments
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={() =>
          setConfirmationModal((prev) => ({ ...prev, open: false }))
        }
        callBackFn={confirmationModal.callback}
        message={confirmationModal.message}
      />
    </div>
  );
};

export default ViewInvestorProfile;
