import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, deleteUser } from "../../../store/slices/LoginSlice";
import { deleteChatRoom } from "../../../store/slices/ChatSlice";
import { clearNotifications } from "../../../store/slices/NotificationSlice";
import { clearCredits } from "../../../store/slices/FetchCreditsSlice";
import { clearDashboard } from "../../../store/slices/dashboard/StartupDashboardSlice";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { LoginForm } from "./loginForm";
import { FrogotPasswordForm } from "../frogotPasswordForm";
import { EmailSuccess } from "../emailSuccess";
import "../Signin.css";
import "../../register-page/Register.css";
import "../../../custom.css";
import pitchHereLogo from "../../../images/pitch-here-logo.png";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [suspended, setSuspended] = useState(false);

  useEffect(() => {
    document.getElementById("forgotPasswordForm").classList.add("hidden");
    document.getElementById("confirmationText").classList.add("hidden");
    dispatch(deleteUser());
    dispatch(deleteChatRoom());
    dispatch(clearNotifications());
    dispatch(clearCredits());
    dispatch(clearDashboard());
  }, [dispatch]);

  const postLogin = (data) => {
    if (data.message === "account suspended") {
      setSuspended(true);
      return;
    }
    dispatch(setUser({ id: data.jwt_token, role: data.role, name: data.name }));
    if (data.profile_completion === 30) {
      navigate("/profile-completion");
      return;
    }
    if (data.role === "f@!3A") {
      navigate("/startup-dashboard");
    } else if (data.role === "R$7s2") {
      navigate("/mentor-dashboard", {
        state: { profile_completion: data.profile_completion },
      });
    } else if (data.role === "Sw%aa") {
      navigate("/investor-dashboard", {
        state: { profile_completion: data.profile_completion },
      });
    } else {
      navigate("/vendor-profile");
    }
  };

  const forgotPasswordBtn = () => {
    document.getElementById("forgotPasswordForm").classList.remove("hidden");
    document.getElementById("loginForm").classList.add("hidden");
  };

  const backToLogin = () => {
    document.getElementById("forgotPasswordForm").classList.add("hidden");
    document.getElementById("confirmationText").classList.add("hidden");
    document.getElementById("loginForm").classList.remove("hidden");
  };

  return (
    <div className="authentication-wrapper authentication-cover">
      {width < 1024 ? (
        <p className="text-center pt-5">
          <Link to="/">
            <img
              src={pitchHereLogo}
              alt="skilldukan-logo"
              width={200}
              className="inline"
            />
          </Link>
        </p>
      ) : (
        <p className="text-left">
          <Link to="/" className="auth-cover-brand md:ps-5">
            <img src={pitchHereLogo} alt="skilldukan-logo" width={140} />
          </Link>
        </p>
      )}
      <div className="md:h-screen w-full relative grid grid-cols-7 lg:bg-white mt-[70px] md:mt-0">
        <div className="hidden main-bg-img h-screen lg:flex col-span-5 md:items-center justify-center p-3"></div>
        <LoginForm
          postLogin={postLogin}
          forgotPasswordBtn={forgotPasswordBtn}
          suspended={suspended}
          setSuspended={setSuspended}
        />
        <FrogotPasswordForm backToLogin={backToLogin} role="user" />
        <EmailSuccess backToLogin={backToLogin} />
      </div>
    </div>
  );
};

export default Signin;
