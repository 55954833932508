import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToastContext } from "../../context/ToastContext";
import { Pagination } from "../../components/ui/Button";
import FavIcon from "../../images/favicon.png";
import { EyeIcon, UnbanIcon } from "../../icons/icons";

export const BlockedUsers = ({
  blockedConnections,
  handleConfirmationModal,
  viewProfile,
}) => {
  const toast = useToastContext();

  const [page, setPage] = useState(1);

  return (
    <>
      <h4 className="mb-1 md:mb-4 font-semibold md:text-lg">Blocked Users</h4>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">User Name</th>
              <th className="p-3 font-medium text-sm">Blocked on</th>
              <th className="p-3 font-medium text-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            {blockedConnections.length > 0 ? (
              blockedConnections.slice(page * 7 - 7, page * 7).map((user) => (
                <tr
                  key={user.user_id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 flex items-center capitalize">
                    <div
                      onClick={() =>
                        viewProfile(user.name, "f@!3A", user.user_id)
                      }
                      className="flex items-center gap-x-2 cursor-pointer"
                    >
                      <img
                        src={user.logo.length > 0 ? user.logo : FavIcon}
                        alt="Startup Logo"
                        className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] rounded-full"
                        loading="lazy"
                      />
                      <span className="font-medium">{user.name}</span>
                    </div>
                  </td>
                  <td className="p-3 text-xs md:text-sm">{user.date}</td>
                  <td className="p-3 text-xs md:text-sm" id="connectedUserId">
                    <div className="flex items-center justify-evenly gap-2 h-full md:w-7/12">
                      {user.deactivate === "0" ? (
                        <Link
                          to={`/view-startup/${user.name
                            .split(" ")
                            .join("-")}/${user.user_id}`}
                          title="View Profile"
                        >
                          <EyeIcon
                            width="20px"
                            height="20px"
                            className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                          />
                        </Link>
                      ) : (
                        <span title="Deactivated Profile">
                          <EyeIcon
                            width="20px"
                            height="20px"
                            className="disabled fill-green-500"
                            onClick={() =>
                              toast.open(
                                "warning",
                                "This profile has been deactivated"
                              )
                            }
                          />
                        </span>
                      )}
                      <span title="UnBlock User">
                        <UnbanIcon
                          width="20px"
                          height="20px"
                          className="cursor-pointer fill-blue-500 transition-colors ease-in ease duration-50 hover:fill-blue-800"
                          onClick={() =>
                            handleConfirmationModal("unblock", user.user_id)
                          }
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan="3" className="p-3 text-xs md:text-sm">
                  No blocked users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {blockedConnections.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={blockedConnections.length}
        />
      )}
    </>
  );
};
