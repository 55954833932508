import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getNotificationsApi = `${process.env.REACT_APP_API_URL}view-notifications`;

const initialState = {
  notifications: [],
  notificationCount: 0,
  unReadMsg: 0,
  profileImg: "",
  status: "idle",
  error: null,
};

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(getNotificationsApi, {
        headers: {
          "x-token": token,
        },
      });
      const data = response.data;

      return {
        notifications: data.notifications,
        notificationCount: data.unread_notifications,
        unReadMsg: data.unread_messages,
        profileImg: data.logo,
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "An error occurred while fetching notifications.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearNotifications: (state) => {
      Object.assign(state, initialState);
    },
    updateNotiStatus: (state, action) => {
      const { notificationId } = action.payload;
      const notification = state.notifications.find(
        (noti) => noti.id === notificationId
      );
      if (notification) {
        if (notification.status === "0") {
          state.notificationCount = Math.max(state.notificationCount - 1, 0);
        }
        notification.status = "1";
      }
    },
    markAllNotificationsAsRead: (state) => {
      state.notifications.forEach((noti) => {
        if (noti.status === "0") {
          noti.status = "1";
        }
      });
      state.notificationCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.notifications = action.payload.notifications;
        state.notificationCount = action.payload.notificationCount;
        state.unReadMsg = action.payload.unReadMsg;
        state.profileImg = action.payload.profileImg;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  clearNotifications,
  updateNotiStatus,
  markAllNotificationsAsRead,
} = notificationSlice.actions;
export default notificationSlice.reducer;
