import React, { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import AdminSidebar from "../../../components/layout/adminSidebar";
import AdminNavbar from "../../../components/layout/adminNavbar";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import Plans from "./Plans";
import TopUp from "./TopUp";

const Store = () => {
  const { isLoading, error } = useFetch();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [storeType, setStoreType] = useState("Plans");

  const renderStore = () => {
    if (storeType === "Plans") {
      return <Plans />;
    } else {
      return <TopUp />;
    }
  };

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!error && !isLoading && (
          <div className="p-5">
            <p className="col-span-3 mb-1 font-medium text-sm">
              Note : Use toggle to switch between Token Plans and Top Up Plans.
            </p>
            <div className="mb-5">
              <span
                className={`${
                  storeType === "Plans"
                    ? "bg-primary text-white"
                    : "bg-gray-200"
                } px-2 py-1 rounded-l-md border-r-2 border-gray-400`}
                onClick={() => setStoreType("Plans")}
              >
                Plans
              </span>
              <span
                className={`${
                  storeType === "Top Up"
                    ? "bg-primary text-white"
                    : "bg-gray-200"
                } px-2 py-1 rounded-r-md`}
                onClick={() => setStoreType("Top Up")}
              >
                Top Up
              </span>
            </div>
            {renderStore()}
          </div>
        )}
      </div>
    </div>
  );
};

export default Store;
