import { XMark } from "../../../icons/icons";
import "./toast.css";

const Toast = ({ success = "false", msg = "", close }) => {
  return (
    <div
      className={`toast-component ${
        success === "false"
          ? "bg-red-300"
          : success === "true"
          ? "bg-green-300"
          : "bg-orange-300"
      }`}
    >
      <XMark className="toast-close-btn" onClick={close} />
      <div
        className={`p-4 ${
          success === "false"
            ? "text-red-800"
            : success === "true"
            ? "text-green-800"
            : "text-orange-800"
        }`}
      >
        <h3 className="font-bold">
          {success === "false"
            ? "Action Failed"
            : success === "true"
            ? "Success"
            : "Warning"}
        </h3>
        <p className="text-sm">{msg}</p>
      </div>
    </div>
  );
};

export default Toast;
