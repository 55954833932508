import { EmptyStar, FilledStar, HalfStar } from "../../../../icons/starSvg";

export const Stars = ({ rating }) => {
  let halfstar = false;
  return (
    <>
      {[...Array(5)].map((_, i) => (
        <>
          {rating > 0 ? (
            <span key={i}>
              {rating.toString().split(".")[0] > i ? (
                <FilledStar />
              ) : rating.toString().split(".")[1] > 0 && !halfstar ? (
                <>
                  <HalfStar />
                  {(halfstar = true)}
                </>
              ) : (
                <EmptyStar />
              )}
            </span>
          ) : (
            <span key={i}>
              <EmptyStar />
            </span>
          )}
        </>
      ))}
    </>
  );
};
