import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useAddNewBank from "../../../../hooks/useAddNewBank";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import banks from "../../../../json-data/banks.json";
import {
  alphanumericRegex,
  numberRegex,
  textWithSpaceRegex,
  bannedInputs,
} from "../../../../constants";
import "../../../../custom.css";
import {
  BuildingColumnIcon,
  HashtagIcon,
  UserIcon,
} from "../../../../icons/icons";

export const BankAccountModal = ({ open, onClose, callBackFn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  const { newBank } = useAddNewBank();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    newBank(data, callBackFn);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Bank Account Details
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-3 text-left">
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="accountName">
              Account holder name
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="accountName"
                name="accountName"
                placeholder="Enter Name"
                {...register("accountName", {
                  required: "Name is required",
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                  validate: {
                    validLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 2 ||
                        "minimum required length is 3"
                      );
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.accountName && (
              <div>
                <FormError message={errors.accountName.message} />
              </div>
            )}
          </div>
          <div className="mb-1 pt-2 flex-col">
            <label htmlFor="bankName" className="required">
              Bank Name
            </label>
            <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
              <select
                name="bankName"
                id="bankName"
                className="border-0 w-full text-gray-500"
                {...register("bankName", {
                  required: "Select a bank",
                  validate: (fieldValue) => {
                    return fieldValue.length > 0 || "Select a bank";
                  },
                })}
              >
                <option value="">Select Bank</option>
                {banks?.banks.map((bank) => (
                  <option
                    key={bank.value}
                    value={bank.value}
                  >{`${bank.name} Bank`}</option>
                ))}
              </select>
            </div>
            {errors.bankName && (
              <div>
                <FormError message={errors.bankName.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="accountNumber">
              Bank account number
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="accountNumber"
                name="accountNumber"
                placeholder="Enter account number"
                {...register("accountNumber", {
                  required: "Account number is required",
                  pattern: {
                    value: numberRegex,
                    message: "Amount must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <BuildingColumnIcon />
              </span>
            </div>
            {errors.accountNumber && (
              <div>
                <FormError message={errors.accountNumber.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label className="mb-0 text-sm required" htmlFor="ifscCode">
              IFSC Code
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="ifscCode"
                name="ifscCode"
                placeholder="Enter IFSC Code"
                {...register("ifscCode", {
                  required: "IFSC code is required",
                  validate: {
                    minValidLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 9 ||
                        "minimum required length is 9"
                      );
                    },
                    maxValidLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length < 13 ||
                        "maximum length can be 12"
                      );
                    },
                  },
                  pattern: {
                    value: alphanumericRegex,
                    message: "IFSC code can contain only alphabets and numbers",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <HashtagIcon />
              </span>
            </div>
            {errors.ifscCode && (
              <div>
                <FormError message={errors.ifscCode.message} />
              </div>
            )}
          </div>
          <div className="modalFooter p-3">
            <Button
              className="px-2 py-1 mx-1"
              bgColor="bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="px-2 py-1 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
