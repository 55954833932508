import React, { useState } from "react";
import { Link } from "react-router-dom";
import StartupSidebar from "./startupSidebar";
import MentorSidebar from "./mentorSidebar";
import InvestorSidebar from "./investorSidebar";
import VendorSidebar from "./vendorSidebar";
import { TicketModal } from "../ui/modals/ticket/ticketModal";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import { CloseIcon } from "../../icons/icons";

export const ResponsiveSidebar = ({
  closeSidebar,
  supportTicket,
  role,
  style,
}) => {
  const [open, setOpen] = useState(false);

  const currPath = window.location.pathname;
  const activeStyle =
    "text-sm flex items-center gap-x-4 cursor-pointer p-2 bg-primary text-white hover:text-white rounded-md mt-2";
  const regularStyle =
    "text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-blue-200 rounded-md mt-2";

  return (
    <div
      className="responsive h-full w-full py-3 px-5 bg-[#f0f8ff] text-left shadow-lg"
      id="sidebar"
      style={style}
    >
      <CloseIcon
        onClick={closeSidebar}
        className="absolute left-[2%] top-[5%] bg-[#f0f8ff] z-2 text-[2.5rem]"
      />
      <div className="flex items-center justify-center py-2">
        <img src={pitchHereLogo} alt="logo" width={200} />
      </div>
      <nav className="sidebar-main">
        <div className="sidebar-inner">
          {role === 1 ? (
            <StartupSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : role === 2 ? (
            <MentorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : role === 3 ? (
            <InvestorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : (
            <VendorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          )}
        </div>
      </nav>
      <div className="absolute w-11/12 left-2/4 right-2/4 -translate-x-2/4 bottom-12 mb-2 text-center">
        <p
          onClick={() => setOpen(!open)}
          className="py-2.5 px-4 text-sm bg-customOrange text-white rounded-md cursor-pointer"
        >
          Raise Support Ticket
        </p>
      </div>
      <Link
        to="/"
        className="absolute w-11/12 left-2/4 right-2/4 -translate-x-2/4 bottom-0 mb-2 text-center"
      >
        <p className="py-2.5 px-4 text-sm bg-primary text-white rounded-md cursor-pointer">
          Log Out
        </p>
      </Link>
      <TicketModal
        open={open}
        onClose={() => setOpen(false)}
        supportTicket={supportTicket}
      />
    </div>
  );
};
