import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom, setChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";
import { Button } from "../../components/ui/Button";
import NavigateToDashboard from "../../utils/navigateToDashboard";
import { getCurrentDate } from "../../utils/getCurrentDate";
import { BuildingIcon, GlobeIcon, RedirectArrowIcon } from "../../icons/icons";
import FavIcon from "../../images/favicon.png";

const ViewVendorProfile = () => {
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}vendor-profile`;
  const connectApi = `${process.env.REACT_APP_API_URL}connect`;
  const getRoomIdApi = `${process.env.REACT_APP_API_URL}get-roomid`;

  const [openSidebar, setOpenSidebar] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const [openRoleModal, setOpenRoleModal] = useState(false);

  const { token, userRole } = useLocalStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, error, fetchApi } = useFetch();
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const vendorDetails = useCallback(() => {
    fetchApi(
      {
        url: vendorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          user_id: user_id,
        },
      },
      (data) => {
        if (data.message === "suspended") {
          alert("This accound has been suspended/Deactivated.");
          navigate(NavigateToDashboard(userRole));
        }
        setVendorData({
          documents: data.documents,
          details: { ...data.vendor_details[0] },
          website: data.website_url,
        });
      }
    );
  }, [vendorDetailsApi, fetchApi, token, user_id, navigate, userRole]);

  useEffect(() => {
    const timer = setTimeout(() => {
      vendorDetails();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [vendorDetails, userRole, navigate]);

  const getRoomId = useCallback(() => {
    fetchApi(
      {
        url: getRoomIdApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
          role: "V&iR8",
        },
      },
      (data) => {
        dispatch(deleteChatRoom());
        dispatch(
          setChatRoom({
            roomId: data.room_id,
            recieverId: data.connection_id,
            status: "1",
          })
        );
      }
    );
  }, [getRoomIdApi, fetchApi, token, dispatch, user_id]);

  const connect = useCallback(() => {
    fetchApi(
      {
        url: connectApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
          date: getCurrentDate(),
        },
      },
      (data) => {
        if (data.message === "connection already established") {
          getRoomId();
          navigate("/chat");
        } else {
          dispatch(deleteChatRoom());
          dispatch(
            setChatRoom({
              roomId: data.room_id,
              recieverId: user_id,
              status: "1",
            })
          );
          navigate("/chat");
        }
      }
    );
  }, [fetchApi, token, navigate, dispatch, connectApi, getRoomId, user_id]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="p-3">
            <Error />
          </div>
        )}
        {!isLoading && !error && Object.keys(vendorData).length && (
          <section className="grid grid-cols-7 gap-3 m-5">
            <div className="col-span-7 md:col-span-2 p-3 bg-white shadow-md rounded-md">
              {location.state?.active && (
                <Button
                  onClick={() => connect()}
                  className="md:hidden float-right p-1 text-sm"
                >
                  Invite
                </Button>
              )}
              <div className="flex flex-col">
                <img
                  src={
                    vendorData.details.logo.length > 0
                      ? vendorData.details.logo
                      : FavIcon
                  }
                  alt="vendor-img"
                  className="rounded-full h-[90px] w-[90px] md:h-[120px] md:w-[120px] border border-slate-400 p-1"
                />
                <p className="font-medium text-lg mt-2 uppercase">
                  {vendorData.details.name}
                </p>
              </div>
              <div className="flex items-center mb-2">
                <BuildingIcon fill="#999999" />
                <p className="text-sm mb-0 ms-3 capitalize">
                  <span className="me-1 font-bold">Company :</span>
                  {vendorData.details.company_name}
                </p>
              </div>
              <div className="flex items-center mb-2">
                <GlobeIcon fill="#999999" />
                <p className="text-sm mb-0 ms-3">
                  <span className="me-1 font-bold">Website :</span>
                  {vendorData.website.length > 0 ? (
                    <a
                      href={vendorData.website}
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm mb-0 ms-1 text-blue-500"
                    >
                      URL
                    </a>
                  ) : (
                    <span className="text-sm mb-0 ms-3 text-secondary">
                      N/A
                    </span>
                  )}
                </p>
              </div>
              <div className="border-t py-2">
                <p className="mb-0 font-bold">Industries</p>
                {vendorData.details.expert_in.length > 0 ? (
                  vendorData.details.expert_in.map((tag, i) => (
                    <span key={i} className="outline-tags">
                      {tag.replace(new RegExp("_", "g"), " ")}
                    </span>
                  ))
                ) : (
                  <p>Not available</p>
                )}
              </div>
            </div>
            <div className="col-span-7 md:col-span-5 p-3 bg-white shadow-md rounded-md">
              {location.state?.active && (
                <Button
                  onClick={() => connect()}
                  className="hidden md:inline-block button float-right p-1 text-sm hover:bg-green-600"
                >
                  Invite
                </Button>
              )}
              <div className="mt-2">
                <h4 className="font-medium mb-2">Bio</h4>
                <p>{vendorData.details.description}</p>
              </div>
              <div>
                <h4 className="font-medium mt-5 mb-2">Portfolio</h4>
                <div className="grid grid-cols-2 gap-2">
                  {vendorData.documents.length > 0 ? (
                    vendorData.documents.map((data) => (
                      <a
                        key={data._id}
                        href={data.path}
                        target="_blank"
                        rel="noreferrer"
                        className="col-span-2 md:col-span-1 flex items-center justify-between p-2 bg-gray-200 rounded"
                      >
                        <span>{data.file_name}</span>
                        <RedirectArrowIcon />
                      </a>
                    ))
                  ) : (
                    <p>Portfolio not updated</p>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <RatingModal
        open={openRoleModal}
        onClose={() => setOpenRoleModal(false)}
        role="vendor"
        id={user_id}
      />
    </div>
  );
};

export default ViewVendorProfile;
