export const roleOptions = [
  { value: "1", label: "Startup Founder/Co-Founder" },
  { value: "2", label: "Mentor" },
  { value: "3", label: "Investor" },
  { value: "4", label: "Service Provider" },
];

export const urlRegex =
  /[-a-zA-Z0-9@:%_\+.~#?&=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;

export const numberRegex = /^[0-9]+$/;

export const numberWithDecimalRegex = /^\d+(\.\d+)?$/;

export const textWithSpaceRegex = /^[a-zA-z]+([\s][a-zA-Z]+)*$/;

export const alphanumericRegex = /^[a-zA-Z0-9]+$/;

export const bannedInputs = [
  "pitchhere",
  "PitchHere",
  "Pitchhere",
  "admin",
  "example",
  "test",
];

export const imgSize = 500 * 1024; //500Kb

export const fileSize = 2 * 1024 * 1024; //2MB

export const videoSize = 2 * 1024 * 1024; //2MB
