import { Link } from "react-router-dom";
import { Button } from "../../../../components/ui/Button";
import { capitalizeText } from "../../../../utils/capitalizeText";
import {
  CalenderIcon,
  CoinsIcon,
  FolderIcon,
  LocationIcon,
} from "../../../../icons/icons";
import FavIcon from "../../../../images/favicon.png";

const InvestorDetails = ({ details, handleConfirmationModal }) => {
  return (
    <div className="grid grid-cols-6 gap-2">
      {details?.deactivate === "1" && details?.deactive_reason?.length > 0 && (
        <div className="col-span-6 md:hidden bg-slate-200 shadow-md rounded-md py-2 px-3">
          <h5 className="font-semibold pb-2 text-secondary">
            Deactivation Reason :
          </h5>
          <p className="font-medium">{details?.deactive_reason}</p>
        </div>
      )}
      <div className="col-span-6 md:col-span-2">
        <div className="bg-white shadow-md rounded-md p-3 text-center">
          <div className="h-[90px] w-[90px] lg:h-[140px] lg:w-[140px] flex items-center border rounded-full">
            <img
              src={details?.logo?.length < 1 ? FavIcon : details?.logo}
              className="max-h-[90px] lg:max-h-[140px] p-1 mx-auto rounded-full"
              alt="profile-img"
            />
            {details?.is_suspend === "0" ? (
              <Button
                className="md:hidden px-2 py-1 mx-1 text-xs"
                bgColor="bg-secondary"
                onClick={() => handleConfirmationModal("suspend")}
              >
                Suspend
              </Button>
            ) : (
              <Button
                className="md:hidden px-2 py-1 mx-1 text-xs"
                bgColor="bg-green-500"
                onClick={() => handleConfirmationModal("unsuspend")}
              >
                Un-Suspend
              </Button>
            )}
          </div>
          <div className="text-start">
            <p className="flex items-center font-bold text-lg pt-2 mb-0 uppercase">
              {details.name}
            </p>
            <div className="flex items-center mb-2">
              <CalenderIcon />
              <p className="text-sm mb-0 ms-3">
                <span className="font-bold me-1">Joined On :</span>
                {details?.details?.date}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <CoinsIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="font-bold me-1">Total Investment :</span>
                {details?.details?.invested_amount}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <CoinsIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="font-bold me-1">Investment Target :</span>
                {details?.details?.future_investment_amount}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <FolderIcon />
              <p className="text-sm mb-0 ms-3">
                <span className="font-bold me-1">Companies in portfolio :</span>{" "}
                {details?.details?.companies_invested_count}
              </p>
            </div>
            <div className="flex mb-4">
              <LocationIcon fill="#9c9c9c" />
              <div className="flex items-center text-sm mb-0 ms-3">
                <span className="font-bold me-1">Address :</span>
                <div className="flex items-center">
                  <span className="me-1">{details.city}</span>
                  <img
                    src={
                      details?.country_code
                        ? `https://flagsapi.com/${details?.country_code}/flat/64.png`
                        : "https://flagsapi.com/IN/flat/64.png"
                    }
                    alt={`${details?.country} flag`}
                    width={18}
                    title={capitalizeText(details?.country)}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="border-t py-2">
              <p className="mb-0 font-bold">Industries</p>
              {details?.details?.investor_industries?.map((industry, i) => (
                <span key={i} className="outline-tags">
                  {industry.replace(new RegExp("_", "g"), " ")}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-6 md:col-span-4">
        {details?.deactivate === "1" &&
          details?.deactive_reason?.length > 0 && (
            <div className="hidden md:block bg-slate-200 shadow-md rounded-md py-2 px-3">
              <h5 className="font-semibold pb-2 text-secondary">
                Deactivation Reason :
              </h5>
              <p className="font-medium">{details?.deactive_reason}</p>
            </div>
          )}
        {details?.is_suspend === "0" ? (
          <Button
            className="hidden md:block ml-auto px-3 py-1 mx-1 text-xs"
            bgColor="bg-secondary"
            onClick={() => handleConfirmationModal("suspend")}
          >
            Suspend
          </Button>
        ) : (
          <Button
            className="hidden md:block ml-auto px-3 py-1 mx-1 text-xs"
            bgColor="bg-green-500"
            onClick={() => handleConfirmationModal("unsuspend")}
          >
            Un-Suspend
          </Button>
        )}
        {details?.details?.description?.length > 0 ? (
          <div className="h-[270px]">
            <div className="mt-4 p-3 pt-1 shadow-md rounded-md h-full overflow-auto">
              <h4 className="my-3 font-medium text-lg">Bio</h4>
              <p>{details?.details?.description}</p>
            </div>
          </div>
        ) : (
          <div className="rounded-md bg-white shadow-md mt-3 p-3">
            No Bio added
          </div>
        )}
        {details?.details?.prevInvestments?.length > 0 ? (
          <>
            <div className="p-2 rounded-md bg-white shadow mt-3 py-3">
              <h5 className="font-medium mb-3">
                Previously Invested Companies
              </h5>
              {details?.details?.prevInvestments?.map((investment) => (
                <div
                  key={investment._id}
                  className="flex items-center justify-between p-2 my-1 bg-gray-200 rounded"
                >
                  <div>
                    <span className="font-medium me-2">Company Name :</span>
                    <span>{investment.company_name}</span>
                  </div>
                  <Link
                    to={investment.company_url ? investment.company_url : "#"}
                    target={investment.company_url ? "_blank" : "_self"}
                    rel={
                      investment.company_url ? "noopener noreferrer" : undefined
                    }
                  >
                    <span className="font-medium me-2">Website Url :</span>
                    <span class={!investment.company_url ? "text-red-500" : ""}>
                      {investment.company_url ? investment.company_url : "NA"}
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="rounded-md bg-white shadow-md mt-3 p-3">
            No previous investments
          </div>
        )}
      </div>
    </div>
  );
};

export default InvestorDetails;
