import { Link } from "react-router-dom";
import "../../Pages/admin-portal/admin-dashboard/adminDashboard.css";
import {
  DashboardIcon,
  UserIcon,
  CameraIcon,
  RupeeIcon,
  ClipBoardIcon,
  IndustryIcon,
  CoinsIcon,
  PercentIcon,
  GearIcon,
  FileIcon,
} from "../../icons/icons";

const AdminSidebarMain = ({ currPath, activeStyle, regularStyle }) => {
  const sidebarText = "ps-2 text-sm tracking-normal font-medium";

  return (
    <ul className="p-3 pt-4">
      <Link
        to="/admin-dashboard"
        className={currPath === "/admin-dashboard" ? activeStyle : regularStyle}
      >
        <DashboardIcon
          fill={currPath === "/admin-dashboard" ? "#fff" : "#999999"}
        />
        <span className={sidebarText}>Dashboard</span>
      </Link>
      <Link
        to="/admin/users"
        className={currPath === "/admin/users" ? activeStyle : regularStyle}
      >
        <UserIcon fill={currPath === "/admin/users" ? "#fff" : "#999999"} />
        <span className={sidebarText}>Users</span>
      </Link>
      <Link
        to="/admin/user-images"
        className={
          currPath === "/admin/user-images" ? activeStyle : regularStyle
        }
      >
        <CameraIcon
          fill={currPath === "/admin/user-images" ? "#fff" : "#999999"}
        />
        <span className={sidebarText}>User Images</span>
      </Link>
      <Link
        to="/admin/withdraw-requests"
        className={
          currPath === "/admin/withdraw-requests" ? activeStyle : regularStyle
        }
      >
        <RupeeIcon
          fill={currPath === "/admin/withdraw-requests" ? "#fff" : "#999999"}
        />
        <span className={sidebarText}>Withdraw Requests</span>
      </Link>
      <Link
        to="/admin/complaint-tickets"
        className={
          currPath === "/admin/complaint-tickets" ||
          currPath.startsWith("/admin/ticket/")
            ? activeStyle
            : regularStyle
        }
      >
        <ClipBoardIcon
          fill={
            currPath === "/admin/complaint-tickets" ||
            currPath.startsWith("/admin/ticket/")
              ? "#fff"
              : "#999999"
          }
        />
        <span className={sidebarText}>Tickets</span>
      </Link>
      <Link
        to="/admin/investments"
        className={
          currPath === "/admin/investments" ? activeStyle : regularStyle
        }
      >
        <RupeeIcon
          fill={currPath === "/admin/investments" ? "#fff" : "#999999"}
        />
        <span className={sidebarText}>Investments</span>
      </Link>
      <Link
        to="/admin/store"
        className={currPath === "/admin/store" ? activeStyle : regularStyle}
      >
        <CoinsIcon fill={currPath === "/admin/store" ? "#fff" : "#999999"} />
        <span className={sidebarText}>Token Store</span>
      </Link>
      <Link
        to="/admin/industries"
        className={
          currPath === "/admin/industries" ? activeStyle : regularStyle
        }
      >
        <IndustryIcon
          fill={currPath === "/admin/industries" ? "#fff" : "#999999"}
        />
        <span className={sidebarText}>Industries</span>
      </Link>
      <Link
        to="/admin/platform-fees"
        className={
          currPath === "/admin/platform-fees" ? activeStyle : regularStyle
        }
      >
        <PercentIcon
          fill={currPath === "/admin/platform-fees" ? "#fff" : "#999999"}
        />
        <span className={sidebarText}>Platform Fees</span>
      </Link>
      <Link
        to="/admin/templates"
        className={currPath === "/admin/templates" ? activeStyle : regularStyle}
      >
        <FileIcon fill={currPath === "/admin/templates" ? "#fff" : "#999999"} />
        <span className={sidebarText}>Sample Templates</span>
      </Link>
      <Link
        to="/admin/settings"
        className={currPath === "/admin/settings" ? activeStyle : regularStyle}
      >
        <GearIcon fill={currPath === "/admin/settings" ? "#fff" : "#999999"} />
        <span className={sidebarText}>Settings</span>
      </Link>
    </ul>
  );
};

export default AdminSidebarMain;
