import { Link } from "react-router-dom";
import AdminSidebarMain from "./adminSidebarMain";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import { CloseIcon } from "../../icons/icons";

const ResponsiveAdminSidebar = ({ closeSidebar }) => {
  const currPath = window.location.pathname;
  const activeStyle =
    "py-2.5 my-1 flex items-center px-2 bg-primary text-white hover:text-white rounded-md cursor-pointer";
  const regularStyle =
    "py-2.5 my-1 flex items-center px-2 rounded-md cursor-pointer";

  return (
    <div
      className="responsive h-full bg-[#f0f8ff] text-left shadow-lg"
      id="sidebar"
    >
      <CloseIcon
        onClick={closeSidebar}
        className="absolute left-[2%] md:-left-[5%] top-[5%] bg-[#f0f8ff] z-2 text-[2.5rem]"
      />
      <div className="flex items-center justify-center py-2">
        <img src={pitchHereLogo} alt="logo" width={200} />
      </div>
      <nav className="sidebar-main">
        <div className="sidebar-inner">
          <AdminSidebarMain
            currPath={currPath}
            activeStyle={activeStyle}
            regularStyle={regularStyle}
          />
        </div>
      </nav>
      <Link to="/" className="absolute w-full bottom-0 mb-2 text-center">
        <span className="w-11/12 py-2.5 px-4 text-sm bg-customOrange text-white rounded-md cursor-pointer">
          Log Out
        </span>
      </Link>
    </div>
  );
};

export default ResponsiveAdminSidebar;
