import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import TextEditor from "../../components/ui/text-editor/TextEditor";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { getCurrentDate } from "../../utils/getCurrentDate";

const MeetingSummary = () => {
  const meetingSummaryApi = `${process.env.REACT_APP_API_URL}meeting-summary`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { meetingId } = useParams();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [openSidebar, setOpenSidebar] = useState(false);

  const submitSummary = useCallback(
    (data) => {
      fetchApi(
        {
          url: meetingSummaryApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            summary: data,
            id: meetingId,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "summary exist") {
            toast.open("warning", "Meeting summary exists");
            return;
          }
          navigate("/meetings");
        }
      );
    },
    [meetingSummaryApi, fetchApi, token, meetingId, navigate, toast]
  );

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-4">
          <h3 className="font-semibold text-xl mb-2">Enter Meeting Summary</h3>
          <div className="font-medium flex items-start text-xs">
            Note:
            <p className="ml-2">
              <p className="text-xs">
                1. Please re-check the summary before saving. Summary is not
                editable after submission.
              </p>
              {userRole === "R$7s2" && (
                <p className="text-xs">
                  2. It is mandatory for mentors to submit a summary. Amount
                  will only be transfered to your account if summary has been
                  added.
                </p>
              )}
            </p>
          </div>
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <div className="py-5 md:px-5 flex flex-col items-center justify-center">
              <TextEditor submitSummary={submitSummary} />
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default MeetingSummary;
