import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useNotificationApi from "../../hooks/useNotificationApi";
import useCheckConnection from "../../hooks/useCheckConnection";
import useUnfollowConnection from "../../hooks/useUnfollowConnection";
import useVerificationApi from "../../hooks/useVerificationApi";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import { useToastContext } from "../../context/ToastContext";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { InvestmentModal } from "../../components/ui/modals/investment/investmentModal";
import { ConfirmationModal } from "../../components/ui/modals/utility-modals/confirmationModal";
import { Stars } from "../../components/ui/modals/rating-modal/Stars";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";
import NavigateToDashboard from "../../utils/navigateToDashboard";
import { Button } from "../../components/ui/Button";
import { getCurrentDate } from "../../utils/getCurrentDate";
import { capitalizeText } from "../../utils/capitalizeText";
import "./viewProfile.css";
import "./Profile.css";
import {
  LinkedInIcon,
  LocationIcon,
  RedirectArrowIcon,
  RedirectIcon,
  RupeeIcon,
  UserIcon,
  UserTieIcon,
} from "../../icons/icons";
import FavIcon from "../../images/favicon.png";
import certifiedLogo from "../../images/certified.png";
import verifiedLogo from "../../images/verified.png";
import { randomId } from "../../utils/randomId";

const ViewStartupProfile = () => {
  const startupDetailsApi = `${process.env.REACT_APP_API_URL}display-startup-profile`;
  const reviewsCountApi = `${process.env.REACT_APP_API_URL}reviews-count`;
  const verifyStartupApi = `${process.env.REACT_APP_API_URL}verify-startup`;
  const sendConnectionRequestApi = `${process.env.REACT_APP_API_URL}send-connect-request`;
  const acceptOrCancelApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;
  const blockApi = `${process.env.REACT_APP_API_URL}block`;
  const unBlockApi = `${process.env.REACT_APP_API_URL}unblock`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const { checkConnection } = useCheckConnection();
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole, name } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const { unFollow } = useUnfollowConnection();
  const { verification } = useVerificationApi();
  const { displayFiles } = useDisplayFiles();
  const toast = useToastContext();

  const ref = useRef(true);
  const [startupDetails, setStartupDetails] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState({
    status: "",
    senderId: "",
  });
  const [startupBasic, setStartupBasic] = useState({
    id: null,
    company: "",
  });
  const [rating, setRating] = useState(0);
  const [profileImg, setProfileImg] = useState("");
  const [open, setOpen] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({
    certified: false,
    verified: false,
  });
  const [openSidebar, setOpenSidebar] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    callback: null,
    message: "",
  });
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(false);
  const [files, setFiles] = useState({
    documents: [],
    msme: [],
    gst: [],
    incorporation: [],
    others: [],
    video: "",
    reviewCount: 0,
  });

  const getStartupDetails = useCallback(async () => {
    if (!user_id) {
      toast.open("false", "Sorry, something went wrong. Please try again");
      return;
    }
    fetchApi(
      {
        url: startupDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
          date: getCurrentDate(),
        },
      },
      (data) => {
        if (data.message === "suspended") {
          toast.open("warning", "This accound has been Suspended/Deactivated");
          navigate(NavigateToDashboard(userRole));
        }
        setStartupDetails(data.startup_profile);
        setStartupBasic({
          id: data.startup_profile[0].user_id,
          company: data.startup_profile[0].company_name,
        });
        setRating(data.startup_profile[0].average_rating);
        setProfileImg(data.startup_profile[0].logo);
        setVerificationStatus({
          certified:
            data.startup_profile[0].verify_profile === "1" ? true : false,
          verified:
            data.startup_profile[0].platform_verify === "1" ? true : false,
        });
      }
    );
  }, [user_id, startupDetailsApi, token, fetchApi, navigate, userRole, toast]);

  const reviewsCount = useCallback(() => {
    fetchApi(
      {
        url: reviewsCountApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          user_id: user_id,
        },
      },
      (data) => {
        setFiles((prev) => ({ ...prev, reviewCount: data.review_count }));
      }
    );
  }, [reviewsCountApi, fetchApi, token, user_id]);

  const sendConnectionRequest = useCallback(() => {
    fetchApi(
      {
        url: sendConnectionRequestApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: user_id,
          date: getCurrentDate(),
        },
      },
      (data) => {
        toast.open("true", "Connection request sent successfully!");
        checkConnection(user_id, setConnectionStatus);
        sendNotification(
          user_id,
          `Incoming connection request from ${
            userRole === "R$7s2" ? "mentor" : "investor"
          } ${data.name}`,
          "connections"
        );
      }
    );
  }, [
    sendConnectionRequestApi,
    fetchApi,
    token,
    user_id,
    sendNotification,
    toast,
    checkConnection,
    userRole,
  ]);

  const acceptOrCancelReq = useCallback(
    (type) => {
      fetchApi(
        {
          url: acceptOrCancelApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: user_id,
            type: type,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "accepted") {
            toast.open("true", "Connection request accepted!");
            setConnectionStatus((prev) => ({ ...prev, status: "connected" }));
          }
          if (data.message === "already cancelled") {
            toast.open("warning", "Sorry, Request was already cancelled");
            setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
          }
          if (data.message === "cancelled") {
            toast.open("true", "Connection request cancelled successfully");
            setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
            sendNotification(
              user_id,
              `Connection cancelled from ${data.name}`,
              "connections"
            );
          }
          if (data.message === "connected") {
            toast.open("warning", "User has already accepted this request");
            setConnectionStatus((prev) => ({ ...prev, status: "connected" }));
          }
        }
      );
    },
    [fetchApi, token, acceptOrCancelApi, toast, sendNotification, user_id]
  );

  const verifyStartup = useCallback(() => {
    fetchApi(
      {
        url: verifyStartupApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          startup_id: user_id,
          date: getCurrentDate(),
        },
      },
      (data) => {
        setVerificationStatus((prev) => ({ ...prev, certified: true }));
        sendNotification(
          user_id,
          `Your profile has been verified by mentor ${data.name}`,
          "certified"
        );
        toast.open("true", "Certified successfully!");
        verification(
          user_id,
          userRole === "R$7s2" ? "verify" : "invested",
          setReviewStatus
        );
      }
    );
  }, [
    fetchApi,
    token,
    toast,
    sendNotification,
    verifyStartupApi,
    userRole,
    user_id,
    verification,
  ]);

  const block = useCallback(() => {
    fetchApi(
      {
        url: blockApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
          date: getCurrentDate(),
        },
      },
      (data) => {
        setConnectionStatus((prev) => ({ ...prev, status: "blocked" }));
        sendNotification(
          user_id,
          `You have been blocked by ${data.name}`,
          "connections"
        );
        toast.open("true", "Connection blocked successfully!");
        dispatch(deleteChatRoom());
      }
    );
  }, [blockApi, fetchApi, token, dispatch, sendNotification, toast, user_id]);

  const unBlock = useCallback(() => {
    fetchApi(
      {
        url: unBlockApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
          date: getCurrentDate(),
        },
      },
      () => {
        checkConnection(user_id, setConnectionStatus);
        sendNotification(user_id, `${name} has un-blocked you`, "connections");
        toast.open("true", "Connection un-blocked successfully!");
        dispatch(deleteChatRoom());
      }
    );
  }, [
    unBlockApi,
    fetchApi,
    token,
    dispatch,
    name,
    sendNotification,
    toast,
    user_id,
    checkConnection,
  ]);

  const postFilesApi = (data) => {
    setFiles((prev) => ({
      ...prev,
      documents: data.documents,
      msme: data.msme,
      gst: data.gst,
      incorporation: data.incorporation,
      others: data.others,
      video: data.video,
    }));
  };

  useEffect(() => {
    if (userRole === "f@!3A") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getStartupDetails();
      verification(
        user_id,
        userRole === "R$7s2" ? "verify" : "invested",
        setReviewStatus
      );
      displayFiles(postFilesApi, user_id);
      checkConnection(user_id, setConnectionStatus);
      reviewsCount();
    }

    return () => {
      ref.current = false;
    };
  }, [
    navigate,
    userRole,
    getStartupDetails,
    verification,
    displayFiles,
    checkConnection,
    reviewsCount,
    user_id,
  ]);

  const verifyConnection = () => {
    checkConnection(user_id, setConnectionStatus);
    if (!isLoading) {
      setOpen(true);
    }
  };

  const routeToChat = () => {
    dispatch(deleteChatRoom());
    navigate("/chat");
  };

  const handleConfirmationModal = (type) => {
    if (type === "unfollow") {
      setConfirmationModal({
        open: true,
        callback: () => unFollow(user_id, setConnectionStatus),
        message: "Are you sure you want to disconnect?",
      });
    } else if (type === "connect") {
      setConfirmationModal({
        open: true,
        callback: () => sendConnectionRequest(),
        message: "Are you sure you want to connect with this startup?",
      });
    } else if (type === "certify") {
      setConfirmationModal({
        open: true,
        callback: () => verifyStartup(),
        message: "Are you sure you want to certify this startup?",
      });
    } else if (type === "block") {
      setConfirmationModal({
        open: true,
        callback: () => block(),
        message: "Are you sure you want to block this startup?",
      });
    } else {
      setConfirmationModal({
        open: true,
        callback: () => unBlock(),
        message: "Are you sure you want to un-block this startup?",
      });
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-3 md:p-4">
          {error && <Error />}
          {isLoading && !error ? (
            <Loader />
          ) : (
            <>
              <div className="grid grid-cols-8 gap-3">
                {userRole !== "V&iR8" && (
                  <div className="col-span-8 ml-auto md:hidden">
                    <div className="flex items-center flex-wrap gap-1">
                      {connectionStatus.status === "connected" && (
                        <>
                          {reviewStatus && (
                            <Button
                              className="px-2 py-1 text-xs"
                              bgColor="bg-[#F97316]"
                              onClick={() => setOpenReviewModal(true)}
                            >
                              Review
                            </Button>
                          )}
                          {!reviewStatus && userRole === "R$7s2" && (
                            <Button
                              className="px-2 py-1 text-xs"
                              bgColor="bg-green-600"
                              onClick={() => handleConfirmationModal("certify")}
                            >
                              Certify
                            </Button>
                          )}
                          {userRole === "R$7s2" && (
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              bgColor="bg-secondary"
                              onClick={() => handleConfirmationModal("block")}
                            >
                              Block
                            </Button>
                          )}
                          {userRole === "Sw%aa" && (
                            <>
                              <Button
                                className="px-2 py-1 text-xs"
                                bgColor="bg-green-600"
                                onClick={() => verifyConnection()}
                              >
                                Invest
                              </Button>
                              <Button
                                className="px-2 py-1 text-xs"
                                bgColor="bg-secondary"
                                onClick={() =>
                                  handleConfirmationModal("unfollow")
                                }
                              >
                                Disconnect
                              </Button>
                            </>
                          )}
                          <Button
                            className="px-2 py-1 text-xs"
                            onClick={() => routeToChat()}
                          >
                            Message
                          </Button>
                        </>
                      )}
                      {connectionStatus.status === "disconnect" && (
                        <Button
                          className="px-2 py-1 text-xs"
                          onClick={() => handleConfirmationModal("connect")}
                        >
                          Connect
                        </Button>
                      )}
                      {connectionStatus.status === "pending" && (
                        <>
                          <Button
                            className="px-2 py-1 text-xs cursor-not-allowed disabled"
                            disabled
                            bgColor="bg-gray-400"
                          >
                            Awaiting Acceptance
                          </Button>
                          {connectionStatus.senderId === user_id ? (
                            <>
                              <Button
                                className="px-2 py-1 text-xs"
                                bgColor="bg-green-700"
                                onClick={() => acceptOrCancelReq("accept")}
                              >
                                Accept
                              </Button>
                              <Button
                                className="px-2 py-1 text-xs"
                                bgColor="bg-orange-700"
                                onClick={() => acceptOrCancelReq("cancel")}
                              >
                                Decline
                              </Button>
                            </>
                          ) : (
                            <Button
                              className="px-2 py-1 text-xs"
                              bgColor="bg-orange-700"
                              onClick={() => acceptOrCancelReq("cancel")}
                            >
                              Cancel Request
                            </Button>
                          )}
                        </>
                      )}
                      {connectionStatus.status === "blocked" && (
                        <Button
                          className="px-2 py-1 mx-1 text-xs"
                          bgColor="bg-green-700"
                          onClick={() => handleConfirmationModal("unblock")}
                        >
                          Un-Block
                        </Button>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-span-8 md:col-span-3 bg-white shadow-md rounded-md p-3 text-center">
                  <div className="flex flex-col justify-center items-end md:hidden">
                    <div className="flex items-center">
                      <Stars rating={rating} size={12} />
                    </div>
                    <div className="flex items-center">
                      <span className="px-1 md:px-2">
                        ({files.reviewCount})
                      </span>
                      <Link
                        to="/reviews"
                        state={{
                          user_id: user_id,
                          role: "f@!3A",
                        }}
                        className="ps-0 text-blue-400 text-sm"
                      >
                        Reviews
                      </Link>
                    </div>
                  </div>
                  <div className="lg:mt-5 lg:mx-auto h-[120px] w-[120px] flex items-center rounded-full border border-slate-400">
                    <img
                      src={profileImg.length < 1 ? FavIcon : profileImg}
                      className="rounded-full h-[120px] lg:mx-auto p-1"
                      alt="profile-img"
                      width={120}
                    />
                  </div>
                  {startupDetails.map((startup) => (
                    <div className="text-start" key={startup.user_id}>
                      <div className="font-bold text-lg pt-2 mb-0 flex items-center uppercase">
                        {startup.company_name}{" "}
                      </div>
                      <div className="flex items-center gap-x-3 mb-3">
                        {verificationStatus.certified && (
                          <img
                            src={certifiedLogo}
                            alt="Certified by Mentor"
                            title="Certified by Mentor"
                            width={20}
                          />
                        )}
                        {verificationStatus.verified && (
                          <img
                            src={verifiedLogo}
                            alt="Platform Verified Logo"
                            title="Platform Verified"
                            width={25}
                          />
                        )}
                      </div>
                      <div className="flex items-center mb-2">
                        <UserIcon fill="#999999" />
                        <p className="text-sm mb-0 ms-3">
                          <span className="me-1 font-semibold">Name :</span>
                          {startup.name}
                        </p>
                      </div>
                      {userRole === "Sw%aa" && (
                        <div className="flex items-center mb-2">
                          <LinkedInIcon fill="#999999" />
                          <p className="text-sm mb-0 ms-3">
                            <span className="me-1 font-semibold">
                              Linkedin :
                            </span>
                            {startup.linked_in ? (
                              <a
                                href={startup.linked_in}
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-500"
                              >
                                Profile
                              </a>
                            ) : (
                              <span className="text-secondary">N/A</span>
                            )}
                          </p>
                        </div>
                      )}
                      <div className="flex items-center mb-2">
                        <RupeeIcon fill="#999999" />
                        <p className="text-sm mb-0 ms-3">
                          <span className="me-1 font-semibold">
                            Desired Investment :
                          </span>
                          {startup.require_investment}
                        </p>
                      </div>
                      <div className="flex items-center mb-2">
                        <RedirectIcon fill="#999999" />
                        <p className="text-sm mb-0 ms-3">
                          <span className="me-1 font-semibold">Website :</span>
                          {startup.website.length > 0 ? (
                            <a
                              href={startup.website}
                              target="_blank"
                              rel="noreferrer"
                              className="text-xs mb-0 text-blue-400"
                            >
                              URL
                            </a>
                          ) : (
                            <span className="text-secondary text-sm">N/A</span>
                          )}
                        </p>
                      </div>
                      <div className="flex items-center mb-2">
                        <UserTieIcon fill="#999999" />
                        <p className="text-sm mb-0 ms-3">
                          <span className="me-1 font-semibold">
                            Company Size :{" "}
                            <span className="font-normal">
                              {startup.no_of_employees || (
                                <span className="text-secondary text-sm">
                                  N/A
                                </span>
                              )}
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className="flex items-center mb-2">
                        <LocationIcon fill="#999999" />
                        <div className="flex items-center text-sm mb-0 ms-3 font-semibold">
                          Address :{" "}
                          <div className="flex items-center mb-0 ms-1">
                            <span className="me-1 font-normal capitalize">
                              {startup.city}
                            </span>
                            <img
                              src={
                                startup.country_code
                                  ? `https://flagsapi.com/${startup.country_code}/flat/64.png`
                                  : "https://flagsapi.com/IN/flat/64.png"
                              }
                              alt={`${startup.country} flag`}
                              width={18}
                              title={capitalizeText(startup.country)}
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border-t py-2">
                        <p className="mb-0 font-semibold">Industries</p>
                        {startup.startup_industry.map((industry, i) => (
                          <span key={i} className="outline-tags">
                            {industry.replace(new RegExp("_", "g"), " ")}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="hidden md:block col-span-5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex my-3">
                        <Stars rating={rating} />
                      </div>
                      <span className="px-2">({files.reviewCount})</span>
                      <Link
                        to="/reviews"
                        state={{
                          user_id: user_id,
                          role: "f@!3A",
                        }}
                        className="ps-0 text-blue-400"
                      >
                        Reviews
                      </Link>
                    </div>
                    {userRole !== "V&iR8" && (
                      <div>
                        {connectionStatus.status === "connected" && (
                          <>
                            {reviewStatus && (
                              <Button
                                className="px-2 py-1 mx-1 text-xs"
                                bgColor="bg-[#F97316]"
                                onClick={() => setOpenReviewModal(true)}
                              >
                                Review
                              </Button>
                            )}
                            {!reviewStatus && userRole === "R$7s2" && (
                              <Button
                                className="px-2 py-1 mx-1 text-xs"
                                bgColor="bg-green-600"
                                onClick={() =>
                                  handleConfirmationModal("certify")
                                }
                              >
                                Certify
                              </Button>
                            )}
                            {userRole === "R$7s2" && (
                              <Button
                                className="px-2 py-1 mx-1 text-xs"
                                bgColor="bg-secondary"
                                onClick={() => handleConfirmationModal("block")}
                              >
                                Block
                              </Button>
                            )}
                            {userRole === "Sw%aa" && (
                              <>
                                <Button
                                  className="px-2 py-1 mx-1 text-xs"
                                  bgColor="bg-green-600"
                                  onClick={() => verifyConnection()}
                                >
                                  Invest
                                </Button>
                                <Button
                                  className="px-2 py-1 mx-1 text-xs"
                                  bgColor="bg-secondary"
                                  onClick={() =>
                                    handleConfirmationModal("unfollow")
                                  }
                                >
                                  Disconnect
                                </Button>
                              </>
                            )}
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              onClick={() => routeToChat()}
                            >
                              Message
                            </Button>
                          </>
                        )}
                        {connectionStatus.status === "disconnect" && (
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            onClick={() => handleConfirmationModal("connect")}
                          >
                            Connect
                          </Button>
                        )}
                        {connectionStatus.status === "pending" && (
                          <>
                            <Button
                              className="px-2 py-1 mx-1 text-xs cursor-not-allowed disabled"
                              disabled
                              bgColor="bg-gray-400"
                            >
                              Awaiting Acceptance
                            </Button>
                            {connectionStatus.senderId === user_id ? (
                              <>
                                <Button
                                  className="px-2 py-1 mx-1 text-xs"
                                  bgColor="bg-green-700"
                                  onClick={() => acceptOrCancelReq("accept")}
                                >
                                  Accept
                                </Button>
                                <Button
                                  className="px-2 py-1 mx-1 text-xs"
                                  bgColor="bg-orange-700"
                                  onClick={() => acceptOrCancelReq("cancel")}
                                >
                                  Decline
                                </Button>
                              </>
                            ) : (
                              <Button
                                className="px-2 py-1 mx-1 text-xs"
                                bgColor="bg-orange-700"
                                onClick={() => acceptOrCancelReq("cancel")}
                              >
                                Cancel Request
                              </Button>
                            )}
                          </>
                        )}
                        {connectionStatus.status === "blocked" && (
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            bgColor="bg-green-700"
                            onClick={() => handleConfirmationModal("unblock")}
                          >
                            Un-Block
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-1 gap-3">
                    <div className="px-0" style={{ height: "270px" }}>
                      <div
                        className="bg-white shadow-md rounded-md p-3"
                        style={{
                          height: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <h5 className="font-medium pb-3">Startup Idea</h5>
                        <p className="mb-0 text-sm">
                          {startupDetails.length > 0
                            ? startupDetails[0].description.length
                              ? startupDetails[0].description
                              : "Not updated."
                            : "Not updated."}
                        </p>
                      </div>
                    </div>
                    <div className="pe-0">
                      <div className="bg-white shadow-md rounded-md p-3">
                        {files.video.length > 0 ? (
                          <>
                            <h5 className="font-medium pb-7">
                              Video About Startup
                            </h5>
                            <video
                              controls
                              className="w-full"
                              id="videoFile"
                              src={files.video}
                              style={{ height: "200px" }}
                            >
                              Video uploaded
                            </video>
                          </>
                        ) : (
                          <p>No Video uploaded.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-3">
                {startupDetails.map((startup) => (
                  <div
                    className="col-span-2 md:col-span-1 px-0"
                    key={startup.user_id}
                  >
                    <div
                      className="bg-white shadow-md rounded-md p-3"
                      style={{
                        height: "100%",
                        overflowY: "auto",
                      }}
                    >
                      <h5 className="pb-3 font-medium">Business Overview</h5>
                      <div className="grid grid-cols-2">
                        <div className="flex flex-col items-start mb-1">
                          <p className="mb-0 font-bold text-sm">
                            Startup Stage
                          </p>
                          <p className="text-sm capitalize">
                            {startup.startup_stage.replace("-", " ") || "N/A"}
                          </p>
                        </div>
                        <div className="flex flex-col items-start mb-1">
                          <p className="mb-0 font-bold text-sm">
                            Previous Investment
                          </p>
                          <p className="text-sm capitalize">
                            {startup.previous_investment || "N/A"}
                          </p>
                        </div>
                        <div className="flex flex-col items-start mb-1">
                          <p className="mb-0 font-bold text-sm">
                            Invested Amount
                          </p>
                          <p className="text-sm">
                            {startup.investment_amount || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="md:hidden col-span-2">
                  <div className="px-0 mb-3" style={{ height: "270px" }}>
                    <div
                      className="bg-white shadow-md rounded-md p-3"
                      style={{
                        height: "100%",
                        overflowY: "auto",
                      }}
                    >
                      <h5 className="font-medium pb-3">Startup Idea</h5>
                      <p className="mb-0 text-sm">
                        {startupDetails.length > 0
                          ? startupDetails[0].description.length
                            ? startupDetails[0].description
                            : "Not updated."
                          : "Not updated."}
                      </p>
                    </div>
                  </div>
                  <div className="pe-0">
                    <div className="bg-white shadow-md rounded-md p-3">
                      {files.video.length > 0 ? (
                        <>
                          <h5 className="font-medium pb-7">
                            Video About Startup
                          </h5>
                          <video
                            controls
                            className="w-full"
                            id="videoFile"
                            src={files.video}
                            style={{ height: "200px" }}
                          >
                            Video uploaded
                          </video>
                        </>
                      ) : (
                        <p>No Video uploaded.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1 px-0">
                  <div className="bg-white shadow-md rounded-md p-3 mb-2 h-100 overflow-y-auto">
                    <h5 className="font-medium pb-3">Pitch Deck</h5>
                    {files.documents.length > 0 &&
                      files.documents.map((document) => (
                        <a
                          href={document.path}
                          key={document._id}
                          target="_blank"
                          rel="noreferrer"
                          className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
                        >
                          <p>{document.file_name}</p>
                          <RedirectArrowIcon className="cursor-pointer" />
                        </a>
                      ))}
                    {!files.documents.length > 0 && (
                      <span>No Files uploaded</span>
                    )}
                  </div>
                  <div className="bg-white shadow-md rounded-md p-3 h-100 overflow-y-auto">
                    <h5 className="font-medium pb-3">
                      Documents & Certifications
                    </h5>
                    {files.msme.length > 0 && (
                      <a
                        href={files.msme[0].path}
                        target="_blank"
                        rel="noreferrer"
                        className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
                      >
                        <p className="uppercase">
                          {files.msme[0].document_name}
                        </p>
                        <RedirectArrowIcon className="cursor-pointer" />
                      </a>
                    )}
                    {files.gst.length > 0 && (
                      <a
                        href={files.gst[0].path}
                        target="_blank"
                        rel="noreferrer"
                        className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
                      >
                        <p className="uppercase">
                          {files.gst[0].document_name}
                        </p>
                        <RedirectArrowIcon className="cursor-pointer" />
                      </a>
                    )}
                    {files.incorporation.length > 0 && (
                      <a
                        href={files.incorporation[0].path}
                        target="_blank"
                        rel="noreferrer"
                        className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
                      >
                        <p className="uppercase">
                          {files.incorporation[0].document_name}
                        </p>
                        <RedirectArrowIcon className="cursor-pointer" />
                      </a>
                    )}
                    {files.others.length > 0 &&
                      files.others.map((file) => (
                        <a
                          href={file.path}
                          key={file._id}
                          target="_blank"
                          rel="noreferrer"
                          className="flex justify-between items-center my-1 p-2 rounded-md bg-gray-200"
                        >
                          <p className="capitalize">{file.document_name}</p>
                          <RedirectArrowIcon className="cursor-pointer" />
                        </a>
                      ))}
                    {!files.msme.length > 0 &&
                      !files.gst.length > 0 &&
                      !files.incorporation.length > 0 &&
                      !files.others.length > 0 && (
                        <span>No Files uploaded</span>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
        <InvestmentModal
          key={randomId()}
          open={open}
          onClose={() => setOpen(false)}
          startupBasic={startupBasic}
          connectionStatus={connectionStatus.status}
        />
        <RatingModal
          open={openReviewModal}
          onClose={() => setOpenReviewModal(false)}
          role="startup"
          id={user_id}
        />
        <ConfirmationModal
          open={confirmationModal.open}
          onClose={() =>
            setConfirmationModal((prev) => ({ ...prev, open: false }))
          }
          callBackFn={confirmationModal.callback}
          message={confirmationModal.message}
        />
      </div>
    </div>
  );
};

export default ViewStartupProfile;
