import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/videoContext";
import Preview from "./Preview";
import VideoCall from "./VideoCall";
import "./video.css";

const Session = () => {
  const navigate = useNavigate();
  const meetingArgs = useContext(UserContext);

  const [sessionState, setSessionState] = useState("preview");

  useEffect(() => {
    if (meetingArgs.videoSDKJWT === "") navigate("/meetings");
  }, [navigate, meetingArgs.videoSDKJWT]);

  if (sessionState === "preview") {
    return <Preview handleSessionState={() => setSessionState("video")} />;
  }
  if (sessionState === "video") {
    return <VideoCall />;
  }
};

export default Session;
