import React, { useState } from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import { Pagination } from "../../components/ui/Button";
import FavIcon from "../../images/favicon.png";
import { EyeIcon, DeleteIcon, CommentIcon, BanIcon } from "../../icons/icons";

export const ConnectedUsers = ({
  connections,
  handleConfirmationModal,
  viewProfile,
}) => {
  const toast = useToastContext();
  const { userRole } = useLocalStorage();

  const [page, setPage] = useState(1);

  return (
    <>
      <h4 className="mb-1 md:mb-4 font-semibold md:text-lg">Connected Users</h4>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-xs md:text-sm">User Name</th>
              <th className="p-3 font-medium text-xs md:text-sm">
                Connected On
              </th>
              <th className="p-3 font-medium text-xs md:text-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            {connections.length > 0 ? (
              connections.filter((user) => user.status === "1").length > 0 ? (
                connections
                  .filter((user) => user.status === "1")
                  .slice(page * 7 - 7, page * 7)
                  .map((user) => (
                    <tr
                      key={user.connection_id}
                      className="bg-white border-b border-slate-300 align-middle h-auto"
                    >
                      <td className="p-3 flex items-center text-xs md:text-sm">
                        <div
                          onClick={() =>
                            viewProfile(
                              user.name,
                              user.role,
                              user.connection_id
                            )
                          }
                          className="flex items-center gap-x-2 cursor-pointer"
                        >
                          <img
                            src={user?.logo.length > 0 ? user.logo : FavIcon}
                            alt="User"
                            width={50}
                            className="h-[50px] rounded-full border"
                            loading="lazy"
                          />
                          <div>
                            <p className="font-medium">{user.name}</p>
                            <span
                              className={`px-1 text-xs ${
                                user.role === "f@!3A"
                                  ? "blue-badge"
                                  : user.role === "R$7s2"
                                  ? "orange-badge"
                                  : user.role === "Sw%aa"
                                  ? "green-badge"
                                  : "indigo-badge"
                              }`}
                            >
                              {user.role === "f@!3A"
                                ? "Startup"
                                : user.role === "R$7s2"
                                ? "Mentor"
                                : user.role === "Sw%aa"
                                ? "Investor"
                                : "Vendor"}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="p-3 text-xs md:text-sm text-[#5a5a5b]">
                        {user.date}
                      </td>
                      <td className="p-3" id="connectedUserId">
                        <div className="flex items-center justify-evenly gap-2 h-full">
                          {user.deactivate === "0" ? (
                            <span title="View Profile">
                              <EyeIcon
                                onClick={() =>
                                  viewProfile(
                                    user.name,
                                    user.role,
                                    user.connection_id
                                  )
                                }
                                className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                              />
                            </span>
                          ) : (
                            <span title="Deactivated Profile">
                              <EyeIcon
                                onClick={() =>
                                  toast.open(
                                    "warning",
                                    "This profile has been deactivated"
                                  )
                                }
                                className="disabled fill-green-500"
                              />
                            </span>
                          )}
                          <Link to="/chat" title="Message User">
                            <CommentIcon className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800" />
                          </Link>
                          {userRole === "R$7s2" ? (
                            <span title="Block User">
                              <BanIcon
                                fill="#5a5a5b"
                                onClick={() =>
                                  handleConfirmationModal(
                                    "block",
                                    user.connection_id
                                  )
                                }
                                className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                              />
                            </span>
                          ) : (
                            <span title="Remove User">
                              <DeleteIcon
                                fill="#5a5a5b"
                                onClick={() =>
                                  handleConfirmationModal(
                                    "unfollow",
                                    user.connection_id
                                  )
                                }
                                className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                              />
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr className="bg-white text-center">
                  <td colSpan="4" className="white p-3 text-xs md:text-sm">
                    No connected users found
                  </td>
                </tr>
              )
            ) : (
              <tr className="bg-white text-center">
                <td colSpan="4" className="p-3 text-xs md:text-sm">
                  No connected users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {connections.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={connections.length}
        />
      )}
    </>
  );
};
