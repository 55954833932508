import { Button } from "../../../../components/ui/Button";
import { Stars } from "../../../../components/ui/modals/rating-modal/Stars";
import { capitalizeText } from "../../../../utils/capitalizeText";
import {
  BuildingIcon,
  GlobeIcon,
  LocationIcon,
  RedirectArrowIcon,
} from "../../../../icons/icons";
import FavIcon from "../../../../images/favicon.png";
import verifiedLogo from "../../../../images/verified.png";

const VendorDetails = ({ details, handleConfirmationModal }) => {
  return (
    <section className="grid grid-cols-7 gap-3">
      {details?.deactivate === "1" && details?.deactive_reason?.length > 0 && (
        <div className="col-span-7 md:hidden bg-slate-200 shadow-md rounded-md py-2 px-3">
          <h5 className="font-semibold pb-2 text-secondary">
            Deactivation Reason :
          </h5>
          <p className="font-medium">{details?.deactive_reason}</p>
        </div>
      )}
      <div className="col-span-7 md:col-span-2 p-3 bg-white shadow-md rounded-md">
        <div className="flex items-center justify-end gap-y-1 md:hidden">
          {details?.is_suspend === "0" ? (
            <Button
              className="px-3 py-1 mx-1 text-xs"
              bgColor="bg-secondary"
              onClick={() => handleConfirmationModal("suspend")}
            >
              Suspend
            </Button>
          ) : (
            <Button
              className="px-3 py-1 mx-1 text-xs"
              bgColor="bg-green-500"
              onClick={() => handleConfirmationModal("unsuspend")}
            >
              Un-Suspend
            </Button>
          )}
          {details?.platform_verify === "0" ? (
            <Button
              className="px-3 py-1 mx-1 text-xs"
              onClick={() => handleConfirmationModal("certify")}
            >
              Certify
            </Button>
          ) : (
            <Button
              className="disabled px-3 py-1 mx-1 text-xs"
              bgColor="bg-green-500"
              disabled
            >
              Certified
            </Button>
          )}
        </div>
        <div className="h-[90px] w-[90px] lg:h-[140px] lg:w-[140px] flex items-center border rounded-full">
          <img
            src={details?.logo?.length < 1 ? FavIcon : details?.logo}
            className="max-h-[90px] lg:max-h-[140px] p-1 mx-auto rounded-full"
            alt="profile-img"
            loading="lazy"
          />
        </div>
        <div className="font-bold text-lg pt-2 mb-0 flex items-center uppercase">
          {details?.name}
          {details?.platform_verify === "1" && (
            <img
              src={verifiedLogo}
              alt="Platform Verification Logo"
              title="Platform Verified"
              width={20}
              className="ms-1"
            />
          )}
        </div>
        <div className="flex items-center gap-x-1">
          <Stars rating={details?.details?.average_rating} size={12} />
        </div>
        <div className="flex items-center mb-2">
          <BuildingIcon fill="#9c9c9c" />
          <p className="text-sm mb-0 ms-3 capitalize">
            <span className="me-1 font-bold">Company :</span>
            {details?.details?.company_name}
          </p>
        </div>
        <div className="flex items-center mb-2">
          <GlobeIcon fill="#9c9c9c" />
          <p className="text-sm mb-0 ms-3">
            <span className="me-1 font-bold">Website :</span>
            {details?.portfolio?.website?.length > 0 ? (
              <a
                href={details?.portfolio?.website}
                target="_blank"
                rel="noreferrer"
                className="text-sm mb-0 ms-1 text-blue-500"
              >
                URL
              </a>
            ) : (
              <span className="text-sm mb-0 ms-3 text-secondary">N/A</span>
            )}
          </p>
        </div>
        <div className="flex mb-4">
          <LocationIcon fill="#9c9c9c" />
          <div className="flex items-center text-sm mb-0 ms-3">
            <span className="font-bold me-1">Address :</span>
            <div className="flex items-center">
              <span className="me-1 capitalize">{details?.city}</span>
              <img
                src={
                  details?.country_code
                    ? `https://flagsapi.com/${details?.country_code}/flat/64.png`
                    : "https://flagsapi.com/IN/flat/64.png"
                }
                alt={`${details?.country} flag`}
                width={18}
                title={capitalizeText(details?.country)}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="border-t py-2">
          <p className="mb-0 font-bold">Industries</p>
          {details?.details?.expert_in?.length > 0 ? (
            details?.details?.expert_in?.map((tag, i) => (
              <span key={i} className="outline-tags">
                {tag.replace(new RegExp("_", "g"), " ")}
              </span>
            ))
          ) : (
            <p>Not available</p>
          )}
        </div>
      </div>
      <div className="col-span-7 md:col-span-5">
        {details?.deactivate === "1" &&
          details?.deactive_reason?.length > 0 && (
            <div className="hidden md:block bg-slate-200 shadow-md rounded-md py-2 px-3">
              <h5 className="font-semibold pb-2 text-secondary">
                Deactivation Reason :
              </h5>
              <p className="font-medium">{details?.deactive_reason}</p>
            </div>
          )}
        <div className="flex items-center justify-end">
          {details?.is_suspend === "0" ? (
            <Button
              className="hidden md:block px-3 py-1 mx-1 text-xs"
              bgColor="bg-secondary"
              onClick={() => handleConfirmationModal("suspend")}
            >
              Suspend
            </Button>
          ) : (
            <Button
              className="hidden md:block px-3 py-1 mx-1 text-xs"
              bgColor="bg-green-500"
              onClick={() => handleConfirmationModal("unsuspend")}
            >
              Un-Suspend
            </Button>
          )}
          {details?.platform_verify === "0" ? (
            <Button
              className="px-3 py-1 mx-1 text-xs"
              onClick={() => handleConfirmationModal("certify")}
            >
              Certify
            </Button>
          ) : (
            <Button
              className="disabled px-3 py-1 mx-1 text-xs"
              bgColor="bg-green-500"
              disabled
            >
              Certified
            </Button>
          )}
        </div>
        <div className="p-3 bg-white shadow-md rounded-md mt-3">
          <h4 className="font-medium mb-2">Bio</h4>
          <p>{details?.details?.description}</p>
        </div>
        <div className="p-3 bg-white shadow-md rounded-md mt-5">
          <h4 className="font-medium mb-2">Portfolio</h4>
          <div className="grid grid-cols-2 gap-2">
            {details?.portfolio?.documents?.length > 0 ? (
              details?.portfolio?.documents?.map((data) => (
                <a
                  key={data._id}
                  href={data.path}
                  target="_blank"
                  rel="noreferrer"
                  className="col-span-2 md:col-span-1 flex items-center justify-between p-2 bg-gray-200 rounded"
                >
                  <span>{data.file_name}</span>
                  <RedirectArrowIcon />
                </a>
              ))
            ) : (
              <p className="col-span-2">Portfolio not updated</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VendorDetails;
