import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useAddDescription = () => {
  const { fetchApi } = useFetch();
  const { token } = useLocalStorage();

  const addDescription = (url, bodyData, callBackFn) => {
    fetchApi(
      {
        url: url,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: bodyData,
      },
      () => {
        callBackFn();
      }
    );
  };
  return { addDescription };
};

export default useAddDescription;
