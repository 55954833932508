import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../Button";
import { AngleRightIcon } from "../../../icons/icons";

export const AdCard = ({ ad, role, handleBidModal, credits }) => {
  const {
    ad_id,
    deadline,
    description,
    max_budget,
    min_budget,
    tags,
    title,
    name,
    startup_id,
  } = ad;

  const navigate = useNavigate();

  const checkCredits = useCallback(() => {
    if (credits < 25) {
      alert("Don't have enough credits");
      navigate("/vendor-plan");
      return;
    }
    handleBidModal(ad_id, title, min_budget, startup_id);
  }, [navigate, credits, ad_id, title, min_budget, startup_id, handleBidModal]);

  return (
    <div className="bg-white shadow p-3 md:p-5 rounded-lg space-y-3">
      <div className="flex flex-col md:flex-row justify-between">
        <p className="mb-2 font-semibold text-lg md:w-8/12 md:mb-0">{title}</p>
        {role === "V&iR8" && (
          <Button
            className="px-1 py-0.5 text-sm"
            onClick={() => checkCredits()}
          >
            Post Bid
          </Button>
        )}
      </div>
      <p>
        {tags.map((tag) => (
          <span key={tag} className="industry-tags">
            {tag.replace(new RegExp("_", "g"), " ")}
          </span>
        ))}
      </p>
      <p
        className={`${
          description.length > 200 ? "description-text" : ""
        } text-sm mt-3`}
      >
        {description}
      </p>
      {description.length > 200 && (
        <input type="checkbox" className="expand-btn" />
      )}
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-start md:flex-row md:items-center gap-x-5 card-footer">
          <p className="text-xs sm:text-sm">
            <span className="font-semibold mr-1 text-blue-900">Author :</span>
            {name}
          </p>
          <p className="text-xs sm:text-sm">
            <span className="font-semibold mr-1 text-blue-900">Budget :</span>₹
            {min_budget} - ₹{max_budget}
          </p>
          <p className="text-xs sm:text-sm">
            <span className="font-semibold mr-1 text-blue-900">Duration :</span>
            {deadline} days
          </p>
        </div>
        <Link
          to={`/bids/${ad_id}`}
          className="bg-primary transition-colors duration-150 ease-in hover:bg-blue-900 w-[30px] h-[30px] md:w-[40px] md:h-[40px] rounded-full flex items-center justify-center"
          title="View Details"
        >
          <AngleRightIcon fill="#ffffff" />
        </Link>
      </div>
    </div>
  );
};
