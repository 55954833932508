import { useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import "../../../../custom.css";

export const CertificateModal = ({
  open,
  onClose,
  addCertificates,
  documentType,
}) => {
  const [file, setFile] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      certificateType: "",
    },
  });

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    document.getElementById("mandatory-error").classList.add("hidden");
    if (!file) {
      document.getElementById("mandatory-error").classList.remove("hidden");
      return;
    }
    addCertificates(file, data.certificateType);
    setFile();
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[400px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Add New Certificate
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="p-3 text-left">
          <div className="mb-2">
            <label className="mb-0 text-sm required" htmlFor="certificateType">
              Certificate Type
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              {documentType === "mandatory" ? (
                <select
                  className="form-select border-0 p-2 w-full"
                  name="certificateType"
                  id="certificateType"
                  {...register("certificateType", {
                    required: "Please select a certificate type",
                  })}
                >
                  <option value="">Select</option>
                  <option value="incorporation">
                    Certificate of Incorporation
                  </option>
                  <option value="msme">MSME Registration</option>
                  <option value="gst">
                    Goods and Services Tax (GST) Registration
                  </option>
                </select>
              ) : (
                <select
                  className="form-select border-0 w-full p-2"
                  name="certificateType"
                  id="certificateType"
                  {...register("certificateType", {
                    required: "Please select a certificate type",
                  })}
                >
                  <option value="">Select</option>
                  <option value="privacy policy">Privacy policy</option>
                  <option value="Terms of service">Terms of service</option>
                  <option value="Startup India Recognition">
                    Startup India Recognition
                  </option>
                  <option value="Team Information">Team Information</option>
                  <option value="Founder Agreement">Founder Agreement</option>
                  <option value="Trademark Registrations">
                    Trademark Registrations
                  </option>
                  <option value="Financial Documentation">
                    Financial Documentation
                  </option>
                  <option value="Business Plan">Business Plan</option>
                  <option value="Market Research Report">
                    Market Research Report
                  </option>
                  <option value="Licensing Agreement">
                    Licensing Agreement
                  </option>
                  <option value="Compliance Documents">
                    Compliance Documents
                  </option>
                </select>
              )}
            </div>
            {errors.certificateType && (
              <div>
                <FormError message={errors.certificateType.message} />
              </div>
            )}
          </div>
          <div>
            <label
              htmlFor="upload-certificate"
              className="p-1 mt-3 rounded-md bg-indigo-500 text-white text-sm cursor-pointer"
            >
              Upload Certificate
            </label>
            <input
              type="file"
              id="upload-certificate"
              accept="image/jpeg,image/png,application/pdf"
              onChange={(e) => setFile(e.target.files[0])}
              required
              hidden
            />
            <span className="ms-2">{file && file.name}</span>
          </div>
          <p className="hidden text-sm text-red-500" id="mandatory-error">
            File is missing
          </p>
          <div className="modalFooter p-3">
            <Button type="submit" className="px-2 py-1 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
