import useLocalStorage from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import ErrorImg from "../../images/404.svg";
import { Button } from "../../components/ui/Button";

export const Error404 = () => {
  const { userRole } = useLocalStorage();
  const navigate = useNavigate();

  const checkRole = () => {
    if (userRole === "admin") {
      navigate("/admin-dashboard");
    } else if (userRole === "f@!3A") {
      navigate("/startup-dashboard");
    } else if (userRole === "R$7s2") {
      navigate("/mentor-dashboard");
    } else if (userRole === "Sw%aa") {
      navigate("/investor-dashboard");
    } else {
      navigate("/vendor-profile");
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img src={ErrorImg} alt="Error-404" width={450} />
      <Button onClick={() => checkRole()} className="px-2 py-1 mx-2">
        Dashboard
      </Button>
    </div>
  );
};
