import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import FavIcon from "../../../images/favicon.png";
import { ImportImage } from "../../../utils/importImage";

const RequestDetails = () => {
  const getDetailsApi = `${process.env.REACT_APP_API_URL}view-withdraw`;
  const updateStatusApi = `${process.env.REACT_APP_API_URL}update-withdraw-request`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = location.state;

  const [openSidebar, setOpenSidebar] = useState(false);
  const [userData, setUserData] = useState([]);
  const [status, setStatus] = useState("0");

  const getDetails = useCallback(() => {
    fetchApi(
      {
        url: getDetailsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          user_id: data?.user_id,
        },
      },
      (data) => {
        setUserData(data.details);
      }
    );
  }, [getDetailsApi, fetchApi, token, data]);

  const updateStatus = useCallback(
    (status) => {
      fetchApi(
        {
          url: updateStatusApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: data?.id,
            role: data?.role,
            status: status,
          },
        },
        () => {
          setStatus(status);
          getDetails();
        }
      );
    },
    [updateStatusApi, fetchApi, token, data, getDetails]
  );

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      setStatus(data?.status);
      getDetails();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getDetails, navigate, userRole, data]);

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!isLoading && !error && (
          <div className="grid grid-cols-3 m-4 p-4 bg-white shadow-md rounded-md">
            {userData &&
              userData.map((data) => (
                <div
                  key={data.email}
                  className="col-span-3 md:col-span-1 md:border-r pe-1"
                >
                  <img
                    src={data.logo ? data.logo : FavIcon}
                    alt="user-img"
                    className="w-[110px] h-[110px] md:w-[140px] md:h-[140px] rounded-full p-1 mb-3 border border-slate-400"
                  />
                  <p className="font-semibold">
                    Name :
                    <span className="ms-1 font-medium uppercase">
                      {data.name}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Role :
                    <span className="ms-1 font-medium">
                      {data.role === "f@!3A"
                        ? "Startup"
                        : data.role === "R$7s2"
                        ? "Mentor"
                        : "Vendor"}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Email :
                    <span className="ms-1 font-medium">{data.email}</span>
                  </p>
                  <p className="font-semibold">
                    Phone :
                    <span className="ms-1 font-medium">{data.phone}</span>
                  </p>
                </div>
              ))}
            <div className="col-span-3 md:col-span-2 p-2">
              <div className="flex flex-col md:flex-row justify-between md:items-center mt-3 md:mt-0 mb-2">
                <h4 className="font-medium order-2 md:order-1">
                  Current Status :
                  <span
                    className={`
                    ps-2
                      ${
                        status === "0"
                          ? "text-orange-500"
                          : status === "1"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                  >
                    {status === "0"
                      ? "Pending"
                      : status === "1"
                      ? "Completed"
                      : "Rejected"}
                  </span>
                </h4>
                <div className="flex items-center order-1 md:order-2">
                  <label
                    htmlFor="status-filter"
                    className="hidden md:inline-block mb-0 pe-1 font-medium text-sm"
                  >
                    Change Status :
                  </label>
                  <select
                    htmlFor="status-filter"
                    id="status-filter"
                    className="border shadow p-1 rounded text-sm"
                    value={status}
                    onChange={(e) => updateStatus(e.target.value)}
                  >
                    <option value="0">Pending</option>
                    <option value="1">Completed</option>
                    <option value="-1">Rejected</option>
                  </select>
                </div>
              </div>
              <p className="font-semibold">
                Requested Amount :
                <span className="ms-1 font-medium">
                  {data?.role === "f@!3A"
                    ? "₹" + data?.withdraw_amount
                    : "₹" + data?.withdraw_amount * (1 - 0.1)}
                </span>
              </p>
              <h4 className="font-semibold mt-2 mb-1">Bank Details</h4>
              {userData ? (
                userData[0]?.bank_details?.length > 0 ? (
                  userData[0]?.bank_details.map((bank) => (
                    <div
                      key={bank._id}
                      className="flex justify-between items-center px-4 py-2 mt-2 rounded-md bg-gray-200"
                    >
                      <div>
                        <p className="font-semibold text-xs md:text-sm leading-relaxed">
                          Bank :
                          <span className="ps-2 font-medium whitespace-normal uppercase">
                            {bank.bank_name} Bank
                          </span>
                        </p>
                        <p className="font-semibold text-xs md:text-sm leading-relaxed">
                          Account Holder :
                          <span className="ps-2 font-medium whitespace-normal">
                            {bank.name}
                          </span>
                        </p>
                        <p className="font-semibold text-xs md:text-sm leading-relaxed">
                          Account Number :
                          <span className="ps-2 font-medium whitespace-normal">
                            {bank.account_number}
                          </span>
                        </p>
                        <p className="font-semibold text-xs md:text-sm leading-relaxed">
                          IFSC :
                          <span className="ps-2 font-medium whitespace-normal">
                            {bank.ifsc}
                          </span>
                        </p>
                      </div>
                      <ImportImage
                        imageName={bank.bank_name}
                        width={45}
                        alt={bank.bank_name}
                      />
                    </div>
                  ))
                ) : (
                  <p>No Bank Found. Ask user to update bank details.</p>
                )
              ) : (
                <p className="text-red-500 font-medium">
                  Sorry, Something went wrong
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestDetails;
