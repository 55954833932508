import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import { useToastContext } from "../../context/ToastContext";
import { randomId } from "../../utils/randomId";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { EditBidModal } from "../../components/ui/modals/market/editBidModal";
import { Pagination } from "../../components/ui/Button";
import { DeleteIcon, EyeIcon, PenIcon } from "../../icons/icons";

const MyBids = () => {
  const getBidsApi = `${process.env.REACT_APP_API_URL}display-my-bids`;
  const editBidApi = `${process.env.REACT_APP_API_URL}edit-bid`;
  const deleteBidApi = `${process.env.REACT_APP_API_URL}delete-bid`;

  const { token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const toast = useToastContext();

  const [bids, setBids] = useState([]);
  const [bidData, setBidData] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);

  const getBids = useCallback(() => {
    fetchApi(
      {
        url: getBidsApi,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setBids(data.bids);
      }
    );
  }, [getBidsApi, fetchApi, token]);

  const editBid = useCallback(
    (data, id) => {
      fetchApi(
        {
          url: editBidApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            bid_amount: data.bidAmount,
            bid_deadline: data.workDuration,
            bid_description: data.bidDescription,
          },
        },
        (data) => {
          if (data.message === "awarded") {
            toast.open("warning", "Cannot edit awarded Bid");
            return;
          }
          getBids();
        }
      );
    },
    [fetchApi, token, toast, editBidApi, getBids]
  );

  const deleteBid = useCallback(
    (bidId, adId) => {
      fetchApi(
        {
          url: deleteBidApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: bidId,
            ad_id: adId,
          },
        },
        (data) => {
          if (data.message === "awarded") {
            toast.open("warning", "Cannot delete awarded Bid");
            return;
          }
          getBids();
        }
      );
    },
    [fetchApi, token, toast, deleteBidApi, getBids]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      getBids();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getBids]);

  const handleBidModal = (data) => {
    setBidData(data);
    setOpenModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="p-3">
            <Error />
          </div>
        )}
        {!error && !isLoading && (
          <>
            <div className="p-3 md:p-6 grid gap-4">
              <h4 className="text-xl font-medium">My Bids</h4>
              <span className="text-xs">
                <span className="font-medium pe-1">Note :</span> The awarded
                amount is freezed and is only transfered to your account once
                the project is marked as completed by startup.
              </span>
              <div className="max-w-fit overflow-auto md:max-w-none md:overflow-none md:w-full">
                <table className="table-auto min-w-max md:w-full border">
                  <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
                    <tr>
                      <th className="p-3 text-sm w-6/12 font-medium">
                        Project Title
                      </th>
                      <th className="p-3 text-sm font-medium">Amount</th>
                      <th className="p-3 text-sm font-medium">Deadline</th>
                      <th className="p-3 text-sm font-medium">Status</th>
                      <th className="p-3 text-sm font-medium">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bids.length > 0 ? (
                      bids.slice(page * 7 - 7, page * 7).map((bid) => (
                        <tr
                          key={bid.bid_id}
                          className="bg-white border-b border-slate-300"
                        >
                          <td className="p-3 text-sm">{bid.title}</td>
                          <td className="p-3 text-sm">
                            <span className="font-medium mr-1">&#8377;</span>
                            {bid.bid_amount}
                          </td>
                          <td className="p-3 text-sm">
                            {bid.bid_deadline}
                            <span className="font-medium ml-1">days</span>
                          </td>
                          <td className="p-3">
                            <span
                              className={`p-1 text-xs ${
                                bid.status === "0" && bid.bid_status === "0"
                                  ? "blue-badge"
                                  : bid.status === "1" && bid.bid_status === "0"
                                  ? "red-badge"
                                  : bid.status === "2" && bid.bid_status === "0"
                                  ? "red-badge"
                                  : bid.status === "1" && bid.bid_status === "1"
                                  ? "orange-badge"
                                  : "green-badge"
                              }`}
                            >
                              {bid.status === "0" && bid.bid_status === "0"
                                ? "Active"
                                : bid.status === "1" && bid.bid_status === "0"
                                ? "Not Awarded"
                                : bid.status === "2" && bid.bid_status === "0"
                                ? "Not Awarded"
                                : bid.status === "1" && bid.bid_status === "1"
                                ? "Awarded"
                                : bid.status === "2" && bid.bid_status === "2"
                                ? "Completed"
                                : null}
                            </span>
                          </td>
                          <td className="p-3 text-sm">
                            <p className="flex justify-between items-center">
                              <Link
                                to={`/bids/${bid.ad_id}`}
                                title="View Project Details"
                              >
                                <EyeIcon className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800" />
                              </Link>
                              {bid.status === "0" ? (
                                <span title="Edit Bid">
                                  <PenIcon
                                    className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800"
                                    onClick={() =>
                                      bid.status === "0" && handleBidModal(bid)
                                    }
                                  />
                                </span>
                              ) : (
                                <span title="Cannot Edit Bid">
                                  <PenIcon className="disabled fill-blue-500" />
                                </span>
                              )}
                              {bid.status === "0" ? (
                                <span title="Delete Bid">
                                  <DeleteIcon
                                    className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                                    onClick={() => {
                                      bid.status === "0" &&
                                        deleteBid(bid.bid_id, bid.ad_id);
                                    }}
                                  />
                                </span>
                              ) : (
                                <span title="Cannot Delete Bid">
                                  <DeleteIcon className="disabled fill-red-500" />
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="bg-white text-center">
                        <td colSpan={5} className="p-3 text-sm">
                          No Bid posted
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {bids.length > 0 && (
              <Pagination
                page={page}
                setPage={setPage}
                totalDataCount={bids.length}
              />
            )}
          </>
        )}
      </div>
      <EditBidModal
        key={randomId()}
        open={openModal}
        onClose={() => setOpenModal(false)}
        bidData={bidData}
        editBid={editBid}
      />
    </div>
  );
};

export default MyBids;
