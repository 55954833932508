import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { useToastContext } from "../../../context/ToastContext";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import { Button } from "../../../components/ui/Button";
import { AddSampleFileModal } from "../../../components/ui/modals/documents/addSampleFile";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";
import { EditSampleFileModal } from "../../../components/ui/modals/documents/editSampleFile";
import { randomId } from "../../../utils/randomId";
import DocumentCard from "../../../components/ui/cards/documentCard";

const Documents = () => {
  const uploadSampleDocApi = `${process.env.REACT_APP_API_URL}upload-sample-document`;
  const displaySampleDocsApi = `${process.env.REACT_APP_API_URL}diplay-sample-document`;
  const deleteSampleDocsApi = `${process.env.REACT_APP_API_URL}delete-sample-document`;
  const editSampleDocsApi = `${process.env.REACT_APP_API_URL}edit-sample-document`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [AddFileModal, setAddFileModal] = useState(false);
  const [openConfimModal, setOpenConfirmModal] = useState({
    open: false,
    id: null,
  });
  const [openEditModal, setOpenEditModal] = useState({
    open: false,
    data: null,
  });
  const [docs, setDocs] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("All");

  const displaySampleDocs = useCallback(() => {
    fetchApi(
      {
        url: displaySampleDocsApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setDocs(data.details);
        setDocTypes([
          "All",
          ...new Set(data.details.map((doc) => doc.document_type)),
        ]);
      }
    );
  }, [displaySampleDocsApi, token, fetchApi]);

  const uploadDocument = useCallback(
    (data) => {
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("thumbnail", data.thumbnail);
      formData.append("title", data.title);
      formData.append("document_type", data.type);
      formData.append("tags", data.tags);
      formData.append("description", data.description);
      formData.append("status", data.status);
      axios
        .post(uploadSampleDocApi, formData, {
          headers: { "x-token": token },
        })
        .then(() => {
          toast.open("true", "Document uploaded successfully!");
          displaySampleDocs();
        })
        .catch((err) => {
          toast.open("false", "Failed to add document.");
        });
    },
    [token, uploadSampleDocApi, toast, displaySampleDocs]
  );

  const deleteSampleDocs = useCallback(() => {
    fetchApi(
      {
        url: deleteSampleDocsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          id: openConfimModal.id,
        },
      },
      () => {
        toast.open("true", "Document deleted successfully!");
        displaySampleDocs();
      }
    );
  }, [
    deleteSampleDocsApi,
    token,
    fetchApi,
    openConfimModal.id,
    toast,
    displaySampleDocs,
  ]);

  const editSampleDocs = useCallback(
    (newData, existingData) => {
      const formData = new FormData();
      if (newData.file !== existingData.file_name) {
        formData.append("file", newData.file);
      }
      if (newData.thumbnail !== existingData.thumbnail_name) {
        formData.append("thumbnail", newData.thumbnail);
      }
      formData.append("title", newData.title);
      formData.append("document_type", newData.type);
      formData.append("tags", newData.tags);
      formData.append("description", newData.description);
      formData.append("status", newData.status);
      formData.append("id", existingData._id);
      axios
        .post(editSampleDocsApi, formData, {
          headers: { "x-token": token },
        })
        .then(() => {
          toast.open("true", "Document updated successfully!");
          displaySampleDocs();
        })
        .catch((err) => {
          toast.open("false", "Update failed");
        });
    },
    [token, editSampleDocsApi, toast, displaySampleDocs]
  );

  useEffect(() => {
    const getDocs = setTimeout(() => {
      displaySampleDocs();
    });
    return () => {
      clearTimeout(getDocs);
    };
  }, [displaySampleDocs]);

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!error && !isLoading && (
          <div className="p-4">
            <div className="flex items-center justify-between mb-5">
              <h3 className="text-lg font-semibold">Sample Templates</h3>
              <Button
                className="px-3 py-1"
                onClick={() => setAddFileModal(true)}
              >
                Add New
              </Button>
            </div>
            {docs.length > 0 ? (
              <div className="grid sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                <div className="sm:col-span-2 md:col-span-3 xl:col-span-4 flex items-center justify-start gap-x-3">
                  <h5 className="font-medium">Document Types :</h5>
                  <div className="flex items-center justify-start gap-x-3">
                    {docTypes.map((type) => (
                      <p
                        key={type}
                        className={`px-3 py-1 rounded-md transition-colors delay-150 hover:bg-primary hover:text-white cursor-pointer text-xs font-medium capitalize ${
                          selectedDocType === type
                            ? "bg-primary text-white"
                            : "outline outline-1 outline-gray-300 bg-white"
                        }`}
                        onClick={() => setSelectedDocType(type)}
                      >
                        {type}
                      </p>
                    ))}
                  </div>
                </div>
                {docs
                  .filter(
                    (document) =>
                      selectedDocType === "All" ||
                      document.document_type === selectedDocType
                  )
                  .map((document) => (
                    <DocumentCard
                      key={document._id}
                      data={document}
                      setOpenEditModal={setOpenEditModal}
                      setOpenConfirmModal={setOpenConfirmModal}
                      type="admin"
                    />
                  ))}
              </div>
            ) : (
              <p className="md:col-span-3 xl:col-span-4 font-medium">
                No Documents Found.
              </p>
            )}
          </div>
        )}
      </div>
      <AddSampleFileModal
        open={AddFileModal}
        onClose={() => setAddFileModal(false)}
        callBackFn={uploadDocument}
      />
      <EditSampleFileModal
        key={randomId()}
        open={openEditModal.open}
        onClose={() => setOpenEditModal((prev) => ({ ...prev, open: false }))}
        callBackFn={editSampleDocs}
        existingData={openEditModal.data}
      />
      <ConfirmationModal
        open={openConfimModal.open}
        onClose={() =>
          setOpenConfirmModal((prev) => ({ ...prev, open: false }))
        }
        message="Are you sure you want to delete this document?"
        callBackFn={deleteSampleDocs}
      />
    </div>
  );
};

export default Documents;
