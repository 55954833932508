import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Country, State, City } from "country-state-city";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import {
  numberRegex,
  textWithSpaceRegex,
  bannedInputs,
  roleOptions,
} from "../../constants";
import {
  UserIcon,
  PhoneIcon,
  MailIcon,
  EyeIcon,
  SlashedEyeIcon,
} from "../../icons/icons";
import { PrivacyPolicy } from "../../components/ui/modals/privacyPolicy";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const RegistrationForm = ({
  postRegistration,
  isClicked,
  setIsClicked,
}) => {
  const registerApi = `${process.env.REACT_APP_API_URL}register`;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, error, fetchApi } = useFetch();

  let countryData = Country.getAllCountries();
  let stateData = State.getStatesOfCountry();
  let cityData = City.getCitiesOfState();

  const [viewPass, setViewPass] = useState(false);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [geoData, setGeoData] = useState({
    countries: countryData,
    states: stateData,
    cities: cityData,
  });
  const [address, setAddress] = useState({
    country: null,
    countryIso: "",
    state: null,
    stateIso: "",
    city: null,
  });

  const registerApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("email-exists").classList.add("hidden");
    if (address.country === "") {
      document.getElementById("country-error").classList.remove("hidden");
      return;
    }
    if (address.state === "") {
      document.getElementById("state-error").classList.remove("hidden");
      return;
    }
    if (geoData.cities.length > 0 && address.city === "") {
      document.getElementById("city-error").classList.remove("hidden");
      return;
    }
    fetchApi(
      {
        url: registerApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          name: data.name,
          email: data.email.toLowerCase(),
          phone: data.phone,
          password: data.password,
          country: address.country.value.toLowerCase(),
          country_code: address.countryIso,
          state:
            address.state != null
              ? address.state.value.toLowerCase()
              : address.country.value.toLowerCase(),
          city:
            address.city != null
              ? address.city.value.toLowerCase()
              : address.state != null
              ? address.state.value.toLowerCase()
              : address.country.value.toLowerCase(),
          role: data.role.value,
          date: getCurrentDate(),
        },
      },
      postRegistration
    );
  };

  const onCountryChange = (data) => {
    let countryIsoCode = geoData.countries.filter(
      (country) => country.name === data.value
    )[0]?.isoCode;

    let newAddress = {
      ...address,
      country: data,
      countryIso: countryIsoCode,
    };

    if (address.state || address.city) {
      const checkState = geoData.states?.filter(
        (state) => state.countryCode === countryIsoCode
      );
      const checkCity = geoData.cities?.filter(
        (city) => city.countryCode === countryIsoCode
      );
      if (checkState.length < 1) {
        newAddress.state = "";
        newAddress.stateIso = "";
      }
      if (checkCity.length < 1) {
        newAddress.city = "";
      }
    }
    setAddress(newAddress);
    setGeoData({
      ...geoData,
      states: State.getStatesOfCountry(countryIsoCode),
    });
  };

  const onStateChange = (data) => {
    let stateIsoCode = geoData.states.filter(
      (state) => state.name === data.value
    )[0]?.isoCode;
    let newAddress = {
      ...address,
      state: data,
      stateIso: stateIsoCode,
    };

    if (address.state || address.city) {
      const checkCity = geoData.cities?.filter(
        (city) => city.stateCode === stateIsoCode
      );
      if (checkCity.length < 1) {
        newAddress.city = "";
      }
    }
    setAddress(newAddress);
    setGeoData({
      ...geoData,
      cities: City.getCitiesOfState(address.countryIso, stateIsoCode),
    });
  };

  const onCityChange = (data) => {
    setAddress({ ...address, city: data });
  };

  return (
    <div
      className="flex col-span-7 authentication-bg relative bg-white md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:my-0 lg:py-3 md:col-start-2 md:col-span-5 lg:col-span-2"
      id="registrationForm"
    >
      <div className="mx-auto p-5 rounded-md shadow-md md:w-full lg:p-3 lg:shadow-none lg:rounded-none lg:pt-0">
        <h4 className="text-lg font-medium">Adventure starts here 🚀</h4>
        <p className="mb-2">
          Access the future. Sign up, step into our startup ecosystem
        </p>
        <form onSubmit={handleSubmit(registerApiCall)}>
          <div className="mb-1">
            <label htmlFor="name" className="text-sm mb-0 required">
              Full Name
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="name"
                name="name"
                placeholder="Enter Full Name"
                autoFocus
                {...register("name", {
                  required: "Name is required",
                  validate: {
                    MinLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 2 ||
                        "minimum required length is 3"
                      );
                    },
                    MaxLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length < 51 ||
                        "cannot exceed 50 characters"
                      );
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.name && (
              <div>
                <FormError message={errors.name.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="phone" className="text-sm mb-0 required">
              Phone
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="text"
                className="border-0 w-full ps-3"
                id="phone"
                name="phone"
                {...register("phone", {
                  required: "Phone is required",
                  validate: {
                    minValue: (fieldValue) => {
                      return (
                        fieldValue.length > 6 ||
                        "Number should be atleast 7 digits"
                      );
                    },
                    maxValue: (fieldValue) => {
                      return (
                        fieldValue.length < 13 ||
                        "Number cannot be more than 12 digits"
                      );
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
                placeholder="Enter Phone Number"
              />
              <span className="input-icons icon-span">
                <PhoneIcon />
              </span>
            </div>
            {errors.phone && (
              <div>
                <FormError message={errors.phone.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="email" className="text-sm mb-0 required">
              Email
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="email"
                className="border-0 w-full ps-3"
                id="email"
                name="email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required",
                  validate: (fieldValue) => {
                    return (
                      !bannedInputs.some((value) =>
                        fieldValue.includes(value)
                      ) || "Enter a different email address"
                    );
                  },
                })}
              />
              <span className="input-icons icon-span">
                <MailIcon />
              </span>
            </div>
            {errors.email && (
              <div>
                <FormError message={errors.email.message} />
              </div>
            )}
            <p id="email-exists" className="hidden text-secondary text-xs">
              This email already exisits
            </p>
          </div>
          <div className="mb-1">
            <label htmlFor="password" className="text-sm mb-0 required">
              Set Password
            </label>
            <div
              className="flex rounded-md overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type={viewPass ? "text" : "password"}
                id="password"
                className="border-0 w-full ps-3"
                name="password"
                placeholder="New password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 7,
                    message: "Minimum length must be 7 characters",
                  },
                  maxLength: {
                    value: 30,
                    message: "Maximum length must be 30 characters",
                  },
                })}
              />
              <span
                className="input-icons cursor-pointer icon-span"
                onClick={() => setViewPass(!viewPass)}
              >
                {viewPass ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.password && (
              <div>
                <FormError message={errors.password.message} />
              </div>
            )}
          </div>
          <div className="mb-1">
            <p className="required">Country</p>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={geoData.countries?.map((country) => ({
                    value: country.name,
                    label: country.name,
                  }))}
                  onChange={(e) => {
                    onCountryChange(e);
                    field.onChange(e);
                  }}
                  value={address.country}
                />
              )}
            />
            <div id="country-error" className="hidden">
              <FormError message={"Country is required"} />
            </div>
          </div>
          {geoData.states.length > 0 && (
            <div className="mb-1">
              <p className="required">State</p>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={geoData.states?.map((state) => ({
                      value: state.name,
                      label: state.name,
                    }))}
                    onChange={(e) => {
                      onStateChange(e);
                      field.onChange(e);
                    }}
                    value={address.state}
                  />
                )}
              />
              <div id="state-error" className="hidden">
                <FormError message={"State is required"} />
              </div>
            </div>
          )}
          {geoData.cities.length > 0 && (
            <div className="mb-1">
              <p className="required">City/District</p>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={geoData.cities?.map((city) => ({
                      value: city.name,
                      label: city.name,
                    }))}
                    onChange={(e) => {
                      onCityChange(e);
                      field.onChange(e);
                    }}
                    value={address.city}
                    styles={{
                      menuList: (baseStyles) => ({
                        ...baseStyles,
                        maxHeight: "150px",
                        overflowY: "auto",
                      }),
                    }}
                  />
                )}
              />
              <div id="city-error" className="hidden">
                <FormError message={"City is required"} />
              </div>
            </div>
          )}
          <div className="mb-1 flex-col">
            <label className="required text-sm">Role</label>
            <Controller
              name="role"
              control={control}
              rules={{ required: "Select a role" }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={roleOptions}
                  styles={{
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      maxHeight: "100px",
                      overflowY: "auto",
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      minHeight: "49px",
                    }),
                  }}
                  closeMenuOnSelect={true}
                />
              )}
            />
            {errors.role && (
              <div>
                <FormError message={errors.role.message} />
              </div>
            )}
          </div>
          <div className="mb-3 mt-2 flex items-center">
            <input
              className="me-2"
              type="checkbox"
              id="terms-conditions"
              name="terms"
              required
              onChange={(e) => {
                if (e.target.checked) {
                  setOpenPolicyModal(true);
                }
              }}
            />
            <label
              className="mb-0 text-xs sm:text-sm"
              htmlFor="terms-conditions"
            >
              I agree to
              <span className="ms-1 text-blue-500">privacy policy & terms</span>
            </label>
          </div>
          <Button
            type="submit"
            className={`py-1.5 w-full ${
              isClicked && "disabled cursor-not-allowed"
            }`}
            bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
            disabled={isClicked}
          >
            Register
          </Button>
        </form>
        {isLoading && !error ? <Loader pt="10px" classes="my-2" /> : null}
        <p className="text-center mt-2">
          <span>Already have an account?</span>
          <Link to="/">
            <span className="ps-1 text-blue-500">Sign in</span>
          </Link>
        </p>
      </div>
      <PrivacyPolicy
        open={openPolicyModal}
        onClose={() => setOpenPolicyModal(false)}
      />
    </div>
  );
};
