export const FilledStar = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 308.000000 296.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,296.000000) scale(0.100000,-0.100000)"
        fill="#FE9500"
        stroke="none"
      >
        <path
          d="M1376 2368 c-92 -288 -171 -529 -176 -537 -6 -11 -105 -13 -576 -12
            -500 2 -567 0 -554 -13 8 -8 215 -159 460 -336 245 -177 446 -323 447 -324 1
            -1 -76 -242 -172 -536 -96 -294 -174 -538 -172 -541 1 -4 208 143 460 327
            l457 334 237 -172 c130 -95 336 -246 457 -334 122 -89 223 -160 224 -158 2 1
            -74 240 -169 531 -94 290 -172 533 -173 539 -1 7 58 54 129 106 72 51 198 143
            280 203 83 59 228 164 322 233 95 68 176 128 179 133 4 6 -205 9 -562 9 -466
            -2 -568 1 -575 12 -4 7 -83 248 -175 536 -93 287 -171 522 -174 522 -3 0 -81
            -235 -174 -522z"
        ></path>
      </g>
    </svg>
  );
};

export const HalfStar = () => {
  return (
    <svg
      width="17"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.049 19.6674L12.7852 19.5035L12.5214 19.6674L5.63593 23.9443L7.46834 15.8605L7.53352 15.573L7.31345 15.3768L1.19706 9.9237L9.23604 9.22179L9.53906 9.19533L9.65488 8.91408L12.7852 1.31303L15.9155 8.91408L16.0313 9.19533L16.3343 9.22179L24.3733 9.9237L18.2569 15.3768L18.0369 15.573L18.102 15.8605L19.9344 23.9443L13.049 19.6674Z"
        fill="FE9500"
        stroke="#F3B816"
      />
      <mask id="path-2-inside-1_788_58" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7852 0L9.19255 8.72368L0 9.52632L6.98071 15.75L4.88394 25L12.7852 20.0921V0Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7852 0L9.19255 8.72368L0 9.52632L6.98071 15.75L4.88394 25L12.7852 20.0921V0Z"
        fill="#FE9500"
      />
      <path
        d="M12.7852 0H13.7852L11.8605 -0.380798L12.7852 0ZM9.19255 8.72368L9.27953 9.71989L9.88556 9.66698L10.1172 9.10448L9.19255 8.72368ZM0 9.52632L-0.0869823 8.53011L-2.39413 8.73155L-0.665474 10.2727L0 9.52632ZM6.98071 15.75L7.95597 15.9711L8.08633 15.396L7.64619 15.0036L6.98071 15.75ZM4.88394 25L3.90869 24.7789L3.37996 27.1114L5.41159 25.8495L4.88394 25ZM12.7852 20.0921L13.3128 20.9416L13.7852 20.6482V20.0921H12.7852ZM11.8605 -0.380798L8.26789 8.34289L10.1172 9.10448L13.7098 0.380798L11.8605 -0.380798ZM9.10557 7.72747L-0.0869823 8.53011L0.0869823 10.5225L9.27953 9.71989L9.10557 7.72747ZM-0.665474 10.2727L6.31524 16.4964L7.64619 15.0036L0.665474 8.77989L-0.665474 10.2727ZM6.00546 15.5289L3.90869 24.7789L5.8592 25.2211L7.95597 15.9711L6.00546 15.5289ZM5.41159 25.8495L13.3128 20.9416L12.2575 19.2426L4.3563 24.1505L5.41159 25.8495ZM13.7852 20.0921V0H11.7852V20.0921H13.7852Z"
        fill="#FE9500"
        mask="url(#path-2-inside-1_788_58)"
      />
    </svg>
  );
};

export const EmptyStar = ({
  width = "20",
  height = "25",
  stroke = "#F3B816",
  fill = "#e3e3e3",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.049 19.6674L12.7852 19.5035L12.5214 19.6674L5.63593 23.9443L7.46834 15.8605L7.53352 15.573L7.31345 15.3768L1.19706 9.9237L9.23604 9.22179L9.53906 9.19533L9.65488 8.91408L12.7852 1.31303L15.9155 8.91408L16.0313 9.19533L16.3343 9.22179L24.3733 9.9237L18.2569 15.3768L18.0369 15.573L18.102 15.8605L19.9344 23.9443L13.049 19.6674Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
