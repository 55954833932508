export const Button = ({
  children,
  type = "button",
  className = "",
  bgColor = "bg-primary",
  textColor = "text-white",
  ...props
}) => {
  return (
    <button
      type={type}
      className={`rounded-md font-medium text-sm ${bgColor} ${textColor} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export const Pagination = ({
  page,
  setPage,
  dataCount = 7,
  totalDataCount,
}) => {
  return (
    <div className="pagination">
      <Button
        className="px-2 py-1 mx-1 text-sm"
        bgColor="bg-black"
        disabled={page < 2}
        onClick={() => setPage(page - 1)}
      >
        Prev
      </Button>
      <span>
        {page}/{Math.ceil(totalDataCount / dataCount)}
      </span>
      <Button
        className="px-2 py-1 mx-1 text-sm"
        bgColor="bg-black"
        disabled={page >= Math.ceil(totalDataCount / dataCount)}
        onClick={() => setPage(page + 1)}
      >
        Next
      </Button>
    </div>
  );
};
