import { Link } from "react-router-dom";
import "./Card.css";
import PHcoin from "../../../images/PH-coin.svg";

const AmountCard = ({ credits, profile_completion, userRole }) => {
  return (
    <div className="balance-card-bg">
      <div className="flex flex-col justify-around">
        {userRole !== "Sw%aa" ? (
          <>
            <span className="font-medium">Remaining Balance</span>
            <h6 className="font-semibold">
              Each token of effort is a step closer to success.
            </h6>
            <div className="mt-1 font-medium flex items-center gap-x-1">
              <span className="text-lg">{credits}</span>
              <img src={PHcoin} alt="Ph coin" />
              {userRole === "f@!3A" ? (
                <Link
                  className="block px-3 py-1.5 ml-3 text-xs w-max button bg-white text-black font-semibold rounded-full"
                  to="/store"
                >
                  Top Up Balance
                </Link>
              ) : (
                <Link
                  className="block px-3 py-1.5 ml-3 text-xs w-max button bg-white text-black font-semibold rounded-full"
                  to="/mentor-wallet"
                >
                  Withdraw
                </Link>
              )}
            </div>
          </>
        ) : (
          <span className="w-8/12 mb-3 font-medium text-sm">
            Complete your profile to find a better match
          </span>
        )}
      </div>
      <div
        className="profile-percent-circle"
        title="Profile completion percentage"
      >
        <p className="font-medium w-max">Profile Completion</p>
        <svg
          className={`circle-svg ${
            profile_completion >= 90
              ? "percent-90"
              : profile_completion >= 70
              ? "percent-70"
              : ""
          }`}
        >
          <circle cx="53" cy="53" r="45"></circle>
          <circle
            cx="53"
            cy="53"
            r="45"
            style={{ "--percent": profile_completion }}
          ></circle>
        </svg>
        <span className="number">{profile_completion}%</span>
      </div>
    </div>
  );
};

export default AmountCard;
