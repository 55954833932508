import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex } from "../../../../constants";

export const WithdrawInvestment = ({
  open,
  onClose,
  accountDetails,
  withdrawInvestment,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const handleModalSubmit = (data, e) => {
    e.preventDefault();
    withdrawInvestment(data.withdrawnAmount);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 text-base">
          Amount Withdrawal
        </h4>
        <form
          onSubmit={handleSubmit(handleModalSubmit)}
          className="p-3 text-left"
        >
          <p className="font-medium">
            Total Investment : <span>{accountDetails.total}</span>
          </p>
          <p className="font-medium">
            Balance Amount : <span>{accountDetails.balance}</span>
          </p>
          <div className="flex mt-2">
            <span className="me-2 font-semibold text-xs">Note :</span>
            <span className="mb-0 font-medium text-xs">
              Balance amount must be atleast 2000.
            </span>
          </div>
          <div className="mt-3">
            <label
              htmlFor="withdrawnAmount"
              className="font-medium text-base required"
            >
              Enter the amount
            </label>
            <input
              type="number"
              id="withdrawnAmount"
              className="w-full px-2 py-1 border border-gray-400 rounded-md focus:ring-1 focus:ring-indigo-600"
              {...register("withdrawnAmount", {
                required: "Amount is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                validate: {
                  notZero: (fieldValue) => {
                    return fieldValue > 0 || "Amount cannot be 0";
                  },
                  checkBalance: (fieldValue) => {
                    return (
                      accountDetails.balance >= fieldValue ||
                      "Amount entered must be lower than or equal to 'Balance Amount'"
                    );
                  },
                  MinAmount: (fieldValue) => {
                    return (
                      fieldValue >= 2000 ||
                      "Balance amount must be atleast 2000"
                    );
                  },
                },
              })}
            />
          </div>
          {errors.withdrawnAmount && (
            <div>
              <FormError message={errors.withdrawnAmount.message} />
            </div>
          )}
          <div className="modalFooter p-3">
            <Button type="submit" className="px-2 py-1 mx-1 text-sm">
              Withdraw
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
