import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { TicketModal } from "../../components/ui/modals/ticket/ticketModal";
import { Button, Pagination } from "../../components/ui/Button";
import { CircleXmarkIcon, EyeIcon } from "../../icons/icons";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const ComplaintTickets = () => {
  const getSupportTicketsApi = `${process.env.REACT_APP_API_URL}display-support-tickets`;
  const cancelTicketApi = `${process.env.REACT_APP_API_URL}cancel-ticket`;
  const supportTicketApi = `${process.env.REACT_APP_API_URL}support-ticket`;

  const toast = useToastContext();

  const [tickets, setTickets] = useState([]);
  const [ticketCount, setTicketCount] = useState(0);
  const [page, setPage] = useState(1);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const ref = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);

  const displaySupportTickets = useCallback(() => {
    fetchApi(
      {
        url: getSupportTicketsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setTickets(data.support_ticket);
        setTicketCount(data.ticket_count);
      }
    );
  }, [getSupportTicketsApi, fetchApi, token]);

  const cancelTicket = useCallback(
    (ticketId) => {
      setIsError(false);
      fetchApi(
        {
          url: cancelTicketApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: ticketId,
          },
        },
        (data) => {
          if (data.message !== "cancelled") {
            setIsError(true);
            return;
          }
          displaySupportTickets();
        }
      );
    },
    [cancelTicketApi, fetchApi, token, displaySupportTickets]
  );

  const supportTicket = useCallback(
    (file, data) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("support_type", data.complaintCategory.replace("-", " "));
      formData.append("ticket_heading", data.complaintHeading);
      formData.append("query", data.complaintQuery);
      formData.append("date", getCurrentDate());
      axios
        .post(supportTicketApi, formData, {
          headers: { "x-token": token },
        })
        .then((res) => {
          if (res.status === 200) {
            toast.open(
              "true",
              "Complaint registered successfully!. We will get back to you soon."
            );
            displaySupportTickets();
          }
        })
        .catch(() => toast.open("false", "Failed to create ticket"));
    },
    [supportTicketApi, token, toast, displaySupportTickets]
  );

  useEffect(() => {
    if (ref.current) {
      displaySupportTickets();
    }
    return () => {
      ref.current = false;
    };
  }, [displaySupportTickets]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-3 md:p-5 h-full">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <>
              <div className="flex flex-col sm:flex-row items-end sm:items-center justify-between gap-3 mt-3">
                <p className="mb-0 order-2 sm:order-1">
                  <span className="font-bold pe-1">COMPLAINTS RAISED : </span>
                  {ticketCount}
                </p>
                <Button
                  bgColor="bg-[#F97316]"
                  className="py-1.5 px-2 order-1 sm:order-2"
                  onClick={() => setOpenTicketModal(true)}
                >
                  Raise Support Ticket
                </Button>
              </div>
              {isError && (
                <p className="text-red-500">
                  Sorry, Ticket cancellelation failed. Please try again later or
                  raise a support ticket for help.
                </p>
              )}
              <div className="overflow-auto md:overflow-none md:w-full">
                <table className="table-auto min-w-max w-full mt-4 border">
                  <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
                    <tr>
                      <th className="p-3 font-medium text-xs md:text-sm">
                        Created On
                      </th>
                      <th className="p-3 font-medium text-xs md:text-sm">
                        Complaint Category
                      </th>
                      <th className="p-3 font-medium text-xs md:text-sm">
                        Status
                      </th>
                      <th className="p-3 font-medium text-xs md:text-sm">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.length > 0 ? (
                      tickets.slice(page * 7 - 7, page * 7).map((ticket) => (
                        <tr
                          key={ticket._id}
                          className="bg-white border-b border-slate-300 align-middle h-auto"
                        >
                          <td className="p-3 text-xs md:text-sm">
                            {ticket.date}
                          </td>
                          <td className="p-3 text-xs md:text-sm capitalize">
                            {ticket.support_type}
                          </td>
                          <td className="p-3">
                            <span
                              className={`p-1 text-xs ${
                                ticket.status === "0"
                                  ? "red-badge"
                                  : ticket.status === "1"
                                  ? "orange-badge"
                                  : "green-badge"
                              }`}
                            >
                              {ticket.status === "0"
                                ? "Pending"
                                : ticket.status === "1"
                                ? "Under Review"
                                : "Solved"}
                            </span>
                          </td>
                          <td className="p-3">
                            <div className="flex items-center justify-evenly gap-2 h-full">
                              <Link
                                to={`/ticket/${ticket._id}`}
                                title="View Ticket Details"
                              >
                                <EyeIcon className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800" />
                              </Link>
                              {ticket.status !== "2" ? (
                                <span title="Delete Ticket">
                                  <CircleXmarkIcon
                                    width="18px"
                                    height="18px"
                                    onClick={() => cancelTicket(ticket._id)}
                                    className="cursor-pointer fill-red-500  transition-colors ease-in duration-50 hover:fill-red-800"
                                  />
                                </span>
                              ) : (
                                <span title="Delete Ticket">
                                  <CircleXmarkIcon
                                    width="18px"
                                    height="18px"
                                    className="fill-red-500 disabled"
                                  />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="bg-white text-center">
                        <td colSpan={4} className="p-3 text-xs md:text-sm">
                          No complaints raised
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {tickets.length > 0 && (
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalDataCount={tickets.length}
                />
              )}
            </>
          )}
        </div>
        <TicketModal
          open={openTicketModal}
          onClose={() => setOpenTicketModal(false)}
          supportTicket={supportTicket}
        />
      </div>
    </div>
  );
};
