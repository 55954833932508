import React, { useState } from "react";
import { Pagination } from "../../components/ui/Button";
import { EyeIcon, CircleXmarkIcon, CircleCheck } from "../../icons/icons";
import FavIcon from "../../images/favicon.png";

export const IncomingRequests = ({
  incomingRequest,
  handleConfirmationModal,
  viewProfile,
}) => {
  const [page, setPage] = useState(1);

  return (
    <>
      <h4 className="mb-1 md:mb-4 font-semibold md:text-lg">
        Incoming Connection Requests
      </h4>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-xs md:text-sm">
                Requester Name
              </th>
              <th className="p-3 font-medium text-xs md:text-sm">
                Requested On
              </th>
              <th className="p-3 font-medium text-xs md:text-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            {incomingRequest.length > 0 ? (
              incomingRequest.slice(page * 7 - 7, page * 7).map((data) => (
                <tr
                  key={data.sender_id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm">
                    <div
                      onClick={() =>
                        viewProfile(data.name, data.role, data.sender_id)
                      }
                      className="flex items-center gap-x-2 cursor-pointer"
                    >
                      <img
                        src={data?.logo.length > 0 ? data.logo : FavIcon}
                        alt="User"
                        width={50}
                        className="h-[50px] rounded-full border"
                        loading="lazy"
                      />
                      <div>
                        <p className="font-medium">{data.name}</p>
                        <span
                          className={`px-1 text-xs ${
                            data.role === "f@!3A"
                              ? "blue-badge"
                              : data.role === "R$7s2"
                              ? "orange-badge"
                              : data.role === "Sw%aa"
                              ? "green-badge"
                              : "indigo-badge"
                          }`}
                        >
                          {data.role === "f@!3A"
                            ? "Startup"
                            : data.role === "R$7s2"
                            ? "Mentor"
                            : data.role === "Sw%aa"
                            ? "Investor"
                            : "Vendor"}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="p-3 text-xs md:text-sm">{data.date}</td>
                  <td id="requestorId" className="p-3 text-xs md:text-sm">
                    <div className="flex items-center justify-evenly gap-2 h-full md:w-7/12">
                      <span title="View Profile">
                        <EyeIcon
                          width="20px"
                          height="20px"
                          onClick={() =>
                            viewProfile(data.name, data.role, data.sender_id)
                          }
                          className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                        />
                      </span>
                      <span title="Accept Connection Request">
                        <CircleCheck
                          width="20px"
                          height="20px"
                          className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800"
                          onClick={() =>
                            handleConfirmationModal(
                              "accept",
                              data.sender_id,
                              "incoming",
                              data.role
                            )
                          }
                        />
                      </span>
                      <span title="Reject Connection Request">
                        <CircleXmarkIcon
                          width="20px"
                          height="20px"
                          onClick={() =>
                            handleConfirmationModal(
                              "cancel",
                              data.sender_id,
                              "incoming",
                              data.role
                            )
                          }
                          className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan="3" className="p-3 text-xs md:text-sm">
                  No Incoming Requests
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {incomingRequest.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={incomingRequest.length}
        />
      )}
    </>
  );
};
