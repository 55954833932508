import ReactDom from "react-dom";
import "../ticket/ticketModal.css";

export const ViewInvestmentHistory = ({
  open,
  onClose,
  agreementDetails,
  userRole,
}) => {
  if (!open) return null;
  let sno = 1;

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[75%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <div>
          <p onClick={onClose} className="closeBtn">
            X
          </p>
          <h4 className="modal-heading text-black font-semibold text-center border-b p-3 text-base">
            Investment History
          </h4>
          <div className="p-3">
            <p>
              <span className="font-medium me-1">Total Agreed Amount :</span> ₹{" "}
              {agreementDetails?.amount}
            </p>
            <p>
              <span className="font-medium me-1">Released Amount :</span> ₹{" "}
              {agreementDetails?.released_amount}
            </p>
            <p className={agreementDetails?.pending_amount < 0 && "bg-red-500"}>
              <span
                className={`font-medium me-1 ${
                  agreementDetails?.pending_amount < 0 && "bg-red-500"
                }`}
              >
                Pending Amount :
              </span>{" "}
              ₹ {agreementDetails?.pending_amount}
            </p>
            {userRole === "f@!3A" && (
              <>
                <p>
                  <span className="font-medium me-1">Platform Fee :</span>
                  {agreementDetails?.platform_fee}%
                </p>
                <p className="text-xs">
                  <span className="font-semibold me-1">Note* :</span>
                  Withdrawable amount is ( released amount - platform fee )
                </p>
              </>
            )}
            <table className="table-auto w-full mt-4 border shadow-sm border-slate-300">
              <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
                <tr>
                  <th className="p-3 font-medium text-sm">S.No</th>
                  <th className="p-3 font-medium text-sm whitespace-nowrap">
                    Released Amount
                  </th>
                  {userRole === "f@!3A" && (
                    <th className="p-3 font-medium text-sm whitespace-nowrap">
                      Withdrawable Amount
                    </th>
                  )}
                  <th className="p-3 font-medium text-sm whitespace-nowrap">
                    Released Date
                  </th>
                  <th className="p-3 font-medium text-sm whitespace-nowrap">
                    Admin approval
                  </th>
                  <th className="p-3 font-medium text-sm whitespace-nowrap">
                    Transfered to startup
                  </th>
                </tr>
              </thead>
              <tbody>
                {agreementDetails?.transactions_history.length > 0 ? (
                  agreementDetails.transactions_history
                    .slice()
                    .reverse()
                    .map((transaction) => (
                      <tr
                        key={transaction._id}
                        className="bg-white border-b border-slate-300 align-middle h-auto"
                      >
                        <td className="p-3 text-sm font-medium">{sno++}</td>
                        <td className="p-3 text-sm">
                          <span className="font-medium mr-1">&#8377;</span>
                          {transaction.amount}
                        </td>
                        {userRole === "f@!3A" && (
                          <td className="p-3 text-sm">
                            {transaction.withdrable_amount}
                          </td>
                        )}
                        <td className="p-3 text-sm">{transaction.date}</td>
                        <td className="p-3 text-sm">
                          <span
                            className={`px-2 py-0.5 ${
                              transaction.status_by_admin === 0
                                ? "orange-badge"
                                : transaction.status_by_admin === 1
                                ? "green-badge"
                                : "red-badge"
                            }`}
                          >
                            {transaction.status_by_admin === 0
                              ? "Pending"
                              : transaction.status_by_admin === 1
                              ? "Approved"
                              : "Rejected"}
                          </span>
                        </td>
                        <td className="p-3 text-sm">
                          <span
                            className={`px-2 py-0.5 ${
                              transaction.transfer_to_startup_status === 0
                                ? "orange-badge"
                                : "green-badge"
                            }`}
                          >
                            {transaction.transfer_to_startup_status === 0
                              ? "Pending"
                              : "Transfered"}
                          </span>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr className="bg-white text-center">
                    <td colSpan={5} className="p-3 text-sm">
                      No Transaction Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
