const RecentTransaction = ({ data }) => {
  return (
    <div className="bg-white shadow-lg rounded-xl overflow-auto w-full md:overflow-none">
      <h6 className="font-medium text-lg p-3">Recent Transactions</h6>
      <table className="table-auto min-w-max w-full rounded-b-xl overflow-hidden">
        <thead className="!bg-transparent !text-gray-400 text-left">
          <tr>
            <th className="p-2 font-medium text-xs">Date</th>
            <th className="p-2 font-medium text-xs">Amount</th>
            <th className="p-2 font-medium text-xs">Plan Type</th>
            <th className="p-2 font-medium text-xs">Status</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.slice(0, 6).map((transaction) => (
              <tr
                className="bg-white border-t border-slate-200"
                key={transaction.id}
              >
                <td className="p-3 text-xs sm:text-sm">{transaction.date}</td>
                <td className="p-3 text-xs sm:text-sm">
                  <span className="font-medium mr-1">&#8377;</span>
                  {transaction.amount}
                </td>
                <td className="p-3">
                  <span
                    className={`p-1 text-xs capitalize ${
                      transaction.plantype === "top up"
                        ? "blue-badge"
                        : "yellow-badge"
                    }`}
                  >
                    {transaction.plantype}
                  </span>
                </td>
                <td className="p-3">
                  <span
                    className={`p-1 text-xs ${
                      transaction.status === "200" ? "green-badge" : "red-badge"
                    }`}
                  >
                    {transaction.status === "200" ? "Success" : "Failed"}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white text-center">
              <td colSpan={4} className="p-3 text-xs sm:text-sm text-gray-400">
                No transaction found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecentTransaction;
