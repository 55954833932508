import React, { useEffect } from "react";
import axios from "axios";
import useCheckRole from "../../hooks/useCheckRole";
import useLocalStorage from "../../hooks/useLocalStorage";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { useToastContext } from "../../context/ToastContext";
import { ResponsiveSidebar } from "./responsiveSidebar";
import { RegularSidebar } from "./regularSidebar";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";
import { getCurrentDate } from "../../utils/getCurrentDate";

const Sidebar = ({ openSidebar, closeSidebar, style }) => {
  const supportTicketApi = `${process.env.REACT_APP_API_URL}support-ticket`;

  const { token, userRole } = useLocalStorage();
  const { role, CheckRole } = useCheckRole();
  const { width } = useWindowDimensions();
  const toast = useToastContext();

  const supportTicket = (file, data) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("support_type", data.complaintCategory.replace("-", " "));
    formData.append("ticket_heading", data.complaintHeading);
    formData.append("query", data.complaintQuery);
    formData.append("date", getCurrentDate());
    axios
      .post(supportTicketApi, formData, {
        headers: { "x-token": token },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.open(
            "true",
            "Complaint registered successfully!. We will get back to you soon."
          );
        }
      })
      .catch(() => toast.open("false", "Sorry, Failed to create ticket"));
  };

  useEffect(() => {
    CheckRole(userRole);
  }, [CheckRole, userRole]);

  return (
    <>
      {openSidebar && width < 1024 && (
        <ResponsiveSidebar
          supportTicket={supportTicket}
          role={role}
          closeSidebar={closeSidebar}
          style={style}
        />
      )}
      {width >= 1024 && (
        <RegularSidebar
          supportTicket={supportTicket}
          role={role}
          style={style}
        />
      )}
    </>
  );
};

export default Sidebar;
