import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import PlanForm from "./planForm";

const EditPlan = ({ open, onClose, singlePlan, updateSubscription }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      planFor: singlePlan.type,
      planName: singlePlan.plan_type,
      planPrice: singlePlan.amount,
      planTokens: singlePlan.points,
      discount: singlePlan.discount_status,
      type: singlePlan.discount_type,
      discountAmount: singlePlan.discount,
    },
  });

  const [features, setFeatures] = useState([]);
  const ref = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      if (singlePlan.plan_features) {
        setFeatures([...singlePlan.plan_features]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [singlePlan]);

  if (!open) return null;

  const submitModal = (data) => {
    if (features.length < 1) {
      document.getElementById("feature-required").classList.remove("hidden");
      return;
    } else {
      document.getElementById("feature-required").classList.add("hidden");
    }
    updateSubscription(data, features, singlePlan._id);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-full max-w-[90%] md:max-w-[750px] max-h-[95vh] overflow-y-auto bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 capitalize text-base">
          Edit Token Plan
        </h4>
        <PlanForm
          submitModal={submitModal}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          features={features}
          setFeatures={setFeatures}
        />
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default EditPlan;
