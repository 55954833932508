import ReactDom from "react-dom";
import { Button } from "../../Button";

export const DeleteModal = ({
  open,
  onClose,
  id,
  deleteFile,
  deleteBankDetails,
  deletePortfolioFile,
  deleteMethod,
  type,
  fileType,
}) => {
  if (!open) return null;

  const submitModal = () => {
    if (type === "file") {
      deleteFile(fileType, id);
    } else if (type === "bank") {
      deleteBankDetails(id);
    } else if (type === "portfolio") {
      deletePortfolioFile(id);
    } else {
      deleteMethod(id);
    }
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <div className="p-3 pb-0 mt-2 text-center">
          <p>Are you sure you want to Delete ?</p>
        </div>
        <div className="flex justify-center p-3">
          <Button
            className="px-2 py-1 mx-1"
            bgColor="bg-secondary"
            onClick={submitModal}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
