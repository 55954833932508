import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import { bannedInputs } from "../../constants";
import { BuildingIcon, UploadIcon } from "../../icons/icons";
import { useToastContext } from "../../context/ToastContext";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { getCurrentDate } from "../../utils/getCurrentDate";

export const VendorDetailsForm = ({ token, industries }) => {
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}insert-vendor-details`;

  const navigate = useNavigate();
  const { isLoading, error } = useFetch();
  const toast = useToastContext();

  const [expertise, setExpertise] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const VendorDetailsApiCall = (data) => {
    setIsClicked(true);
    document.getElementById("expertise-error").classList.add("hidden");
    document.getElementById("limit").classList.add("hidden");
    if (expertise.length < 1) {
      document.getElementById("expertise-error").classList.remove("hidden");
      return;
    }
    if (expertise.length > 3) {
      document.getElementById("limit").classList.remove("hidden");
      return;
    }
    let logo = document.getElementById("companyLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("company_name", data.vendorCompany);
    formData.append("description", data.vendorBio);
    formData.append("expert_in", expertise);
    formData.append("date", getCurrentDate());
    axios
      .post(vendorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        setIsClicked(false);
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/vendor-profile");
      })
      .catch(() => toast.open("false", "Failed to submit"));
  };

  return (
    <div
      id="vendorDetailsForm"
      className="flex col-span-7 authentication-bg relative bg-white md:items-center pb-3 px-4 md:px-0 md:py-0 md:my-3 lg:my-0 lg:py-3 md:col-start-2 md:col-span-5 lg:col-span-2"
    >
      <form
        onSubmit={handleSubmit(VendorDetailsApiCall)}
        className="mx-auto p-5 rounded-md shadow-md md:w-full lg:p-3 lg:shadow-none lg:rounded-none lg:pt-0"
      >
        <h4 className="text-lg font-medium">
          Some details about your business
        </h4>
        <div className="mb-1">
          <label htmlFor="vendorCompany" className="text-sm mb-0 required">
            Company Name
          </label>
          <div
            className="flex rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="border-0 w-full ps-3"
              id="vendorCompany"
              name="vendorCompany"
              placeholder="Enter Company Name"
              {...register("vendorCompany", {
                required: "Company name is required",
                validate: (fieldValue) => {
                  return (
                    !bannedInputs.some((value) => fieldValue.includes(value)) ||
                    "Enter a different name"
                  );
                },
                minLength: {
                  value: 3,
                  message: "Must be at least 3 characters long",
                },
                maxLength: {
                  value: 100,
                  message: "Max length is 100 characters",
                },
              })}
            />
            <span className="input-icons icon-span">
              <BuildingIcon />
            </span>
          </div>
          {errors.vendorCompany && (
            <div>
              <FormError message={errors.vendorCompany.message} />
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="companyLogo" className="text-sm mb-0">
            Company Logo
          </label>
          <div
            className="flex items-center rounded-md overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/*"
              className="border-0 w-full ps-3"
              id="companyLogo"
              name="companyLogo"
              placeholder="Upload Profile Image"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        <div className="mb-1">
          <label htmlFor="vendorBio" className="text-sm mb-0 required">
            Short description about your company
          </label>
          <div className="border mb-3">
            <textarea
              name="vendorBio"
              id="vendorBio"
              cols="30"
              rows="4"
              placeholder="Write here..."
              className="border-0 w-full ps-3 pt-1 rounded-md"
              {...register("vendorBio", {
                required: "Bio is required",
                validate: {
                  minLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length > 29 ||
                      "Bio must be at least 30 characters"
                    );
                  },
                  maxLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length < 1001 ||
                      "Cannot exceed 1000 characters"
                    );
                  },
                },
              })}
            ></textarea>
          </div>
          {errors.vendorBio && (
            <div>
              <FormError message={errors.vendorBio.message} />
            </div>
          )}
        </div>
        <div className="mb-1 flex-col">
          <p>
            Company expertise{" "}
            <span className="required">({expertise.length + "/3"})</span>
            <span id="limit" className="hidden text-red-500 ms-2">
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setExpertise(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small className="text-red-500 mb-2 hidden" id="expertise-error">
            This field is mandatory.
          </small>
        </div>
        {isLoading && !error ? <Loader pt="10px" /> : null}
        <Button
          type="submit"
          className={`py-1.5 mt-3 w-full ${
            isClicked && "disabled cursor-not-allowed"
          }`}
          bgColor={isClicked ? "bg-gray-400" : "bg-primary"}
          disabled={isClicked}
        >
          Next
        </Button>
      </form>
    </div>
  );
};
