import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext, VideoClientContext } from "../../context/videoContext";
import { useToastContext } from "../../context/ToastContext";
import socket from "../../utils/socketConnection";
import { Button } from "../../components/ui/Button";

const VideoCall = () => {
  const uitoolkit = useContext(VideoClientContext);
  const meetingArgs = useContext(UserContext);
  const toast = useToastContext();
  const navigate = useNavigate();
  const { roomId, meetingId, userId } = useParams();

  const runOnce = useRef(false);

  const meetingEnd = useCallback(() => {
    document.getElementById("timer-container").classList.remove("hidden");
    document.getElementById("timer-container").classList.add("flex");
    setTimeout(() => {
      navigate(`/meeting-summary/${meetingId}`);
    }, 120000);
  }, [meetingId, navigate]);

  useEffect(() => {
    if (!uitoolkit || !meetingArgs) {
      toast.open("false", "Failed to join meeting! Please try again.");
      navigate("/meetings");
      return;
    }
    if (!runOnce.current) {
      socket.emit("room:join", {
        userId: userId,
        roomId: roomId,
      });
    }

    let video = document.getElementById("videoContainer");
    uitoolkit.joinSession(video, meetingArgs);
    uitoolkit.onSessionClosed(() => navigate("/meetings"));

    socket.on("meet:end", meetingEnd);

    return () => {
      uitoolkit.offSessionClosed(() => navigate("/meetings"));
      uitoolkit.closeSession(video);
      socket.emit("cancel", {
        userId: userId,
      });
      socket.removeAllListeners();
    };
  }, [uitoolkit, meetingArgs, navigate, toast, roomId, userId, meetingEnd]);

  return (
    <>
      <div id="videoContainer"></div>
      <div id="timer-container" className="hidden">
        <p className="font-medium mb-3">
          Meeting will automatically end in 2 minutes.
        </p>
        <Button
          onClick={() => {
            document.getElementById("timer-container").classList.add("hidden");
            document.getElementById("timer-container").classList.remove("flex");
          }}
          className="px-2 py-1"
        >
          Okay
        </Button>
      </div>
    </>
  );
};

export default VideoCall;
