import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: [],
  reducers: {
    setChatRoom(state, action) {
      state.push(action.payload);
    },
    deleteChatRoom(state, action) {
      return [];
    },
    updateStatus(state, action) {
      return [{ ...state[0], status: action.payload }];
    },
  },
});

export default chatSlice.reducer;
export const { setChatRoom, deleteChatRoom, updateStatus } = chatSlice.actions;
