import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useSuspendApi from "../../../../hooks/useSuspendApi";
import { Button } from "../../Button";

export const SuspendModal = ({ open, onClose, id, callBackFn }) => {
  const { Suspend } = useSuspendApi();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data) => {
    Suspend(id, data.reason, callBackFn);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[500px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <form onSubmit={handleSubmit(submitModal)} className="p-3 mt-5">
          <h4 class="text-lg font-medium mb-2">Reason for suspension</h4>
          <textarea
            name="reason"
            id="reason"
            cols="30"
            rows="7"
            placeholder="Write here..."
            className="border p-1.5 rounded-md"
            {...register("reason", {
              required: "Reason is required",
              minLength: {
                value: 50,
                message: "Reason must be atleast 50 characters",
              },
            })}
          ></textarea>
          {errors.reason && (
            <span className="my-1 text-secondary text-xs">
              {errors.reason.message}
            </span>
          )}
          <div className="flex justify-end p-2">
            <Button type="submit" className="px-2 py-1 mx-1 text-sm">
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
