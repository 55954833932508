import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex } from "../../../../constants";

export const EditBidModal = ({ open, onClose, bidData, editBid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bidAmount: bidData.bid_amount,
      workDuration: bidData.bid_deadline,
      bidDescription: bidData.bid_description,
    },
  });

  if (!open) return null;
  const submitModal = (data, e) => {
    e.preventDefault();
    editBid(data, bidData.bid_id);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:max-w-[50%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-base">
          Edit Bid
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="grid grid-cols-2 gap-2 p-2 pb-0"
        >
          <span className="hidden text-red-500 text-sm" id="mandatory-error">
            All fields are mandatory
          </span>
          <div className="col-span-2 flex flex-col">
            <p htmlFor="ad-title" className="font-medium">
              Bidding for
            </p>
            <p className="p-2 max-h-28 overflow-auto border rounded bg-slate-100">
              {bidData.title}
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="minBudget" className="mb-1 font-medium text-sm">
              Minimum budget
            </label>
            <input
              type="text"
              className="p-1 border rounded-md"
              value={bidData.min_budget}
              disabled
              readOnly
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="bidAmount" className="mb-1 font-medium text-sm">
              Bid Amount*
            </label>
            <input
              type="number"
              id="bidAmount"
              className="p-1 border rounded-md"
              placeholder="Enter bid amount"
              {...register("bidAmount", {
                required: "bid amount is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                validate: {
                  notZero: (fieldValue) => {
                    return fieldValue > 0 || "Amount cannot be 0";
                  },
                  minAmount: (fieldValue) => {
                    return fieldValue > 0
                      ? fieldValue >= bidData.min_budget ||
                          "Amount cannot be less than minimum project budget"
                      : null;
                  },
                },
              })}
            />
            {errors.bidAmount && (
              <div>
                <FormError message={errors.bidAmount.message} />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="workDuration"
              className="mb-1 font-medium text-sm required"
            >
              Work Duration*(days)
            </label>
            <input
              type="number"
              id="workDuration"
              className="p-1 border rounded-md"
              placeholder="Enter work duration"
              {...register("workDuration", {
                required: "Duration is required",
                pattern: {
                  value: numberRegex,
                  message: "Duration must only contain digits",
                },
                validate: (fieldValue) => {
                  return fieldValue > 0 || "Duration cannot be 0";
                },
              })}
            />
            {errors.workDuration && (
              <div>
                <FormError message={errors.workDuration.message} />
              </div>
            )}
          </div>
          <div className="col-span-2 flex flex-col">
            <label
              htmlFor="bidDescription"
              className="mb-1 font-medium text-sm required"
            >
              Bid Description*
            </label>
            <textarea
              name="bidDescription"
              id="bidDescription"
              cols="30"
              rows="7"
              className="p-1 border rounded-md"
              placeholder="Add bid description"
              {...register("bidDescription", {
                required: "description is required",
                minLength: {
                  value: 30,
                  message: "description must be at least 30 characters",
                },
              })}
            ></textarea>
            {errors.bidDescription && (
              <div>
                <FormError message={errors.bidDescription.message} />
              </div>
            )}
          </div>
          <div className="col-start-2 text-right p-2">
            <Button
              className="px-1 py-0.5 mx-1"
              bgColor="bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="px-1 py-0.5 mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
