import FavIcon from "../../images/favicon.png";
import "./chat.css";

const Header = ({ connectedUsersList, getRoomId }) => {
  return (
    <div className="bg-white p-3 mb-3">
      <h4 className="text-lg">Chat</h4>
      <div className="flex items-center justify-start pt-5 pb-3 overflow-auto">
        {connectedUsersList?.map((user) => (
          <img
            key={user.connection_id}
            src={user.logo.length > 0 ? user.logo : FavIcon}
            alt="connections-profile-img"
            width={50}
            loading="lazy"
            className="h-[50px] rounded-full mx-1 border border-slate-400 p-1"
            onClick={() =>
              getRoomId(user.connection_id, user.role, user.status)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Header;
