import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { deleteChatRoom } from "../../store/slices/ChatSlice";
import { updateCredits } from "../../store/slices/FetchCreditsSlice";
import { updateTokensUsed } from "../../store/slices/dashboard/StartupDashboardSlice";
import useLocalStorage from "../../hooks/useLocalStorage";
import useNotificationApi from "../../hooks/useNotificationApi";
import useCheckConnection from "../../hooks/useCheckConnection";
import useUnfollowConnection from "../../hooks/useUnfollowConnection";
import useVerificationApi from "../../hooks/useVerificationApi";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import { useToastContext } from "../../context/ToastContext";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { Stars } from "../../components/ui/modals/rating-modal/Stars";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";
import { ConfirmationModal } from "../../components/ui/modals/utility-modals/confirmationModal";
import NavigateToDashboard from "../../utils/navigateToDashboard";
import { getCurrentDate } from "../../utils/getCurrentDate";
import { capitalizeText } from "../../utils/capitalizeText";
import "./viewProfile.css";
import "./Profile.css";
import {
  BriefCaseIcon,
  BuildingIcon,
  LocationIcon,
  RupeeIcon,
} from "../../icons/icons";
import FavIcon from "../../images/favicon.png";
import { Button } from "../../components/ui/Button";
import PHcoin from "../../images/PH-coin.svg";

const ViewMentorProfile = () => {
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}display-mentor-profile`;
  const sendConnectionRequestApi = `${process.env.REACT_APP_API_URL}send-connect-request`;
  const acceptOrCancelApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;
  const reviewsCountApi = `${process.env.REACT_APP_API_URL}reviews-count`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const { checkConnection } = useCheckConnection();
  const { unFollow } = useUnfollowConnection();
  const { verification } = useVerificationApi();
  const { displayFiles } = useDisplayFiles();
  const toast = useToastContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const location = useLocation();
  const { credits } = useSelector((state) => state.credits);

  const [mentorDetails, setMentorDetails] = useState([]);
  const [latestReviews, setLatestReviews] = useState([]);
  const [video, setVideo] = useState([]);
  const [mentorShortDesc, setMentorShortDesc] = useState("");
  const [connectionStatus, setConnectionStatus] = useState({
    status: "",
    senderId: "",
  });
  const [profileImg, setProfileImg] = useState("");
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    callback: null,
    message: "",
  });
  const ref = useRef(true);

  const reviewsCount = useCallback(() => {
    fetchApi(
      {
        url: reviewsCountApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          user_id: user_id,
        },
      },
      (data) => {
        setReviewCount(data.review_count);
      }
    );
  }, [reviewsCountApi, fetchApi, token, user_id]);

  const getMentorDetails = useCallback(async () => {
    if (!user_id) {
      toast.open("false", "Sorry, something went wrong. Please try again");
      return;
    }
    fetchApi(
      {
        url: mentorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
        },
      },
      (data) => {
        if (data.message === "suspended") {
          toast.open("false", "This accound has been suspended or Deactivated");
          navigate(NavigateToDashboard(userRole));
          return;
        }
        setMentorDetails(data.mentor_profile);
        setLatestReviews(data.latest_reviews);
        setProfileImg(data.mentor_profile[0]?.logo);
        setMentorShortDesc(data.mentor_profile[0]?.short_description);
        setAverageRating(data.mentor_profile[0]?.average_rating);
      }
    );
  }, [user_id, mentorDetailsApi, token, fetchApi, navigate, userRole, toast]);

  useEffect(() => {
    if (userRole === "V&iR8") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getMentorDetails();
      displayFiles((data) => setVideo(data.video), user_id);
      verification(user_id, "verify", setVerifiedStatus);
      checkConnection(user_id, setConnectionStatus);
      reviewsCount();
    }
    return () => {
      ref.current = false;
    };
  }, [
    verification,
    getMentorDetails,
    displayFiles,
    checkConnection,
    reviewsCount,
    navigate,
    userRole,
    user_id,
  ]);

  const acceptOrCancelReq = useCallback(
    (type, receiver = false) => {
      fetchApi(
        {
          url: acceptOrCancelApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            connection_id: user_id,
            type: type,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "accepted") {
            toast.open("true", "Connection request accepted!");
            setConnectionStatus((prev) => ({ ...prev, status: "connected" }));
          }
          if (data.message === "already cancelled") {
            if (userRole === "f@!3A" && !receiver) {
              dispatch(updateCredits({ type: "add", amount: 200 }));
              dispatch(updateTokensUsed({ type: "remove", amount: 200 }));
            }
            toast.open(
              "warning",
              "Sorry, Request was already cancelled by sender!"
            );
            setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
          }
          if (data.message === "cancelled") {
            if (userRole === "f@!3A" && !receiver) {
              dispatch(updateCredits({ type: "add", amount: 200 }));
              dispatch(updateTokensUsed({ type: "remove", amount: 200 }));
            }
            toast.open("true", "Connection request cancelled successfully!");
            setConnectionStatus((prev) => ({ ...prev, status: "disconnect" }));
            sendNotification(
              user_id,
              `Connection cancelled by ${data.name}`,
              "connections"
            );
          }
          if (data.message === "connected") {
            toast.open("warning", "User has already accepted this request");
            setConnectionStatus((prev) => ({ ...prev, status: "connected" }));
          }
        }
      );
    },
    [
      fetchApi,
      token,
      acceptOrCancelApi,
      toast,
      sendNotification,
      user_id,
      dispatch,
      userRole,
    ]
  );

  const sendConnectionRequest = useCallback(() => {
    fetchApi(
      {
        url: sendConnectionRequestApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: user_id,
          date: getCurrentDate(),
        },
      },
      (data) => {
        toast.open("true", "Connection request sent successfully!");
        checkConnection(user_id, setConnectionStatus);
        dispatch(
          updateCredits({
            type: "remove",
            amount: location.state?.connection_fee,
          })
        );
        dispatch(
          updateTokensUsed({
            type: "add",
            amount: location.state?.connection_fee,
          })
        );
        sendNotification(
          user_id,
          `Incoming connection request from ${data.name}`,
          "connections"
        );
      }
    );
  }, [
    sendConnectionRequestApi,
    fetchApi,
    token,
    user_id,
    sendNotification,
    toast,
    checkConnection,
    dispatch,
  ]);

  const routeToChat = () => {
    dispatch(deleteChatRoom());
    navigate("/chat");
  };

  const handleConfirmationModal = (type) => {
    if (type === "unfollow") {
      setConfirmationModal((prev) => ({
        ...prev,
        open: true,
        callback: () => unFollow(user_id, setConnectionStatus),
        message: "Are you sure you want to disconnect?",
      }));
    } else {
      setConfirmationModal({
        open: true,
        callback: () => sendConnectionRequest(user_id),
        message: `There is a one time cost of ${location.state?.connection_fee} tokens to connect with a mentor`,
      });
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-3 md:p-5">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <>
              <div className="grid grid-cols-7 gap-2">
                {userRole === "f@!3A" && (
                  <div className="col-span-7 ml-auto md:hidden">
                    {connectionStatus.status === "connected" && (
                      <>
                        {verifiedStatus && (
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            bgColor="bg-[#F97316]"
                            onClick={() => setOpenReviewModal(true)}
                          >
                            Review
                          </Button>
                        )}
                        <Button
                          className="px-2 py-1 mx-1 text-xs"
                          onClick={() => routeToChat()}
                        >
                          Message
                        </Button>
                        <Button
                          className="px-2 py-1 mx-1 text-xs"
                          bgColor="bg-secondary"
                          onClick={() => handleConfirmationModal("unfollow")}
                        >
                          Disconnect
                        </Button>
                      </>
                    )}
                    {connectionStatus.status === "pending" && (
                      <>
                        <Button
                          className="px-2 py-1 mx-1 text-xs cursor-not-allowed disabled"
                          disabled
                          bgColor="bg-gray-400"
                        >
                          Awaiting Acceptance
                        </Button>
                        {connectionStatus.senderId === user_id ? (
                          <>
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              bgColor="bg-green-700"
                              onClick={() => acceptOrCancelReq("accept", true)}
                            >
                              Accept
                            </Button>
                            <Button
                              className="px-2 py-1 mx-1 text-xs"
                              bgColor="bg-orange-700"
                              onClick={() => acceptOrCancelReq("cancel", true)}
                            >
                              Decline
                            </Button>
                          </>
                        ) : (
                          <Button
                            className="px-2 py-1 mx-1 text-xs"
                            bgColor="bg-orange-700"
                            onClick={() => acceptOrCancelReq("cancel")}
                          >
                            Cancel Request
                          </Button>
                        )}
                      </>
                    )}
                    {connectionStatus.status === "disconnect" &&
                    credits < 200 ? (
                      <Link
                        to="/store"
                        className="disabled px-2 py-1 mx-1 text-xs rounded-sm bg-primary text-white hover:text-white"
                        title="Insufficient tokens"
                      >
                        Connect
                      </Link>
                    ) : null}
                    {connectionStatus.status === "disconnect" &&
                      credits >= 200 && (
                        <Button
                          className="px-2 py-1 mx-1 text-xs"
                          onClick={() => handleConfirmationModal("connect")}
                        >
                          Connect
                        </Button>
                      )}
                    {connectionStatus.status === "blocked" && (
                      <Button
                        className="disabled px-2 py-1 text-xs cursor-not-allowed"
                        disabled
                        bgColor="bg-gray-400"
                      >
                        Blocked
                      </Button>
                    )}
                  </div>
                )}

                <div className="col-span-7 bg-white shadow-md p-3 rounded-md md:col-span-3">
                  <div className="flex justify-between items-start md:justify-center">
                    <img
                      src={profileImg.length < 1 ? FavIcon : profileImg}
                      className="rounded-full w-[90px] h-[90px] md:h-[120px] md:w-[120px] border border-slate-400 p-1"
                      alt="profile-img"
                    />
                    <div className="flex flex-col justify-center md:hidden">
                      <div className="flex items-center">
                        <Stars rating={averageRating} size={12} />
                      </div>
                      <div className="flex items-center justify-end">
                        <span className="px-1 md:px-2">({reviewCount})</span>
                        <Link
                          to="/reviews"
                          state={{
                            user_id: user_id,
                            role: "R$7s2",
                          }}
                          className="text-blue-500 text-sm"
                        >
                          Reviews
                        </Link>
                      </div>
                    </div>
                  </div>
                  {mentorDetails.map((mentor) => (
                    <div className="text-start" key={mentor.user_id}>
                      <p className="flex items-center pt-2 mb-0 font-bold text-lg uppercase">
                        {mentor.name}
                      </p>
                      <div className="flex items-center mb-2">
                        <RupeeIcon fill="#999999" />
                        <p className="text-sm mb-0 ms-3 flex items-center gap-x-1">
                          <span className="font-semibold me-1">Fee :</span>
                          {mentor.fee} <img src={PHcoin} alt="PH coin" /> Per
                          Session
                        </p>
                      </div>
                      <div className="flex items-center mb-2">
                        <BriefCaseIcon fill="#999999" />
                        <p className="text-sm mb-0 ms-3">
                          <span className="font-semibold me-1">
                            Experience :
                          </span>
                          {mentor.work_experience}+ Years
                        </p>
                      </div>
                      <div className="flex items-center mb-2">
                        <BuildingIcon fill="#999999" />
                        <p className="text-sm mb-0 ms-3 capitalize">
                          <span className="font-semibold me-1">
                            Previous/Current Company :
                          </span>
                          {mentor.previous_company}{" "}
                        </p>
                      </div>
                      <div className="flex mb-4">
                        <LocationIcon fill="#999999" />
                        <div className="flex items-center text-sm mb-0 ms-3">
                          <span className="font-semibold me-1">Address :</span>
                          <div className="flex items-center">
                            <span className="me-1 capitalize">
                              {mentor.city}
                            </span>
                            <img
                              src={
                                mentor.country_code
                                  ? `https://flagsapi.com/${mentor.country_code}/flat/64.png`
                                  : "https://flagsapi.com/IN/flat/64.png"
                              }
                              alt={`${mentor.country} flag`}
                              width={18}
                              title={capitalizeText(mentor.country)}
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border-t py-2">
                        <p className="mb-0 font-semibold">Industries</p>
                        {mentor.mentor_industry.map((industry, i) => (
                          <span key={i} className="outline-tags">
                            {industry.replace(new RegExp("_", "g"), " ")}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-span-7 md:col-span-4">
                  <div className="hidden md:flex items-center justify-between">
                    <div className="flex items-center">
                      <Stars rating={averageRating} size={"15px"} />
                      <span className="px-2">({reviewCount})</span>
                      <Link
                        to="/reviews"
                        state={{
                          user_id: user_id,
                          role: "R$7s2",
                        }}
                        className="text-blue-500 text-sm"
                      >
                        Reviews
                      </Link>
                    </div>
                    {userRole === "f@!3A" && (
                      <div className="flex items-center gap-x-1">
                        {connectionStatus.status === "connected" && (
                          <>
                            {verifiedStatus && (
                              <Button
                                className="px-2 py-1 text-xs"
                                bgColor="bg-[#F97316]"
                                onClick={() => setOpenReviewModal(true)}
                              >
                                Review
                              </Button>
                            )}
                            <Button
                              className="px-2 py-1 text-xs"
                              onClick={() => routeToChat()}
                            >
                              Message
                            </Button>
                            <Button
                              className="px-2 py-1 text-xs"
                              bgColor="bg-secondary"
                              onClick={() =>
                                handleConfirmationModal("unfollow")
                              }
                            >
                              Disconnect
                            </Button>
                          </>
                        )}
                        {connectionStatus.status === "pending" && (
                          <>
                            <Button
                              className="px-2 py-1 text-xs cursor-not-allowed disabled"
                              disabled
                              bgColor="bg-gray-400"
                            >
                              Awaiting Acceptance
                            </Button>
                            {connectionStatus.senderId === user_id ? (
                              <>
                                <Button
                                  className="px-2 py-1 text-xs"
                                  bgColor="bg-green-700"
                                  onClick={() =>
                                    acceptOrCancelReq("accept", true)
                                  }
                                >
                                  Accept
                                </Button>
                                <Button
                                  className="px-2 py-1 text-xs"
                                  bgColor="bg-orange-700"
                                  onClick={() =>
                                    acceptOrCancelReq("cancel", true)
                                  }
                                >
                                  Decline
                                </Button>
                              </>
                            ) : (
                              <Button
                                className="px-2 py-1 text-xs"
                                bgColor="bg-orange-700"
                                onClick={() => acceptOrCancelReq("cancel")}
                              >
                                Cancel Request
                              </Button>
                            )}
                          </>
                        )}
                        {connectionStatus.status === "disconnect" &&
                        credits < 200 ? (
                          <Link
                            to="/store"
                            className="px-2 py-1 text-xs rounded-sm bg-primary text-white hover:text-white disabled"
                            title="Insufficient tokens"
                          >
                            Connect
                          </Link>
                        ) : null}
                        {connectionStatus.status === "disconnect" &&
                          credits >= 200 && (
                            <Button
                              className="px-2 py-1 text-xs"
                              onClick={() => handleConfirmationModal("connect")}
                            >
                              Connect
                            </Button>
                          )}
                        {connectionStatus.status === "blocked" && (
                          <Button
                            className="disabled px-2 py-1 text-xs cursor-not-allowed"
                            disabled
                            bgColor="bg-gray-400"
                          >
                            Blocked
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-span-7 gap-3">
                    <div className="bg-white shadow-md rounded-md p-3 mt-3 h-full overflow-auto">
                      <p className="text-slate-400 pb-1">About Me</p>
                      <p className="mb-0">{mentorShortDesc}</p>
                    </div>
                    <div className="bg-white shadow-md rounded-md p-3 mt-3">
                      <p className="text-slate-400 pb-1">Video</p>
                      {video.length > 0 ? (
                        <video
                          controls
                          className="w-full"
                          id="videoFile"
                          src={video}
                          style={{ height: "200px" }}
                        >
                          Video uploaded
                        </video>
                      ) : (
                        <p>No video uploaded.</p>
                      )}
                    </div>
                    <div className="bg-white shadow-md rounded-md p-2 mt-3">
                      <p className="text-slate-400 pb-1">Reviews</p>
                      {latestReviews && latestReviews.length > 0 ? (
                        latestReviews.map((review) => (
                          <div
                            key={review.id}
                            className="bg-gray-200 rounded-md p-2 my-1"
                          >
                            <div className="flex justify-between items-center text-sm">
                              <span>{review.name}</span>
                              <span>{review.date}</span>
                            </div>
                            <div className="flex">
                              <Stars rating={review.rating} size={"14px"} />
                            </div>
                            <p>{review.review}</p>
                          </div>
                        ))
                      ) : (
                        <p className="p-1 bg-gray-200 rounded-md">
                          This mentor has no reviews currently.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <RatingModal
        open={openReviewModal}
        onClose={() => setOpenReviewModal(false)}
        role="mentor"
        id={user_id}
      />
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={() =>
          setConfirmationModal((prev) => ({ ...prev, open: false }))
        }
        callBackFn={confirmationModal.callback}
        message={confirmationModal.message}
      />
    </div>
  );
};

export default ViewMentorProfile;
