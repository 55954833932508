import { Link } from "react-router-dom";
import ErrorImg from "../../images/403.svg";

export const Error403 = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img src={ErrorImg} alt="Error-403" width={450} />
      <p className="mb-2 font-medium">
        Sorry something went wrong. Please login to continue.
      </p>
      <div>
        <Link
          to="/"
          className="bg-primary text-white rounded-md px-3 py-2 mx-2 hover:text-white"
        >
          Login / Signup
        </Link>
      </div>
    </div>
  );
};
