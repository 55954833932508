const RecentInvestments = ({ data }) => {
  return (
    <div className="bg-white shadow-lg rounded-xl overflow-auto w-full md:overflow-none">
      <h6 className="font-medium text-lg p-3">Recent Transactions</h6>
      <table className="table-auto min-w-max w-full rounded-b-xl overflow-hidden">
        <thead className="!bg-transparent !text-gray-400 text-left">
          <tr>
            <th className="p-2 font-medium text-xs">Startup</th>
            <th className="p-2 font-medium text-xs">Date</th>
            <th className="p-2 font-medium text-xs">Amount</th>
            <th className="p-2 font-medium text-xs">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((investment) => (
              <tr
                key={investment.id}
                className="bg-white border-t border-slate-200"
              >
                <td className="p-3 text-xs sm:text-sm text-capitalize">
                  {investment.startup_name}
                </td>
                <td className="p-3 text-xs sm:text-sm">{investment.date}</td>
                <td className="p-3 text-xs sm:text-sm">{investment.amount}</td>
                <td className="p-3">
                  <span
                    className={`p-1 text-xs ${
                      investment.status === "0" ? "orange-badge" : "green-badge"
                    }`}
                  >
                    {investment.status === "0" ? "Pending" : "Completed"}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <td colSpan={4} className="p-3 text-xs sm:text-sm text-gray-400">
                No investments found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecentInvestments;
