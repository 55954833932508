import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Pagination } from "../../../components/ui/Button";
import { EyeIcon, PenIcon, UserIcon } from "../../../icons/icons";

export const AllAgreements = ({
  agreementDetails,
  handleEditModal,
  viewAgreement,
  openHistoryModal,
}) => {
  const [page, setPage] = useState(1);

  return (
    <>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">Investor Name</th>
              <th className="p-3 font-medium text-sm">Total Amount</th>
              <th className="p-3 font-medium text-sm">Startup Sign</th>
              <th className="p-3 font-medium text-sm">Investor Sign</th>
              <th className="p-3 font-medium text-sm">Agreed On</th>
              <th className="p-3 font-medium text-sm">Action</th>
              <th className="p-3 font-medium text-sm">Details</th>
            </tr>
          </thead>
          <tbody>
            {agreementDetails && agreementDetails.length > 0 ? (
              agreementDetails
                .slice(page * 7 - 7, page * 7)
                .map((agreement) => (
                  <tr
                    className="bg-white border-b border-slate-300 align-middle h-auto"
                    key={agreement.id}
                  >
                    <td className="p-3 text-sm font-medium">
                      {agreement.investor_name}
                    </td>
                    <td className="p-3 text-sm">
                      <span className="font-medium mr-1">&#8377;</span>
                      {agreement.amount}
                    </td>
                    <td className="p-3 text-sm">
                      <span
                        className={`px-2 py-0.5 ${
                          agreement.startup_status === 0
                            ? "red-badge"
                            : "green-badge"
                        }`}
                      >
                        {agreement.startup_status === 0
                          ? "Not Signed"
                          : "Signed"}
                      </span>
                    </td>
                    <td className="p-3 text-sm">
                      <span
                        className={`px-2 py-0.5 ${
                          agreement.investor_status === 0
                            ? "red-badge"
                            : "green-badge"
                        }`}
                      >
                        {agreement.investor_status === 0
                          ? "Not Signed"
                          : "Signed"}
                      </span>
                    </td>
                    <td className="p-3 text-sm">
                      {agreement.investor_agreed_date || "-"}
                    </td>
                    <td className="p-3">
                      <div className="flex items-center justify-evenly gap-2 h-full">
                        {agreement.investor_deactivate === "1" ||
                        agreement.startup_deactivate === "1" ? (
                          <span title="Cannot View Agreement">
                            <EyeIcon className="disabled fill-green-500 cursor-not-allowed" />
                          </span>
                        ) : (
                          <span title="View Agreement">
                            <EyeIcon
                              onClick={() => viewAgreement(agreement)}
                              className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                            />
                          </span>
                        )}
                        {agreement.investor_deactivate === "1" ||
                        agreement.startup_deactivate === "1" ||
                        (agreement.startup_status === 1 &&
                          agreement.investor_status === 1) ? (
                          <span title="Cannot Edit Agreement">
                            <PenIcon className="disabled fill-blue-500 cursor-not-allowed" />
                          </span>
                        ) : (
                          <span title="Edit Agreement">
                            <PenIcon
                              onClick={() =>
                                handleEditModal(
                                  agreement.amount,
                                  agreement.id,
                                  agreement.investor_id
                                )
                              }
                              className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800"
                            />
                          </span>
                        )}
                        <Link
                          to={
                            agreement.investor_deactivate === "1" ||
                            agreement.startup_deactivate === "1"
                              ? "#"
                              : `/view-investor/${agreement.investor_name
                                  .split(" ")
                                  .join("-")}/${agreement.investor_id}`
                          }
                        >
                          {agreement.investor_deactivate === "1" ||
                          agreement.startup_deactivate === "1" ? (
                            <span title="Cannot View Profile">
                              <UserIcon className="disabled fill-orange-500 cursor-not-allowed" />
                            </span>
                          ) : (
                            <span title="View Profile">
                              <UserIcon className="cursor-pointer fill-orange-500 transition-colors ease-in duration-50 hover:fill-orange-800" />
                            </span>
                          )}
                        </Link>
                      </div>
                    </td>
                    <td className="p-3">
                      <Button
                        className="p-1 w-max text-sm transition-colors ease-in duration-150 hover:bg-orange-800"
                        bgColor="bg-orange-500"
                        onClick={() => openHistoryModal(agreement)}
                      >
                        Details
                      </Button>
                    </td>
                  </tr>
                ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={9} className="p-3 text-sm">
                  No agreement found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {agreementDetails.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={agreementDetails.length}
        />
      )}
    </>
  );
};
