import bankImages from "../images";

export const ImportImage = ({ imageName, width }) => {
  const selectedImg = bankImages[imageName];
  if (!selectedImg) {
    return null;
  }

  return <img src={selectedImg} alt={`Icon ${imageName}`} width={width} />;
};
