import { Link } from "react-router-dom";
import { Button } from "../Button";
import FavIcon from "../../../images/favicon.png";

export const BidCard = ({
  data,
  vendorName,
  role,
  connect,
  ad_status,
  ad_id,
}) => {
  const {
    bid_id,
    bid_amount,
    bid_deadline,
    bid_description,
    logo,
    status,
    user_id,
  } = data;

  return (
    <div className="mt-1 px-1 py-3 shadow rounded-lg bg-white">
      <div className="grid grid-cols-6 px-2 space-y-2 md:space-y-0 md:px-3">
        <div className="col-span-6 flex items-center justify-end md:hidden">
          {role !== "V&iR8" && ad_status === "0" && (
            <div>
              <Button
                className="py-0.5 px-3 mx-1 text-sm"
                bgColor="bg-green-600"
                onClick={() => connect(user_id)}
              >
                Chat
              </Button>
              <Link
                to={`/milestone/${ad_id}/${bid_id}`}
                state={{ companyName: vendorName }}
                className="py-1 px-3 mx-1 text-sm bg-primary text-white rounded-md"
              >
                Award
              </Link>
            </div>
          )}
          {status !== "0" && (
            <div className="flex">
              <Link
                to={`/milestone/${ad_id}/${bid_id}`}
                state={{ companyName: vendorName }}
                className={`py-0.5 px-1 mx-1 text-white rounded text-sm ${
                  status === "1"
                    ? "bg-orange-500"
                    : status === "2"
                    ? "bg-green-500"
                    : null
                }`}
              >
                {status === "1"
                  ? "Awarded"
                  : status === "2"
                  ? "Completed"
                  : null}
              </Link>

              {role !== "V&iR8" && (
                <>
                  <Button
                    className="py-0.5 px-1 mx-1 text-sm"
                    bgColor="bg-green-600"
                    onClick={() => connect(user_id)}
                  >
                    Chat
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
        <Link
          to={`/view-vendor/${vendorName.split(" ").join("-")}/${user_id}`}
          className="col-span-6 md:col-span-2 xl:col-span-1 flex flex-col md:items-center"
        >
          <img
            src={logo.length > 0 ? logo : FavIcon}
            alt="vendor-logo"
            width={75}
            className="hidden md:block h-[75px] rounded-full border border-slate-400 p-1"
            loading="lazy"
          />
          <span className="font-medium text-lg md:text-base">{vendorName}</span>
        </Link>
        <div className="col-span-6 md:col-span-4 xl:col-span-5 md:ps-2">
          <div className="flex flex-col md:flex-row justify-between md:items-center mb-2">
            <div className="flex">
              <p className="text-sm me-4">
                <span className="font-medium pe-1">Bid : </span>₹ {bid_amount}
              </p>
              <p className="text-sm">
                <span className="font-medium pe-1">Duration : </span>
                {bid_deadline} days
              </p>
            </div>
            {role !== "V&iR8" && ad_status === "0" && (
              <div className="hidden md:block">
                <Button
                  className="py-0.5 px-3 mx-2 text-sm"
                  bgColor="bg-green-600"
                  onClick={() => connect(user_id)}
                >
                  Chat
                </Button>
                <Link
                  to={`/milestone/${ad_id}/${bid_id}`}
                  state={{ companyName: vendorName }}
                  className="py-1 px-3 mx-2 text-sm bg-primary text-white rounded-md"
                >
                  Award
                </Link>
              </div>
            )}
            {status !== "0" && (
              <div className="hidden md:flex">
                <Link
                  to={`/milestone/${ad_id}/${bid_id}`}
                  state={{ companyName: vendorName }}
                  className={`py-0.5 px-1 mx-1 text-white rounded text-sm ${
                    status === "1"
                      ? "bg-orange-500"
                      : status === "2"
                      ? "bg-green-500"
                      : null
                  }`}
                >
                  {status === "1"
                    ? "Awarded"
                    : status === "2"
                    ? "Completed"
                    : null}
                </Link>

                {role !== "V&iR8" && (
                  <>
                    <Button
                      className="py-0.5 px-1 mx-1 text-sm"
                      bgColor="bg-green-600"
                      onClick={() => connect(user_id)}
                    >
                      Chat
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
          <p
            className={`${
              bid_description.length > 150 ? "description-text" : ""
            } text-xs md:text-sm`}
          >
            {bid_description}
          </p>
          {bid_description.length > 150 && (
            <input type="checkbox" className="expand-btn" />
          )}
        </div>
      </div>
    </div>
  );
};
