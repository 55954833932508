const LongCard = ({
  data,
  img = "",
  width = 30,
  gradient = "warning",
  classes = "col-span-6 sm:col-span-2",
}) => {
  return (
    <div className={`${classes} shadow rounded-md`}>
      <div className={`card py-4 !mb-0 stat-cards relative ${gradient}`}>
        <p className="font-medium text-sm mb-1">{data.count}</p>
        <p className="font-medium text-xs text-[#756f6f]">{data.title}</p>
        <div className="bg-gradient h-[40px] w-[40px]">
          <img src={img} alt="card-icon" width={width} loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default LongCard;
