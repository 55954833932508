import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../components/ui/Button";
import FavIcon from "../../../images/favicon.png";
import PHcoin from "../../../images/PH-coin.svg";
import { EyeIcon } from "../../../icons/icons";

export const WalletTable = ({ creditTransactions, usedCredits }) => {
  const { credits } = useSelector((state) => state.credits);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const viewProfile = (name, role, connection_id) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name}/${connection_id}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name}/${connection_id}`);
    } else {
      navigate(`/view-investor/${name}/${connection_id}`);
    }
  };

  return (
    <>
      <p className="mb-0 mt-2 text-xs flex items-center md:mt-0 md:text-sm">
        <span className="font-bold me-1">TOKENS USED :</span>
        {usedCredits}{" "}
        <img src={PHcoin} alt="PH coin" className="ml-1" width={22} />
      </p>
      <p className="text-xs md:text-sm flex items-center">
        <span className="font-bold me-1">TOKENS REMAINING :</span>
        {credits} <img src={PHcoin} alt="PH coin" className="ml-1" width={22} />
      </p>
      <h4 className="font-medium text-xl mt-3">Connection Transactions</h4>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 w-5/12 font-medium text-sm">Name</th>
              <th className="p-3 font-medium text-sm">Date</th>
              <th className="p-3 font-medium text-sm">Tokens</th>
              <th className="p-3 font-medium text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {creditTransactions.length > 0 ? (
              creditTransactions
                .slice(page * 7 - 7, page * 7)
                .map((transaction) => (
                  <tr
                    key={transaction.id}
                    className="bg-white border-b border-slate-300 align-middle h-auto"
                  >
                    <td className="p-3 text-xs md:text-sm">
                      <div className="flex items-center justify-start ps-3 capitalize">
                        <img
                          src={
                            transaction.logo.length > 0
                              ? transaction.logo
                              : FavIcon
                          }
                          alt="profile-img"
                          className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] me-3 rounded-full"
                          loading="lazy"
                        />
                        <div className="ml-2">
                          <p className="font-medium">{transaction.name}</p>
                          <span
                            className={`px-1 text-xs ${
                              transaction.role === "f@!3A"
                                ? "blue-badge"
                                : transaction.role === "R$7s2"
                                ? "orange-badge"
                                : transaction.role === "Sw%aa"
                                ? "green-badge"
                                : "indigo-badge"
                            }`}
                          >
                            {transaction.role === "f@!3A"
                              ? "Startup"
                              : transaction.role === "R$7s2"
                              ? "Mentor"
                              : transaction.role === "Sw%aa"
                              ? "Investor"
                              : "Vendor"}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="p-3 text-xs md:text-sm">
                      {transaction.date}
                    </td>
                    <td className="p-3 text-xs md:text-sm">
                      <div className="flex items-center gap-x-1 text-[#5a5a5b]">
                        <img src={PHcoin} alt="PHcoin" />
                        {transaction.freeze_points || 0}
                      </div>
                    </td>
                    <td className="p-3 text-xs md:text-sm">
                      <div className="flex items-center justify-start h-full">
                        <span title="View Profile">
                          <EyeIcon
                            onClick={() =>
                              viewProfile(
                                transaction.name,
                                transaction.role,
                                transaction.connection_id
                              )
                            }
                            className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan="5" className="p-3 text-xs md:text-sm">
                  No Transaction Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {creditTransactions.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={creditTransactions.length}
        />
      )}
    </>
  );
};
