import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import { Button } from "../../../components/ui/Button";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";
import { ImportImage } from "../../../utils/importImage";
import FavIcon from "../../../images/favicon.png";
import Agreed from "../../../images/agreed-amount.png";
import Pending from "../../../images/pending-amount.png";
import Invested from "../../../images/invested.png";

const InvestmentDetails = () => {
  const investmentDetailsApi = `${process.env.REACT_APP_API_URL}view-investment`;
  const updateInvestmentStatusApi = `${process.env.REACT_APP_API_URL}update-investment-status`;

  const { token, userRole } = useLocalStorage();
  const { fetchApi, isLoading, error } = useFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state;

  let sno = 1;

  const [openSidebar, setOpenSidebar] = useState(false);
  const [details, setDetails] = useState({
    bank: [],
    startup: [],
    investor: [],
    transaction: [],
    agreed_amount: 0,
    pending_amount: 0,
    released_amount: 0,
  });
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    callback: null,
    message: "",
  });

  const investmentDetails = useCallback(() => {
    fetchApi(
      {
        url: investmentDetailsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          startup_id: data.startup_id,
          investor_id: data.investor_id,
          transaction_id: data.transaction_id,
        },
      },
      (data) => {
        setDetails({
          bank: data.bank_details,
          startup: data.startup_details,
          investor: data.investor_details,
          transaction: data.transaction,
          agreed_amount: data.agreement_amount,
          pending_amount: data.pending_amount,
          released_amount: data.released_amount,
        });
      }
    );
  }, [investmentDetailsApi, token, fetchApi, data]);

  const updateStatus = useCallback(
    (type, status) => {
      let bodyData = {
        startup_id: data.startup_id,
        investor_id: data.investor_id,
        transaction_id: data.transaction_id,
      };
      if (type === "verify") {
        bodyData = { ...bodyData, status_by_admin: status };
      } else {
        bodyData = { ...bodyData, transfer_to_startup_status: status };
      }
      fetchApi(
        {
          url: updateInvestmentStatusApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: bodyData,
        },
        () => {
          investmentDetails();
        }
      );
    },
    [updateInvestmentStatusApi, token, fetchApi, data, investmentDetails]
  );

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      investmentDetails();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [investmentDetails, navigate, userRole]);

  const handleConfirmationModal = (type) => {
    if (type === "payment verified") {
      setConfirmationModal({
        open: true,
        callback: () => updateStatus("verify", 1),
        message:
          "Please make sure that the platform has recieved the same amount as provided by the investor",
      });
    } else if (type === "payment not verified") {
      setConfirmationModal({
        open: true,
        callback: () => updateStatus("verify", -1),
        message:
          "Please make sure that the payment has not been received by the platform before continuing",
      });
    } else if (type === "payment processed") {
      setConfirmationModal({
        open: true,
        callback: () => updateStatus("processed", 1),
        message:
          "Please make sure that the correct amount has been transfered to the above startup before continuing",
      });
    }
  };

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <div className="grid grid-cols-2 gap-3">
              {details.pending_amount < 0 && (
                <p className="col-span-2 font-medium text-red-500">
                  <span className="font-semibold text-black">Important :</span>{" "}
                  Investor has released more money than agreed on. Check pending
                  amount and transaction history table below before performing
                  any action.
                </p>
              )}
              <div className="col-span-2 grid grid-cols-3 gap-3">
                <div className="col-span-3 md:col-span-1">
                  <div className="bg-white shadow-md rounded-md p-2 flex items-center justify-between">
                    <div>
                      <p className="text-lg font-medium">Agreed Amount</p>
                      <p className="text-base">
                        <span className="font-medium">&#8377;</span>{" "}
                        {details.agreed_amount}
                      </p>
                    </div>
                    <img src={Agreed} alt="agreed amount icon" width={45} />
                  </div>
                </div>
                <div className="col-span-3 md:col-span-1">
                  <div
                    className={`shadow-md rounded-md p-2 flex items-center justify-between ${
                      details.pending_amount === 0
                        ? "bg-green-400"
                        : details.pending_amount < 0
                        ? "bg-red-400"
                        : "bg-white"
                    }`}
                  >
                    <div>
                      <p className="text-lg font-medium">Pending Amount</p>
                      <p className="text-base">
                        <span className="font-medium">&#8377;</span>{" "}
                        {details.pending_amount}
                      </p>
                    </div>
                    <img src={Pending} alt="pending amount icon" width={45} />
                  </div>
                </div>
                <div className="col-span-3 md:col-span-1">
                  <div className="bg-white shadow-md rounded-md p-2 flex items-center justify-between">
                    <div>
                      <p className="text-lg font-medium">Invested Amount</p>
                      <p className="text-base">
                        <span className="font-medium">&#8377;</span>{" "}
                        {details.released_amount}
                      </p>
                    </div>
                    <img src={Invested} alt="invested amount icon" width={45} />
                  </div>
                </div>
              </div>
              {details.investor.length > 0 && (
                <div className="col-span-2 sm:col-span-1 p-2 bg-white shadow-md rounded-md">
                  <img
                    src={
                      details.investor[0].logo.length > 0
                        ? details.investor[0].logo
                        : FavIcon
                    }
                    alt="investor-img"
                    width={100}
                    className="h-[100px] rounded-full p-1 mb-2 border border-slate-400"
                  />
                  <p className="font-semibold">
                    Role :<span className="font-medium ps-1">Investor</span>
                  </p>
                  <p className="font-semibold">
                    Name :
                    <span className="font-medium ps-1">
                      {details.investor[0].name}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Email :
                    <span className="font-medium ps-1">
                      {details.investor[0].email}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Phone :
                    <span className="font-medium ps-1">
                      {details.investor[0].phone}
                    </span>
                  </p>
                </div>
              )}
              {details.startup.length > 0 && (
                <div className="col-span-2 sm:col-span-1 p-2 bg-white shadow-md rounded-md">
                  <img
                    src={
                      details.startup[0].logo.length > 0
                        ? details.startup[0].logo
                        : FavIcon
                    }
                    alt="startup-img"
                    width={100}
                    className="h-[100px] rounded-full p-1 mb-2 border border-slate-400"
                  />
                  <p className="font-semibold">
                    Role :<span className="font-medium ps-1">Startup</span>
                  </p>
                  <p className="font-semibold">
                    Name :
                    <span className="font-medium ps-1">
                      {details.startup[0].name}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Email :
                    <span className="font-medium ps-1">
                      {details.startup[0].email}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Phone :
                    <span className="font-medium ps-1">
                      {details.startup[0].phone}
                    </span>
                  </p>
                </div>
              )}
              <div className="col-span-2 md:col-span-2 p-3 bg-white shadow-md rounded-md">
                {details.transaction.length > 0 &&
                  details.transaction
                    .filter((transaction) => transaction._id === data?.id)
                    .map((transactionData) => (
                      <>
                        <div className="md:col-span-2 flex items-center justify-between mb-4">
                          <h4 className="font-semibold">
                            Current Investment Details
                          </h4>
                          {transactionData.status_by_admin === 0 ? (
                            <div>
                              <Button
                                className="button py-1 px-2 mx-2 text-xs"
                                bgColor="bg-green-500"
                                onClick={() =>
                                  handleConfirmationModal("payment verified")
                                }
                              >
                                Payment Verified
                              </Button>
                              <Button
                                className="button py-1 px-2 mx-2 text-xs"
                                bgColor="bg-secondary"
                                onClick={() =>
                                  handleConfirmationModal(
                                    "payment not verified"
                                  )
                                }
                              >
                                Payment Not Recieved
                              </Button>
                            </div>
                          ) : transactionData.status_by_admin === 1 ? (
                            <Button
                              className="py-1 px-2 mx-2 text-xs disabled"
                              bgColor="bg-green-700"
                              disabled
                            >
                              Verified
                            </Button>
                          ) : (
                            <Button
                              className="py-1 px-2 mx-2 text-xs disabled"
                              bgColor="bg-secondary"
                              disabled
                            >
                              Rejected
                            </Button>
                          )}
                        </div>
                        <div className="grid grid-cols-4 gap-1">
                          <p className="font-medium">
                            Date :
                            <span className="ps-2">{transactionData.date}</span>
                          </p>
                          <p className="font-medium">
                            Amount :
                            <span className="ps-2">
                              ₹{transactionData.withdrable_amount}
                            </span>
                          </p>
                          <p className="col-span-2 font-medium">
                            Transaction Id :
                            <span className="ps-2">
                              {transactionData.transaction_id}
                            </span>
                          </p>
                          <p className="font-medium">
                            Verified :
                            <span
                              className={`ms-2 p-0.5 rounded-md text-sm ${
                                transactionData.status_by_admin === 0
                                  ? "bg-orange-200 text-orange-800"
                                  : transactionData.status_by_admin === 1
                                  ? "bg-green-200 text-green-800"
                                  : "bg-red-200 text-red-800"
                              }`}
                            >
                              {transactionData.status_by_admin === 0
                                ? "Pending"
                                : transactionData.status_by_admin === 1
                                ? "Verified"
                                : "Rejected"}
                            </span>
                          </p>
                          <p className="font-medium">
                            Processed :
                            <span
                              className={`ms-2 p-0.5 rounded-md text-sm ${
                                transactionData.transfer_to_startup_status === 0
                                  ? "bg-red-200 text-red-800"
                                  : "bg-green-200 text-green-800"
                              }`}
                            >
                              {transactionData.transfer_to_startup_status === 0
                                ? "No"
                                : "Yes"}
                            </span>
                          </p>
                          <p className="col-span-2 font-medium">
                            Transaction Mode :
                            <span className="ps-2 capitalize">
                              {transactionData.transaction_mode}
                            </span>
                          </p>
                        </div>
                      </>
                    ))}
                <div className="flex justify-between items-center">
                  <h4 className="font-semibold my-3">Bank Details</h4>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-3">
                  {details.bank.length > 0 ? (
                    details.bank.map((bank) => (
                      <div
                        key={bank._id}
                        className="flex justify-between items-center p-2 bg-slate-300 shadow-md rounded-md"
                      >
                        <div>
                          <p className="font-medium">
                            Name :
                            <span className="ps-2 uppercase">{bank.name}</span>
                          </p>
                          <p className="font-medium">
                            Bank :
                            <span className="ps-2 uppercase">
                              {bank.bank_name} BANK
                            </span>
                          </p>
                          <p className="font-medium">
                            Account Number :
                            <span className="ps-2">{bank.account_number}</span>
                          </p>
                          <p className="font-medium">
                            IFSC :<span className="ps-2">{bank.ifsc}</span>
                          </p>
                        </div>
                        <ImportImage
                          imageName={bank.bank_name}
                          width={45}
                          alt={bank.bank_name}
                        />
                      </div>
                    ))
                  ) : (
                    <p className="p-2 ps-0 md:col-span-2">
                      Bank details not found. Please ask startup to add bank
                      details from profile page.
                    </p>
                  )}
                </div>
                {details.transaction.length > 0 &&
                  details.transaction
                    .filter((transaction) => transaction._id === data?.id)
                    .map((transactionData) =>
                      transactionData.status_by_admin === 1 ? (
                        transactionData.transfer_to_startup_status === 0 ? (
                          <Button
                            className="button py-1 px-2 text-xs"
                            onClick={() =>
                              handleConfirmationModal("payment processed")
                            }
                          >
                            Trasnfered to Startup
                          </Button>
                        ) : (
                          <Button
                            className="py-1 px-2 text-xs disabled"
                            disabled
                          >
                            Payment Processed
                          </Button>
                        )
                      ) : (
                        transactionData.status_by_admin === 0 && (
                          <Button
                            className="py-1 px-2 text-xs disabled"
                            disabled
                          >
                            Need Payment Verification
                          </Button>
                        )
                      )
                    )}
              </div>
              <div className="col-span-2 p-3 rounded-md bg-white shadow-md">
                <h4 className="font-semibold">Investment History</h4>
                <div className="overflow-auto w-full md:overflow-none">
                  <table className="table-auto w-full mt-4 border shadow-sm border-slate-300">
                    <thead className="bg-primary text-white border-b border-slate-300 text-left">
                      <tr>
                        <th className="p-2 font-medium text-sm">S.No</th>
                        <th className="p-2 font-medium text-sm whitespace-nowrap">
                          Released Amount
                        </th>
                        <th className="p-2 font-medium text-sm whitespace-nowrap">
                          Withdrawable Amount
                        </th>
                        <th className="p-2 font-medium text-sm whitespace-nowrap">
                          Released Date
                        </th>
                        <th className="p-2 font-medium text-sm whitespace-nowrap">
                          Admin approval
                        </th>
                        <th className="p-2 font-medium text-sm whitespace-nowrap">
                          Transfered to startup
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.transaction.length > 0 ? (
                        details.transaction.map((transaction) => (
                          <tr
                            key={transaction._id}
                            className="bg-white border-b border-slate-300"
                          >
                            <td className="p-2 text-sm">{sno++}</td>
                            <td className="p-2 text-sm">
                              {transaction.amount}
                            </td>
                            <td className="p-2 text-sm">
                              {transaction.withdrable_amount}
                            </td>
                            <td className="p-2 text-sm">{transaction.date}</td>
                            <td
                              className={`p-2 text-sm ${
                                transaction.status_by_admin === 0
                                  ? "text-orange-500"
                                  : transaction.status_by_admin === 1
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {transaction.status_by_admin === 0
                                ? "Pending"
                                : transaction.status_by_admin === 1
                                ? "Approved"
                                : "Rejected"}
                            </td>
                            <td
                              className={`p-2 text-sm ${
                                transaction.transfer_to_startup_status === 0
                                  ? "text-orange-500"
                                  : "text-green-500"
                              }`}
                            >
                              {transaction.transfer_to_startup_status === 0
                                ? "Pending"
                                : "Transfered"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No transaction found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={() =>
          setConfirmationModal((prev) => ({ ...prev, open: false }))
        }
        message={confirmationModal.message}
        callBackFn={confirmationModal.callback}
      />
    </div>
  );
};

export default InvestmentDetails;
