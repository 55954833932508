import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTemplates } from "../../store/slices/SampleTemplateSlice";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import DocumentCard from "../../components/ui/cards/documentCard";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";

const SampleDocument = () => {
  const { token } = useLocalStorage();
  const dispatch = useDispatch();
  const { templates, doc_types, status, error } = useSelector(
    (state) => state.templates
  );

  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState("All");

  useEffect(() => {
    const getTemplates = setTimeout(() => {
      if (status === "idle" && !error) {
        dispatch(fetchTemplates(token));
      }
    });
    return () => {
      clearTimeout(getTemplates);
    };
  }, [dispatch, token, status, error]);

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {status === "loading" && <Loader />}
        {error && <Error />}
        {!error && status === "succeeded" && (
          <div className="p-4 space-y-4">
            <h3 className="text-lg font-semibold">Sample Templates</h3>
            {templates.length > 0 ? (
              <div className="grid sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-3">
                <div className="sm:col-span-2 md:col-span-3 xl:col-span-4 flex items-center justify-start gap-x-3">
                  <h5 className="font-medium">Document Types :</h5>
                  <div className="flex items-center justify-start gap-x-3">
                    {doc_types.map((type) => (
                      <p
                        key={type}
                        className={`px-3 py-1 rounded-md transition-colors delay-150 hover:bg-primary hover:text-white cursor-pointer text-xs font-medium capitalize ${
                          selectedDocType === type
                            ? "bg-primary text-white"
                            : "outline outline-1 outline-gray-300 bg-white"
                        }`}
                        onClick={() => setSelectedDocType(type)}
                      >
                        {type}
                      </p>
                    ))}
                  </div>
                </div>
                {templates
                  .filter(
                    (document) =>
                      selectedDocType === "All" ||
                      document.document_type === selectedDocType
                  )
                  .map((document) => (
                    <DocumentCard key={document._id} data={document} />
                  ))}
              </div>
            ) : (
              <p className="md:col-span-3 xl:col-span-4 font-medium">
                No Documents Found.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SampleDocument;
