import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "user",
  initialState: [],
  reducers: {
    setUser(state, action) {
      state.push(action.payload);
    },
    deleteUser(state, action) {
      return [];
    },
    updateName(state, action) {
      return [{ ...state[0], name: action.payload }];
    },
  },
});

export default loginSlice.reducer;
export const { setUser, deleteUser, updateName } = loginSlice.actions;
