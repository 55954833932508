import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import TopUpForm from "./topupForm";
import { useEffect } from "react";

const AddTopUp = ({ open, onClose, addTopUp }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      discount: "0",
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data) => {
    addTopUp(data);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`min-w-[90%] md:min-w-[750px] md:max-w-[750px] max-h-[95vh] overflow-y-auto bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 capitalize text-base">
          Create New Top-Up Plan
        </h4>
        <TopUpForm
          submitModal={submitModal}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          type="add"
        />
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default AddTopUp;
