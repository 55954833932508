import { useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import "./ticketModal.css";
import { FormError } from "../../FormError";
import { AttachmentIcon } from "../../../../icons/icons";

export const TicketModal = ({ open, onClose, supportTicket }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [proof, setProof] = useState(null);

  if (!open) return null;

  const complaintConfirmation = (data) => {
    supportTicket(proof, data);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <div id="modal-front">
          <p onClick={onClose} className="closeBtn">
            X
          </p>
          <h4 className="modal-heading text-black font-semibold text-center border-b p-3 text-base">
            Raise a complaint
          </h4>
          <form
            className="form p-2 text-gray-500 text-sm"
            onSubmit={handleSubmit(complaintConfirmation)}
          >
            <div className="mt-2">
              <label htmlFor="complaintCategory" className="mb-1 required">
                What is your complaint regarding*
              </label>
              <select
                className="w-full px-2 py-1 border border-gray-400 rounded-md focus:ring-1 focus:ring-indigo-600"
                id="complaintCategory"
                name="complaintCategory"
                required
                {...register("complaintCategory", {
                  required: "Category is required",
                })}
              >
                <option value="website-bug">Website Bug</option>
                <option value="fund-transfer">Fund Transfer</option>
                <option value="startup">Startup</option>
                <option value="mentor">Mentor</option>
                <option value="investor">Investor</option>
                <option value="service-provider">Service Provider</option>
                <option value="other">Other</option>
              </select>
              {errors.complaintCategory && (
                <div>
                  <FormError message={errors.complaintCategory.message} />
                </div>
              )}
            </div>
            <div className="mt-2">
              <label htmlFor="complaintHeading" className="required">
                Complaint Heading
              </label>
              <input
                type="text"
                id="complaintHeading"
                name="complaintHeading"
                className="w-full px-2 py-1 border border-gray-400 rounded-md focus:ring-1 focus:ring-indigo-600"
                {...register("complaintHeading", {
                  required: "Heading is required",
                  minLength: {
                    value: 10,
                    message: "Must be at least 10 characters long",
                  },
                  maxLength: {
                    value: 100,
                    message: "Must not exceed 100 characters",
                  },
                })}
              />
              {errors.complaintHeading && (
                <div>
                  <FormError message={errors.complaintHeading.message} />
                </div>
              )}
            </div>
            <div className="mt-3">
              <textarea
                name="complaintQuery"
                id="complaintQuery"
                cols="30"
                rows="3"
                placeholder="Wrtie your query here..."
                className="w-full px-2 py-1 border border-gray-400 rounded-md focus:ring-1 focus:ring-indigo-600"
                {...register("complaintQuery", {
                  required: "Description is required",
                  minLength: {
                    value: 30,
                    message: "Must be at least 30 characters long",
                  },
                })}
              ></textarea>
              {errors.complaintQuery && (
                <div>
                  <FormError message={errors.complaintQuery.message} />
                </div>
              )}
            </div>
            <div className="my-2">
              <label
                htmlFor="ticket-document"
                className="inline-flex items-center gap-x-1 text-customOrange cursor-pointer bg-slate-200 shadow-md p-1 rounded-md"
              >
                Upload Supporting documents <AttachmentIcon fill="#B6B6B6" />
              </label>
              <input
                type="file"
                id="ticket-document"
                accept="application/pdf,image/jpeg,image/png,.pdf,.jpg,.jpeg,.png"
                onChange={(e) => setProof(e.target.files[0])}
                hidden
              />
            </div>
            <p className="text-primary">{proof && proof.name}</p>
            <div className="float-right p-0 my-2">
              <Button type="submit" className="px-2 py-1 mx-1">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
