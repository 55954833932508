const RatingCircle = ({
  rating,
  title = "Overall Rating",
  tag = "Overall",
}) => {
  return (
    <p
      title={title}
      className="flex flex-col justify-center mx-2 bg-[#f0f8ff] items-center font-medium h-[100px] w-[100px] border border-slate-300 rounded-full"
    >
      <span>{rating}/5</span>
      <span>{tag}</span>
    </p>
  );
};

export default RatingCircle;
