import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getAllReviewsApi = `${process.env.REACT_APP_API_URL}display-all-reviews`;

const initialState = {
  reviews: [],
  role: "",
  average_rating: 0,
  investor: 0,
  mentor: 0,
  vendor: 0,
  startup: 0,
  status: "idle",
  error: null,
};

export const fetchReviews = createAsyncThunk(
  "review/fetchReviews",
  async ({ token, userId, role }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        getAllReviewsApi,
        { user_id: userId, role: role },
        {
          headers: {
            "x-token": token,
          },
        }
      );
      const data = response.data;

      return {
        reviews: data.reviews,
        role: data.role,
        average_rating: data.average_rating,
        investor: data.rateByInvestor,
        mentor: data.rateByMentor,
        vendor: data.rateByVendor,
        startup: data.rateByStartup,
      };
    } catch (error) {
      if (error.response.data.message === "Invalid token") {
        window.location.href = "/error-401";
      }
      let errorMessage = "An error occurred while fetching reviews.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    clearReviews: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviews = action.payload.reviews;
        state.role = action.payload.role;
        state.average_rating = action.payload.average_rating;
        state.investor = action.payload.investor;
        state.mentor = action.payload.mentor;
        state.vendor = action.payload.vendor;
        state.startup = action.payload.startup;
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearReviews } = reviewsSlice.actions;
export default reviewsSlice.reducer;
