import { useCallback, useState } from "react";

const useCheckRole = () => {
  const [role, setRole] = useState(null);

  const CheckRole = useCallback((checkRole) => {
    if (checkRole === "f@!3A") {
      setRole(1);
    } else if (checkRole === "R$7s2") {
      setRole(2);
    } else if (checkRole === "Sw%aa") {
      setRole(3);
    } else {
      setRole(4);
    }
  }, []);

  return { role, CheckRole };
};

export default useCheckRole;
