import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useDisplayIndustries from "../../../hooks/useDisplayIndustries";
import { useToastContext } from "../../../context/ToastContext";
import { randomId } from "../../../utils/randomId";
import AdminSidebar from "../../../components/layout/adminSidebar";
import AdminNavbar from "../../../components/layout/adminNavbar";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import { Button } from "../../../components/ui/Button";
import { AddIndustryModal } from "../../../components/ui/modals/industry/addIndustryModal";
import IndustryTable from "./industryTable";

const Industries = () => {
  const addIndustryApi = `${process.env.REACT_APP_API_URL}add-industry`;
  const editIndustryApi = `${process.env.REACT_APP_API_URL}edit-industry`;
  const deleteIndustryApi = `${process.env.REACT_APP_API_URL}delete-industry`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();
  const { displayIndustries } = useDisplayIndustries();

  const [industries, setIndustries] = useState([]);
  const [selectedRole, setSelectedRole] = useState("other");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openIndustryModal, setOpenIndustryModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedIndustry, setSelectedIndustry] = useState({
    industry: "",
    role: "",
    id: null,
  });

  const addIndustry = useCallback(
    (industry, role) => {
      fetchApi(
        {
          url: addIndustryApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            industry_name: industry,
            role: role,
          },
        },
        (data) => {
          if (data.message === "industry exist") {
            toast.open("false", "Industry exist");
            return;
          }
          displayIndustries((data) => setIndustries(data.industries));
        }
      );
    },
    [fetchApi, token, addIndustryApi, toast, displayIndustries]
  );

  const updateIndustry = useCallback(
    (id, data, field) => {
      let bodyData = { id: id };
      if (field === "status") {
        bodyData = { ...bodyData, status: data };
      } else {
        bodyData = {
          ...bodyData,
          industry_name: data.industry_name,
          role: data.role,
        };
      }
      fetchApi(
        {
          url: editIndustryApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: bodyData,
        },
        (data) => {
          if (data.message === "industry exist") {
            toast.open("false", "Industry exist");
            return;
          }
          setSelectedIndustry({
            industry: "",
            role: "",
            id: null,
          });
          displayIndustries((data) => setIndustries(data.industries));
        }
      );
    },
    [fetchApi, token, editIndustryApi, toast, displayIndustries]
  );

  const deleteIndustry = useCallback(
    (id) => {
      fetchApi(
        {
          url: deleteIndustryApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        () => {
          displayIndustries((data) => setIndustries(data.industries));
        }
      );
    },
    [fetchApi, token, deleteIndustryApi, displayIndustries]
  );

  useEffect(() => {
    const fetchIndustries = setTimeout(() => {
      displayIndustries((data) => setIndustries(data.industries), "admin");
    }, 20);
    return () => {
      clearTimeout(fetchIndustries);
    };
  }, [displayIndustries, fetchApi, token]);

  const handleIndustryModal = (type, industry, role, id) => {
    if (type === "edit") {
      setSelectedIndustry({
        industry: industry,
        role: role,
        id: id,
      });
      setOpenIndustryModal(true);
    } else {
      setOpenIndustryModal(true);
    }
  };

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!isLoading && !error && (
          <div className="p-5">
            <Button
              className="block px-3 py-1 ml-auto"
              onClick={() => setOpenIndustryModal(true)}
            >
              Add Industry
            </Button>
            <p className="col-span-3 mb-3 font-medium text-sm">
              Note : Use toggle switch to activate/deactive subscription plan.
            </p>
            <div>
              <span
                className={`${
                  selectedRole === "other"
                    ? "bg-primary text-white"
                    : "bg-gray-200"
                } px-2 py-1 rounded-l-md border-r-2 border-gray-400`}
                onClick={() => {
                  setSelectedRole("other");
                  setPage(1);
                }}
              >
                Other
              </span>
              <span
                className={`${
                  selectedRole === "service provider"
                    ? "bg-primary text-white"
                    : "bg-gray-200"
                } px-2 py-1 rounded-r-md`}
                onClick={() => {
                  setSelectedRole("service provider");
                  setPage(1);
                }}
              >
                Service Provider
              </span>
            </div>
            <IndustryTable
              page={page}
              setPage={setPage}
              industries={industries}
              type={selectedRole}
              handleIndustryModal={handleIndustryModal}
              deleteIndustry={deleteIndustry}
            />
          </div>
        )}
      </div>
      <AddIndustryModal
        key={randomId()}
        open={openIndustryModal}
        onClose={() => {
          setOpenIndustryModal(false);
          setSelectedIndustry({
            industry: "",
            role: "",
            id: null,
          });
        }}
        addIndustry={addIndustry}
        updateIndustry={updateIndustry}
        selectedIndustry={selectedIndustry}
        setSelectedIndustry={setSelectedIndustry}
      />
    </div>
  );
};

export default Industries;
