import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import UnderLine from "@tiptap/extension-underline";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import "./editor.css";
import {
  Bold,
  Italic,
  OrderedList,
  QuoteLeft,
  Redo,
  StrikeThrough,
  UnOrderedList,
  UnbanIcon,
  Underline,
} from "../../../icons/icons";
import { Button } from "../../ui/Button";

const MenuBar = ({ submitSummary }) => {
  const { editor } = useCurrentEditor();
  const summary = () => {
    if (editor.getHTML().length < 300) {
      document.getElementById("summary-error").classList.remove("hidden");
      document.getElementById("summary-error").classList.add("flex");
      return;
    } else {
      document.getElementById("summary-error").classList.add("hidden");
      document.getElementById("summary-error").classList.remove("flex");
      submitSummary(editor.getHTML());
    }
  };

  return (
    <div className="editor-styles">
      <div>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={`menu-button ${
            editor.isActive("bold") ? "is-active" : ""
          }`}
        >
          <Bold />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={`menu-button ${
            editor.isActive("italic") ? "is-active" : ""
          }`}
        >
          <Italic />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          className={`menu-button ${
            editor.isActive("underline") ? "is-active" : ""
          }`}
        >
          <Underline />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={`menu-button ${
            editor.isActive("strike") ? "is-active" : ""
          }`}
        >
          <StrikeThrough />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`menu-button ${
            editor.isActive("bulletList") ? "is-active" : ""
          }`}
        >
          <UnOrderedList />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`menu-button ${
            editor.isActive("orderedList") ? "is-active" : ""
          }`}
        >
          <OrderedList />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`menu-button ${
            editor.isActive("blockquote") ? "is-active" : ""
          }`}
        >
          <QuoteLeft />
        </button>
      </div>
      <div className="flex items-center">
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="menu-button"
        >
          <UnbanIcon width="18px" height="18px" />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="menu-button"
        >
          <Redo />
        </button>
        <Button className="px-4 py-2 m-2 float-right" onClick={() => summary()}>
          Save
        </Button>
      </div>
    </div>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  UnderLine,
];

const TextEditor = ({ submitSummary }) => {
  return (
    <div className="flex flex-col items-start gap-2 w-full md:w-10/12 xl:w-9/12">
      <p id="summary-error" className="hidden text-red-500 text-sm">
        Summary must be atleast 300 characters long
      </p>
      <div className="text-editor">
        <EditorProvider
          slotBefore={<MenuBar submitSummary={submitSummary} />}
          extensions={extensions}
          content=""
        ></EditorProvider>
      </div>
    </div>
  );
};

export default TextEditor;
