import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { setChatRoom, deleteChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetchRoom from "../../hooks/useFetchRoom";
import useWindowDimensions from "../../hooks/useWindowDimension";
import ChatList from "./ChatList";
import BlankPreview from "./BlankPreview";
import Chat from "./Chat";
import Header from "./Header";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { SearchIcon } from "../../icons/icons";
import { MeetingSchedule } from "../../components/ui/modals/meetings/meetingSchedule";
import { randomId } from "../../utils/randomId";
import FavIcon from "../../images/favicon.png";
import { getCurrentDate } from "../../utils/getCurrentDate";

const ChatPage = () => {
  const connectionsListApi = `${process.env.REACT_APP_API_URL}display-connections`;
  const getRoomIdApi = `${process.env.REACT_APP_API_URL}get-roomid`;
  const unReadMessagesApi = `${process.env.REACT_APP_API_URL}unread-messages`;
  const scheduleMeetingApi = `${process.env.REACT_APP_API_URL}schedule-meeting`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, name } = useLocalStorage();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { roomData } = useFetchRoom();
  const [connectedUsersList, setConnectedUsersList] = useState([]);
  const [userData, setUserData] = useState({ id: "", image: "" });
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [unReadMsg, setUnReadMsg] = useState([]);
  const [meetingModal, setMeetingModal] = useState(false);
  const [singleUser, setSingleUser] = useState(null);

  const unReadMessages = useCallback(
    () =>
      fetchApi(
        {
          url: unReadMessagesApi,
          headers: {
            "x-token": token,
          },
        },
        (data) => {
          setUnReadMsg(data.unread);
        }
      ),
    [unReadMessagesApi, token, fetchApi]
  );

  const getRoomId = useCallback(
    (connection_id, role, status, deactivate) => {
      fetchApi(
        {
          url: getRoomIdApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: connection_id,
            role: role,
          },
        },
        (data) => {
          unReadMessages();
          dispatch(deleteChatRoom());
          dispatch(
            setChatRoom({
              roomId: data.room_id,
              recieverId: data.connection_id,
              recieverRole: role,
              status: status,
              deactivated: deactivate,
            })
          );
          setShowChat(true);
        }
      );
    },
    [getRoomIdApi, fetchApi, token, dispatch, unReadMessages]
  );

  const connectionsList = useCallback(
    () =>
      fetchApi(
        {
          url: connectionsListApi,
          headers: {
            "x-token": token,
          },
        },
        (data) => {
          setConnectedUsersList(data.response);
          setUserData({ id: data.user_id, image: data.image });
        }
      ),
    [connectionsListApi, fetchApi, token]
  );

  useEffect(() => {
    const timer = setTimeout(async () => {
      await connectionsList();
      await unReadMessages();
    }, 10);
    return () => {
      clearTimeout(timer);
      setRefresh(false);
    };
  }, [refresh, connectionsList, unReadMessages]);

  const chatSearch = useCallback(
    (value) => {
      setTimeout(() => {
        if (value) {
          const filteredList = connectedUsersList.filter((user) =>
            user.name.toLowerCase().includes(value.toLowerCase())
          );
          setFilteredUsers(filteredList);
        } else {
          setFilteredUsers(connectedUsersList);
        }
      }, 20);
    },
    [connectedUsersList]
  );

  const scheduleMeeting = useCallback(
    (type, data, recieverId) => {
      let bodyData;
      if (type === "chat") {
        bodyData = {
          reciever_id: recieverId,
          time: data.hour + ":" + data.minutes,
          title: data.title,
          date: getCurrentDate(),
        };
      } else {
        bodyData = {
          reciever_id: data.user,
          time: data.hour + ":" + data.minutes,
          title: data.title,
          date: getCurrentDate(),
        };
      }
      fetchApi(
        {
          url: scheduleMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: bodyData,
        },
        () => {
          connectionsList();
        }
      );
    },
    [scheduleMeetingApi, fetchApi, token, connectionsList]
  );

  const handleMeetingModal = (id, name, price) => {
    setSingleUser({ userId: id, name: name, fees: price });
    setMeetingModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="md:p-4">
          {!isLoading && error ? <Error /> : null}
          {!isLoading && !error ? (
            <div className="grid grid-cols-6 gap-x-4">
              {(!showChat || width > 768) && (
                <div className="col-span-6 md:col-span-2 card md:shadow py-5">
                  <div className="flex mb-3">
                    <img
                      className="rounded-full h-[45px] float-left"
                      src={userData.image.length > 0 ? userData.image : FavIcon}
                      alt="profile-img"
                      width={45}
                    />
                    <div className="float-left ps-2">
                      <div className="font-semibold">{name}</div>
                      <div className="text-[#59667a] text-xs mt-1 flex items-center">
                        Online{" "}
                        <svg
                          width="10px"
                          height="10px"
                          viewBox="-3.6 -3.6 43.20 43.20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          className="ms-1 iconify iconify--twemoji"
                          preserveAspectRatio="xMidYMid meet"
                          fill="#00ff00"
                          stroke="#00ff00"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0">
                            <rect
                              x="-3.6"
                              y="-3.6"
                              width="43.20"
                              height="43.20"
                              rx="21.6"
                              fill="#00ff00"
                              strokeWidth="0"
                            ></rect>
                          </g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <circle
                              fill="#00ff00"
                              cx="18"
                              cy="18"
                              r="18"
                            ></circle>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center justify-between mb-3 border rounded overflow-hidden">
                      <input
                        className="p-2 w-10/12"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => chatSearch(e.target.value)}
                      />
                      <SearchIcon
                        width="16px"
                        fill="#bebebe"
                        className="me-1"
                      />
                    </div>
                    <ul className="max-h-[335px] overflow-auto flex flex-col cursor-pointer">
                      {connectedUsersList.length > 0 ? (
                        filteredUsers.length > 0 ? (
                          filteredUsers.map((user) => {
                            const unreadCount =
                              unReadMsg.find(
                                (msg) => msg.sender === user.connection_id
                              )?.count || 0;
                            return (
                              <ChatList
                                key={user.connection_id}
                                data={user}
                                getRoomId={getRoomId}
                                unreadCount={unreadCount}
                              />
                            );
                          })
                        ) : (
                          connectedUsersList.map((user) => {
                            const unreadCount =
                              unReadMsg.find(
                                (msg) => msg.sender === user.connection_id
                              )?.count || 0;
                            return (
                              <ChatList
                                key={user.connection_id}
                                data={user}
                                getRoomId={getRoomId}
                                unreadCount={unreadCount}
                              />
                            );
                          })
                        )
                      ) : (
                        <li>No User Found</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}

              <div
                className={`col-span-6 md:col-span-4 ${
                  width > 767 || showChat ? "" : "hidden"
                }`}
              >
                {roomData ? (
                  <>
                    {width < 768 && (
                      <Header
                        connectedUsersList={connectedUsersList}
                        getRoomId={getRoomId}
                      />
                    )}
                    {userData.id.length > 0 && (
                      <Chat
                        userId={userData.id}
                        refresh={() => setRefresh(true)}
                        handleMeetingModal={handleMeetingModal}
                      />
                    )}
                  </>
                ) : (
                  <BlankPreview />
                )}
              </div>
            </div>
          ) : null}
          {isLoading && !error ? <Loader /> : null}
        </div>
      </div>
      <MeetingSchedule
        key={randomId()}
        open={meetingModal}
        onClose={() => setMeetingModal(false)}
        userData={singleUser}
        scheduleMeeting={scheduleMeeting}
      />
    </div>
  );
};

export default ChatPage;
