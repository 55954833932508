import React, { useCallback, useEffect, useState, useRef } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import SubscriptionCard from "../../components/ui/cards/subscriptionCard";
import TopUpCard from "../../components/ui/cards/topUpCard";

const VendorPlan = () => {
  const displayVendorPlansApi = `${process.env.REACT_APP_API_URL}display-subscription`;
  const displayTopUpsApi = `${process.env.REACT_APP_API_URL}display-top-up`;

  const { token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();

  const [planDetails, setPlanDetails] = useState({
    subscriptions: [],
    topup: [],
  });
  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState("plan");
  const ref = useRef(false);

  const getSubscriptionPlans = useCallback(() => {
    fetchApi(
      { url: displayVendorPlansApi, headers: { "x-token": token } },
      (data) =>
        setPlanDetails((prev) => ({
          ...prev,
          subscriptions: data.subscriptions,
        }))
    );
  }, [fetchApi, token, displayVendorPlansApi]);

  const displayTopUpPlans = useCallback(() => {
    fetchApi({ url: displayTopUpsApi, headers: { "x-token": token } }, (data) =>
      setPlanDetails((prev) => ({
        ...prev,
        topup: data.plans,
      }))
    );
  }, [fetchApi, token, displayTopUpsApi]);

  useEffect(() => {
    if (!ref.current) {
      getSubscriptionPlans();
      displayTopUpPlans();
    }
    return () => (ref.current = true);
  }, [getSubscriptionPlans, displayTopUpPlans]);

  const inactiveTab =
    "bg-[#d6d6d6cc] text-black transition-colors duration-100 hover:bg-primary hover:text-white";

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="text-center p-2">
          {error && <Error />}
          {isLoading && <Loader />}
          {!error && !isLoading && (
            <div className="inner-container py-3 space-y-5">
              <div className="flex items-center justify-center gap-x-3">
                <button
                  className={`font-medium px-3 py-1 rounded-lg ${
                    activeTab === "plan" ? "bg-primary text-white" : inactiveTab
                  }`}
                  onClick={
                    activeTab !== "plan"
                      ? () => setActiveTab("plan")
                      : undefined
                  }
                >
                  Purchase Tokens
                </button>
                <button
                  className={`font-medium px-3 py-1 rounded-lg ${
                    activeTab === "top-up"
                      ? "bg-primary text-white"
                      : inactiveTab
                  }`}
                  onClick={
                    activeTab !== "top-up"
                      ? () => setActiveTab("top-up")
                      : undefined
                  }
                >
                  Add-on Top-Up
                </button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                {activeTab === "plan" ? (
                  planDetails.subscriptions.length > 0 ? (
                    planDetails.subscriptions.map((plan) => (
                      <SubscriptionCard
                        key={plan._id}
                        data={plan}
                        callbackUrl={getSubscriptionPlans}
                      />
                    ))
                  ) : (
                    <p className="md:col-span-2 lg:col-span-4 font-medium text-left text-lg">
                      No purchase token plan found.
                    </p>
                  )
                ) : planDetails.topup.length > 0 ? (
                  planDetails.topup.map((topup) => (
                    <TopUpCard
                      key={topup._id}
                      data={topup}
                      callbackUrl={displayTopUpPlans}
                    />
                  ))
                ) : (
                  <p className="md:col-span-2 lg:col-span-4 font-medium text-left text-lg">
                    No top-up plan found.
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorPlan;
