import { useSelector } from "react-redux";

const useFetchRoom = () => {
  const roomData = useSelector((state) => {
    if (state.chatRoom.length < 0) {
      return null;
    }
    return state.chatRoom[0];
  });
  return { roomData };
};

export default useFetchRoom;
