import React, { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Pagination } from "../../components/ui/Button";
import { CircleCheck, CircleXmarkIcon } from "../../icons/icons";

export const WithdrawReqTable = ({
  withdrawReq,
  cancelWithdrawReq,
  walletDetails,
  processedAmount,
}) => {
  const { userRole } = useLocalStorage();

  const [page, setPage] = useState(1);

  return (
    <>
      <p className="mb-0 mt-2 md:mt-0">
        <span className="me-2 font-bold">Total Amount : </span>
        {userRole === "V&iR8" ? walletDetails.total : processedAmount.total}
      </p>
      <p>
        <span className="me-2 font-bold">Balance Amount : </span>
        {userRole === "V&iR8" ? walletDetails.balance : processedAmount.balance}
      </p>
      {userRole === "V&iR8" && (
        <p>
          <span className="me-2 font-bold">Debited Amount : </span>
          {walletDetails.debited}
        </p>
      )}
      <div className="flex mt-2">
        <span className="me-2 font-semibold text-xs whitespace-nowrap">
          Note :
        </span>
        <span className="mb-0 font-medium text-xs">
          Platform charges 10% of your Total amount as fee.
        </span>
      </div>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-5 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">Date</th>
              <th className="p-3 font-medium text-sm">Amount</th>
              <th className="p-3 font-medium text-sm">Status</th>
              <th className="p-3 font-medium text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {withdrawReq.length > 0 ? (
              withdrawReq.slice(page * 7 - 7, page * 7).map((request) => (
                <tr
                  key={request.id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-sm">{request.date}</td>
                  <td className="p-3 text-sm">
                    <span className="font-medium mr-1">&#8377;</span>
                    {request.withdraw_amount}
                  </td>
                  <td className="p-3">
                    <span
                      className={`p-1 text-xs font-medium ${
                        request.status === "0" ? "red-badge" : "green-badge"
                      }`}
                    >
                      {request.status === "0" ? "Pending" : "Processed"}
                    </span>
                  </td>
                  <td className="p-3 text-sm">
                    <div className="flex items-center justify-start h-full md:w-7/12">
                      {request.status === "0" ? (
                        <span title="Cancel Withdraw Request">
                          <CircleXmarkIcon
                            width="20px"
                            height="20px"
                            onClick={() => cancelWithdrawReq(request.id)}
                            className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                          />
                        </span>
                      ) : (
                        <span title="Processed Request">
                          <CircleCheck
                            width="20px"
                            height="20px"
                            className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                          />
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={4} className="p-3 text-xs md:text-sm">
                  No withdraw request
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {withdrawReq.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={withdrawReq.length}
        />
      )}
    </>
  );
};
