import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberWithDecimalRegex } from "../../../../constants";

export const AddInvestmentBracket = ({ open, onClose, callBackFn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      offerPercentage: 0,
      offer: "0",
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [reset, isSubmitSuccessful]);

  if (!open) return null;

  const submitModal = (data) => {
    callBackFn(data);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`max-w-[95%] min-w-[80vw] md:min-w-[550px] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading font-semibold text-center border-b p-3 mt-3 md:mt-0 capitalize text-base">
          Create Investment Bracket
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="block md:grid md:grid-cols-2 gap-x-3 gap-y-2 p-3 text-left"
        >
          <p className="col-span-2 font-medium text-base mb-0">
            Investment Range
          </p>
          <div className="flex flex-col mb-2">
            <label
              className="font-medium text-sm required mb-0"
              htmlFor="minInvestment"
            >
              Min (&#8377;)
            </label>
            <input
              type="number"
              id="minInvestment"
              name="minInvestment"
              className="p-[0.7rem] border rounded-md"
              {...register("minInvestment", {
                required: "Range min amount is required",
              })}
            />
            {errors.minInvestment && (
              <div>
                <FormError message={errors.minInvestment.message} />
              </div>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label
              className="font-medium text-sm required mb-0"
              htmlFor="maxInvestment"
            >
              Max (&#8377;)
            </label>
            <input
              type="number"
              id="maxInvestment"
              name="maxInvestment"
              className="p-[0.7rem] border rounded-md"
              {...register("maxInvestment", {
                required: "Range max amount is required",
              })}
            />
            {errors.maxInvestment && (
              <div>
                <FormError message={errors.maxInvestment.message} />
              </div>
            )}
          </div>
          <p className="col-span-2 font-medium text-base mb-0">Fee</p>
          <div className="flex flex-col mb-2">
            <label
              className="font-medium text-sm required mb-0"
              htmlFor="actualFee"
            >
              Orignal Fee (%)
            </label>
            <input
              type="text"
              id="actualFee"
              name="actualFee"
              className="p-[0.7rem] border rounded-md"
              {...register("actualFee", {
                required: "Fee percentage is required",
                pattern: {
                  value: numberWithDecimalRegex,
                  message: "Invalid fee percentage",
                },
              })}
            />
            {errors.actualFee && (
              <div>
                <FormError message={errors.actualFee.message} />
              </div>
            )}
          </div>
          <p className="col-span-2 font-medium text-base">Offer</p>
          <div className="flex flex-col mb-2">
            <label
              className="font-medium text-sm required mb-0"
              htmlFor="offer"
            >
              Offer Status
            </label>
            <div className="select-input mb-1 w-full border border-slate-300 m-auto rounded-md">
              <select
                name="offer"
                id="offer"
                className="border-0 w-full text-gray-500"
                {...register("offer", {
                  required: "Set offer status",
                })}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
            {errors.offer && (
              <div>
                <FormError message={errors.offer.message} />
              </div>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label
              className="font-medium text-sm required mb-0"
              htmlFor="offerPercentage"
            >
              Offer Percentage (%)
            </label>
            <input
              type="text"
              id="offerPercentage"
              name="offerPercentage"
              className="p-[0.7rem] border rounded-md"
              {...register("offerPercentage", {
                required: "Offer percentage is required",
                pattern: {
                  value: numberWithDecimalRegex,
                  message: "Invalid offer percentage",
                },
              })}
            />
            {errors.offerPercentage && (
              <div>
                <FormError message={errors.offerPercentage.message} />
              </div>
            )}
          </div>
          <div className="col-span-2 modalFooter">
            <Button type="submit" className="px-2 py-1 mx-1">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
