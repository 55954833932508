import { Pagination } from "../../../components/ui/Button";
import { Link } from "react-router-dom";
import { EyeIcon } from "../../../icons/icons";

const InvestmentTable = ({ page, setPage, data, type }) => {
  let filterData;

  if (type === "All") {
    filterData = data;
  } else if (type === "0") {
    filterData = data.filter(
      (data) =>
        data.status_by_admin === 0 || data.transfer_to_startup_status === 0
    );
  } else if (type === "1") {
    filterData = data.filter(
      (data) =>
        data.status_by_admin === 1 && data.transfer_to_startup_status === 1
    );
  } else {
    filterData = data.filter(
      (data) =>
        data.status_by_admin === -1 || data.transfer_to_startup_status === -1
    );
  }

  return (
    <>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">S.No</th>
              <th className="p-3 font-medium text-sm">Date</th>
              <th className="p-3 font-medium text-sm">Actual Amount</th>
              <th className="p-3 font-medium text-sm">Withdrawable</th>
              <th className="p-3 font-medium text-sm">Transaction Id</th>
              <th className="p-3 font-medium text-sm">Transaction Mode</th>
              <th className="p-3 font-medium text-sm">Verified</th>
              <th className="p-3 font-medium text-sm">Processed</th>
              <th className="p-3 font-medium text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.length > 0 ? (
              filterData.slice(page * 10 - 10, page * 10).map((data, index) => (
                <tr
                  key={data.id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm font-medium">
                    {(page - 1) * 10 + index + 1}
                  </td>
                  <td className="p-3 text-xs md:text-sm">{data.date}</td>
                  <td className="p-3 text-xs md:text-sm">
                    <span className="font-medium mr-1">&#8377;</span>
                    {data.amount}
                  </td>
                  <td className="p-3 text-xs md:text-sm">
                    <span className="font-medium mr-1">&#8377;</span>
                    {data.withdrable_amount}
                  </td>
                  <td className="p-3 text-xs md:text-sm">
                    {data.transaction_id}
                  </td>
                  <td className="p-3 text-xs md:text-sm">
                    {data.transaction_mode}
                  </td>
                  <td className="p-3 text-xs">
                    <span
                      className={`p-1 ${
                        data.status_by_admin === 0
                          ? "orange-badge"
                          : data.status_by_admin === 1
                          ? "green-badge"
                          : "red-badge"
                      }`}
                    >
                      {data.status_by_admin === 0
                        ? "Pending"
                        : data.status_by_admin === 1
                        ? "Verified"
                        : "Rejected"}
                    </span>
                  </td>
                  <td className="p-3 text-xs">
                    <span
                      className={`p-1 ${
                        data.transfer_to_startup_status === 0
                          ? "orange-badge"
                          : "green-badge"
                      }`}
                    >
                      {data.transfer_to_startup_status === 0 ? "No" : "Yes"}
                    </span>
                  </td>
                  <td className="p-3 text-xs md:text-sm">
                    <Link
                      to={`/admin/investment-details/${data.id}`}
                      state={{ data }}
                    >
                      <EyeIcon className="fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800" />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={9} className="p-3 text-xs md:text-sm">
                  No Investment Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={filterData.length}
          dataCount={10}
        />
      )}
    </>
  );
};

export default InvestmentTable;
